/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSFAccountInput = {
  id?: string | null,
  name?: string | null,
  accountName?: string | null,
  countryId?: string | null,
  parentAccountId?: string | null,
  emailDomain?: string | null,
  industry?: string | null,
  organizationId?: string | null,
  billingAddress?: string | null,
  shippingAddress?: string | null,
  copyBillingAddressToShippingAddress?: boolean | null,
  type?: string | null,
  phone?: string | null,
  website?: string | null,
  createdBy?: string | null,
  createdDate?: string | null,
  updatedBy?: string | null,
  updatedDate?: string | null,
  files?: Array< S3ObjectInput | null > | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  tenantId: string,
};

export type S3ObjectInput = {
  bucket: string,
  key: string,
  region: string,
  localUri?: string | null,
  mimeType?: string | null,
};

export type ModelSFAccountConditionInput = {
  name?: ModelStringInput | null,
  accountName?: ModelStringInput | null,
  countryId?: ModelIDInput | null,
  parentAccountId?: ModelIDInput | null,
  emailDomain?: ModelStringInput | null,
  industry?: ModelStringInput | null,
  organizationId?: ModelStringInput | null,
  billingAddress?: ModelStringInput | null,
  shippingAddress?: ModelStringInput | null,
  copyBillingAddressToShippingAddress?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  website?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdDate?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  updatedDate?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFAccountConditionInput | null > | null,
  or?: Array< ModelSFAccountConditionInput | null > | null,
  not?: ModelSFAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type SFAccount = {
  __typename: "SFAccount",
  id: string,
  name?: string | null,
  accountName?: string | null,
  countryId?: string | null,
  parentAccountId?: string | null,
  emailDomain?: string | null,
  industry?: string | null,
  organizationId?: string | null,
  billingAddress?: string | null,
  shippingAddress?: string | null,
  copyBillingAddressToShippingAddress?: boolean | null,
  type?: string | null,
  phone?: string | null,
  website?: string | null,
  createdBy?: string | null,
  createdDate?: string | null,
  updatedBy?: string | null,
  updatedDate?: string | null,
  files?:  Array<S3Object | null > | null,
  notesAndAttachments?:  Array<S3Object | null > | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  ticketRules?: ModelTicketRuleSFAccountConnection | null,
  parentAccount?: SFAccount | null,
  sfProducts?: ModelSFProductConnection | null,
  country?: SFCountry | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type Tenant = {
  __typename: "Tenant",
  id: string,
  name: string,
  subscribedServices?: string | null,
  domain?: string | null,
  organizationId?: string | null,
  tenantCustomizationId?: string | null,
  sidebarLinkId?: string | null,
  usedSpaceByDoc?: string | null,
  totalSpaceForDoc?: string | null,
  createdAt: string,
  updatedAt: string,
  sidebarLink?: ModelSidebarLinkConnection | null,
  tenantCustomization?: TenantCustomization | null,
  applications?: ModelTenantApplicationConnection | null,
  productDocs?: ModelProductDocConnection | null,
  chapters?: ModelChapterConnection | null,
  docs?: ModelDocConnection | null,
  docItems?: ModelDocItemConnection | null,
  users?: ModelUserConnection | null,
  comments?: ModelCommentConnection | null,
  groups?: ModelGroupConnection | null,
  applicationRoles?: ModelApplicationRoleConnection | null,
  organization?: Organization | null,
  fileLists?: ModelFileListConnection | null,
  tasks?: ModelTaskConnection | null,
  leads?: ModelLeadConnection | null,
  currencies?: ModelCurrencyConnection | null,
};

export type ModelSidebarLinkConnection = {
  __typename: "ModelSidebarLinkConnection",
  items:  Array<SidebarLink | null >,
  nextToken?: string | null,
};

export type SidebarLink = {
  __typename: "SidebarLink",
  id: string,
  name?: string | null,
  url?: string | null,
  parentLinkId?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant: Tenant,
  parentLink?: SidebarLink | null,
  subLinks?: ModelSidebarLinkConnection | null,
};

export type TenantCustomization = {
  __typename: "TenantCustomization",
  id: string,
  color?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type ModelTenantApplicationConnection = {
  __typename: "ModelTenantApplicationConnection",
  items:  Array<TenantApplication | null >,
  nextToken?: string | null,
};

export type TenantApplication = {
  __typename: "TenantApplication",
  id: string,
  tenantId: string,
  applicationId: string,
  createdAt: string,
  updatedAt: string,
  tenants: Tenant,
  applications: Group,
};

export type Group = {
  __typename: "Group",
  id: string,
  name: string,
  description?: string | null,
  status?: ActiveStatusType | null,
  organizationId?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  users?: ModelUserGroupConnection | null,
  ticketRules?: ModelTicketRuleGroupConnection | null,
  applicationRoles?: ModelApplicationRoleConnection | null,
  assignedTickets?: ModelGroupTaskConnection | null,
};

export enum ActiveStatusType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}


export type ModelUserGroupConnection = {
  __typename: "ModelUserGroupConnection",
  items:  Array<UserGroup | null >,
  nextToken?: string | null,
};

export type UserGroup = {
  __typename: "UserGroup",
  id: string,
  userId: string,
  groupId: string,
  createdAt: string,
  updatedAt: string,
  users: User,
  groups: Group,
};

export type User = {
  __typename: "User",
  id: string,
  userType: UserType,
  firstName: string,
  lastName: string,
  displayName?: string | null,
  jobTitle?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  email: string,
  organizationId?: string | null,
  salesForceId?: string | null,
  role?: RoleType | null,
  secondaryEmail: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  file?: S3Object | null,
  status?: ActiveStatusType | null,
  isMigrated?: boolean | null,
  isTenantAdmin?: boolean | null,
  ticketsToReviewOrder?: Array< string | null > | null,
  supportPortalDashBoardChartsOrder?: Array< number | null > | null,
  tenantId: string,
  preferredCurrency?: string | null,
  tenant?: Tenant | null,
  createdProductDocs?: ModelProductDocConnection | null,
  approvedProductDocs?: ModelProductDocConnection | null,
  createdChapters?: ModelChapterConnection | null,
  approvedChapters?: ModelChapterConnection | null,
  createdDocs?: ModelDocConnection | null,
  approvedDocs?: ModelDocConnection | null,
  createdDocItems?: ModelDocItemConnection | null,
  approvedDocItems?: ModelDocItemConnection | null,
  comments?: ModelCommentConnection | null,
  groups?: ModelUserGroupConnection | null,
  ticketRules?: ModelTicketRuleUserConnection | null,
  organization?: Organization | null,
  createdTasks?: ModelTaskConnection | null,
  reviewedTasks?: ModelTaskConnection | null,
  watchingTickets?: ModelWatcherTaskConnection | null,
  assignedTickets?: ModelUserTaskConnection | null,
  createdLeads?: ModelLeadConnection | null,
  qualifiedLeads?: ModelLeadConnection | null,
  disqualifiedLeads?: ModelLeadConnection | null,
  approvedLeads?: ModelLeadConnection | null,
  rejectedLeads?: ModelLeadConnection | null,
  watchingLeads?: ModelWatcherLeadConnection | null,
};

export enum UserType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}


export enum RoleType {
  admin = "admin",
  reviewer = "reviewer",
  writer = "writer",
  reader = "reader",
}


export type ModelProductDocConnection = {
  __typename: "ModelProductDocConnection",
  items:  Array<ProductDoc | null >,
  nextToken?: string | null,
};

export type ProductDoc = {
  __typename: "ProductDoc",
  id: string,
  version: string,
  status: string,
  name: string,
  description?: string | null,
  file?: S3Object | null,
  createdByUserId: string,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  applicationId: string,
  orderFloat?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  application?: Application | null,
  chapters?: ModelChapterConnection | null,
  createdByUser?: User | null,
  approvedByUser?: User | null,
  tasks?: ModelTaskConnection | null,
};

export type Application = {
  __typename: "Application",
  id: string,
  name: string,
  description?: string | null,
  defaultRoles?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  tenants?: ModelTenantApplicationConnection | null,
  productDocs?: ModelProductDocConnection | null,
  applicationRoles?: ModelApplicationRoleConnection | null,
};

export type ModelApplicationRoleConnection = {
  __typename: "ModelApplicationRoleConnection",
  items:  Array<ApplicationRole | null >,
  nextToken?: string | null,
};

export type ApplicationRole = {
  __typename: "ApplicationRole",
  id: string,
  roleId: string,
  applicationId: string,
  groupId: string,
  productDocs?: Array< string | null > | null,
  locations?: Array< string | null > | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  application?: Application | null,
  group?: Group | null,
};

export type ModelChapterConnection = {
  __typename: "ModelChapterConnection",
  items:  Array<Chapter | null >,
  nextToken?: string | null,
};

export type Chapter = {
  __typename: "Chapter",
  id: string,
  version: string,
  status: string,
  name: string,
  description?: string | null,
  file?: S3Object | null,
  pdfFile?: S3Object | null,
  isPdfBeingGenerated?: boolean | null,
  createdByUserId: string,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  rootChapterId: string,
  parentProductDocId?: string | null,
  parentChapterId?: string | null,
  shouldBeDeleted?: boolean | null,
  currentTaskId?: string | null,
  isRejected?: boolean | null,
  orderFloat?: string | null,
  tenantId: string,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  parentProductDoc?: ProductDoc | null,
  parentProductDocs?: ModelProductDocConnection | null,
  parentChapter?: Chapter | null,
  parentChapters?: ModelChapterConnection | null,
  subChapters?: ModelChapterConnection | null,
  docs?: ModelDocConnection | null,
  createdByUser?: User | null,
  approvedByUser?: User | null,
  tasks?: ModelTaskConnection | null,
  currentTask?: Task | null,
};

export type ModelDocConnection = {
  __typename: "ModelDocConnection",
  items:  Array<Doc | null >,
  nextToken?: string | null,
};

export type Doc = {
  __typename: "Doc",
  id: string,
  version: string,
  status: string,
  title: string,
  description?: string | null,
  createdByUserId: string,
  pdfFile?: S3Object | null,
  isPdfBeingGenerated?: boolean | null,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  rootChapterId: string,
  chapterId: string,
  shouldBeDeleted?: boolean | null,
  orderFloat?: string | null,
  referenceDocId?: string | null,
  tenantId: string,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  chapter?: Chapter | null,
  parentChapters?: ModelChapterConnection | null,
  referenceDocs?: ModelDocConnection | null,
  docItems?: ModelDocItemConnection | null,
  createdByUser?: User | null,
  approvedByUser?: User | null,
};

export type ModelDocItemConnection = {
  __typename: "ModelDocItemConnection",
  items:  Array<DocItem | null >,
  nextToken?: string | null,
};

export type DocItem = {
  __typename: "DocItem",
  id: string,
  version: string,
  status: string,
  title: string,
  description?: string | null,
  itemType: DocItemType,
  file?: S3Object | null,
  createdByUserId: string,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  rootChapterId: string,
  docId: string,
  createdAt?: string | null,
  shouldBeDeleted?: boolean | null,
  orderFloat?: string | null,
  tenantId: string,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
  updatedAt: string,
  tenant?: Tenant | null,
  doc?: Doc | null,
  parentDocs?: ModelDocConnection | null,
  createdByUser?: User | null,
  approvedByUser?: User | null,
};

export enum DocItemType {
  alert = "alert",
  tip = "tip",
  note = "note",
  image = "image",
  video = "video",
  preRequisite = "preRequisite",
  content = "content",
  code = "code",
}


export type ModelTaskConnection = {
  __typename: "ModelTaskConnection",
  items:  Array<Task | null >,
  nextToken?: string | null,
};

export type Task = {
  __typename: "Task",
  id: string,
  name: string,
  description?: string | null,
  requestedByUserId: string,
  reviewedByUserId?: string | null,
  status: TaskStatusType,
  rootChapterId?: string | null,
  organizationId?: string | null,
  ticketRef?: string | null,
  severity?: string | null,
  severityUpdatedAt?: string | null,
  issueCategory?: string | null,
  subCategory?: string | null,
  productVersion?: string | null,
  closureReason?: string | null,
  type?: string | null,
  rootChapterVersion?: string | null,
  productDocId?: string | null,
  isRejected?: boolean | null,
  usersToNotify?: Array< string | null > | null,
  linkedTickets?: Array< string | null > | null,
  salesForceTicketId?: string | null,
  hasSensitiveInfo?: boolean | null,
  requestForInformation?: string | null,
  isSupportEngineerIdentityHidden?: boolean | null,
  tenantId: string,
  issueType?: string | null,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  productDoc?: ProductDoc | null,
  rootChapter?: Chapter | null,
  requestedByUser: User,
  reviewedByUser?: User | null,
  comments?: ModelCommentConnection | null,
  file?: ModelFileListConnection | null,
  ticketWatchers?: ModelWatcherTaskConnection | null,
  ticketAssignedUsers?: ModelUserTaskConnection | null,
  ticketAssignedGroups?: ModelGroupTaskConnection | null,
};

export enum TaskStatusType {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  comment: string,
  commentedByUserId: string,
  parentCommentId?: string | null,
  taskId?: string | null,
  leadId?: string | null,
  file?: S3Object | null,
  attachments?:  Array<S3Object | null > | null,
  hasSensitiveInfo?: boolean | null,
  requestForInformation?: boolean | null,
  type?: CommentType | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  commentedByUser?: User | null,
  parentComment?: Comment | null,
  replies?: ModelCommentConnection | null,
  task?: Task | null,
  lead?: Lead | null,
};

export enum CommentType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}


export type Lead = {
  __typename: "Lead",
  id: string,
  leadReferenceNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  role?: string | null,
  jobTitle?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  industry?: string | null,
  companyUrl?: string | null,
  leadName?: string | null,
  description?: string | null,
  timeline?: string | null,
  estimatedBudget?: string | null,
  estimatedNumberOfUsers?: number | null,
  solution?: string | null,
  incumbentProduct?: string | null,
  competitors?: string | null,
  submissionDate?: string | null,
  remarks?: string | null,
  createdByUserId: string,
  lastModifiedByUserId?: string | null,
  qualifiedByUserId?: string | null,
  disqualifiedByUserId?: string | null,
  approvedByUserId?: string | null,
  rejectedByUserId?: string | null,
  usersToNotify?: Array< string | null > | null,
  overallStatus?: LeadOverallStatusType | null,
  qualifiedStatus?: LeadQualifiedStatusType | null,
  approvedStatus?: LeadApprovedStatusType | null,
  qualifiedDate?: string | null,
  approvedDate?: string | null,
  qualifiedRemarks?: string | null,
  approvedRemarks?: string | null,
  salesForceLeadId?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  createdByUser: User,
  qualifiedByUser?: User | null,
  disqualifiedByUser?: User | null,
  approvedByUser?: User | null,
  rejectedByUser?: User | null,
  comments?: ModelCommentConnection | null,
  attchments?: ModelFileListConnection | null,
  leadWatchers?: ModelWatcherLeadConnection | null,
};

export enum LeadOverallStatusType {
  QUALIFIED = "QUALIFIED",
  DISQUALIFIED = "DISQUALIFIED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export enum LeadQualifiedStatusType {
  YES = "YES",
  NO = "NO",
}


export enum LeadApprovedStatusType {
  YES = "YES",
  NO = "NO",
}


export type ModelFileListConnection = {
  __typename: "ModelFileListConnection",
  items:  Array<FileList | null >,
  nextToken?: string | null,
};

export type FileList = {
  __typename: "FileList",
  id: string,
  file?: S3Object | null,
  taskId?: string | null,
  leadId?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type ModelWatcherLeadConnection = {
  __typename: "ModelWatcherLeadConnection",
  items:  Array<WatcherLead | null >,
  nextToken?: string | null,
};

export type WatcherLead = {
  __typename: "WatcherLead",
  id: string,
  userId: string,
  leadId: string,
  createdAt: string,
  updatedAt: string,
  users: User,
  leads: Lead,
};

export type ModelWatcherTaskConnection = {
  __typename: "ModelWatcherTaskConnection",
  items:  Array<WatcherTask | null >,
  nextToken?: string | null,
};

export type WatcherTask = {
  __typename: "WatcherTask",
  id: string,
  userId: string,
  taskId: string,
  createdAt: string,
  updatedAt: string,
  users: User,
  tasks: Task,
};

export type ModelUserTaskConnection = {
  __typename: "ModelUserTaskConnection",
  items:  Array<UserTask | null >,
  nextToken?: string | null,
};

export type UserTask = {
  __typename: "UserTask",
  id: string,
  userId: string,
  taskId: string,
  status: TaskUserGroupStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt: string,
  updatedAt: string,
  createdByUser?: User | null,
  updatedByUser?: User | null,
  users: User,
  tasks: Task,
};

export enum TaskUserGroupStatusType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelGroupTaskConnection = {
  __typename: "ModelGroupTaskConnection",
  items:  Array<GroupTask | null >,
  nextToken?: string | null,
};

export type GroupTask = {
  __typename: "GroupTask",
  id: string,
  groupId: string,
  taskId: string,
  status: TaskUserGroupStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt: string,
  updatedAt: string,
  createdByUser?: User | null,
  updatedByUser?: User | null,
  groups: Group,
  tasks: Task,
};

export type ModelTicketRuleUserConnection = {
  __typename: "ModelTicketRuleUserConnection",
  items:  Array<TicketRuleUser | null >,
  nextToken?: string | null,
};

export type TicketRuleUser = {
  __typename: "TicketRuleUser",
  id: string,
  ticketRuleId: string,
  userId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  createdAt: string,
  updatedAt: string,
  createdByUser?: User | null,
  lastModifiedByUser?: User | null,
  users: User,
  ticketRules: TicketRule,
};

export enum TicketRuleJoinTableStatusType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type TicketRule = {
  __typename: "TicketRule",
  id: string,
  name: string,
  description?: string | null,
  status: string,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  createdByUser?: User | null,
  lastModifiedByUser?: User | null,
  sfCountries?: ModelTicketRuleSFCountryConnection | null,
  sfAccounts?: ModelTicketRuleSFAccountConnection | null,
  users?: ModelTicketRuleUserConnection | null,
  groups?: ModelTicketRuleGroupConnection | null,
};

export type ModelTicketRuleSFCountryConnection = {
  __typename: "ModelTicketRuleSFCountryConnection",
  items:  Array<TicketRuleSFCountry | null >,
  nextToken?: string | null,
};

export type TicketRuleSFCountry = {
  __typename: "TicketRuleSFCountry",
  id: string,
  ticketRuleId: string,
  sfCountryId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  createdAt: string,
  updatedAt: string,
  createdByUser?: User | null,
  lastModifiedByUser?: User | null,
  ticketRules: TicketRule,
  sfCountries: SFCountry,
};

export type SFCountry = {
  __typename: "SFCountry",
  id: string,
  name?: string | null,
  countryId?: string | null,
  region?: string | null,
  notesAndAttachments?:  Array<S3Object | null > | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  ticketRules?: ModelTicketRuleSFCountryConnection | null,
  accounts?: ModelSFAccountConnection | null,
  contacts?: ModelSFContactConnection | null,
};

export type ModelSFAccountConnection = {
  __typename: "ModelSFAccountConnection",
  items:  Array<SFAccount | null >,
  nextToken?: string | null,
};

export type ModelSFContactConnection = {
  __typename: "ModelSFContactConnection",
  items:  Array<SFContact | null >,
  nextToken?: string | null,
};

export type SFContact = {
  __typename: "SFContact",
  id: string,
  name?: string | null,
  phone?: string | null,
  accountId?: string | null,
  email?: string | null,
  title?: string | null,
  role?: string | null,
  countryId?: string | null,
  superVisorId?: string | null,
  region?: string | null,
  mailingAddress?: string | null,
  otherAddress?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  files?:  Array<S3Object | null > | null,
  notesAndAttachments?:  Array<S3Object | null > | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  account?: SFAccount | null,
  superVisor?: SFContact | null,
  country?: SFCountry | null,
};

export type ModelTicketRuleSFAccountConnection = {
  __typename: "ModelTicketRuleSFAccountConnection",
  items:  Array<TicketRuleSFAccount | null >,
  nextToken?: string | null,
};

export type TicketRuleSFAccount = {
  __typename: "TicketRuleSFAccount",
  id: string,
  ticketRuleId: string,
  sfAccountId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  createdAt: string,
  updatedAt: string,
  createdByUser?: User | null,
  lastModifiedByUser?: User | null,
  ticketRules: TicketRule,
  sfAccounts: SFAccount,
};

export type ModelTicketRuleGroupConnection = {
  __typename: "ModelTicketRuleGroupConnection",
  items:  Array<TicketRuleGroup | null >,
  nextToken?: string | null,
};

export type TicketRuleGroup = {
  __typename: "TicketRuleGroup",
  id: string,
  ticketRuleId: string,
  groupId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  createdAt: string,
  updatedAt: string,
  createdByUser?: User | null,
  lastModifiedByUser?: User | null,
  groups: Group,
  ticketRules: TicketRule,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name?: string | null,
  domain?: string | null,
  accountId?: string | null,
  recordId?: string | null,
  type?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type ModelLeadConnection = {
  __typename: "ModelLeadConnection",
  items:  Array<Lead | null >,
  nextToken?: string | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items:  Array<Group | null >,
  nextToken?: string | null,
};

export type ModelCurrencyConnection = {
  __typename: "ModelCurrencyConnection",
  items:  Array<Currency | null >,
  nextToken?: string | null,
};

export type Currency = {
  __typename: "Currency",
  id: string,
  name?: string | null,
  symbol?: string | null,
  isDefaultCurrency?: boolean | null,
  code?: string | null,
  country?: string | null,
  exchangeRate?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type ModelSFProductConnection = {
  __typename: "ModelSFProductConnection",
  items:  Array<SFProduct | null >,
  nextToken?: string | null,
};

export type SFProduct = {
  __typename: "SFProduct",
  id: string,
  productName?: string | null,
  productVersion?: string | null,
  productMetric?: string | null,
  productDescription?: string | null,
  sfAccountId?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  productCode?: string | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  sfAccount?: SFAccount | null,
  sfProductSubCategories?: ModelSFProductSubCategoryConnection | null,
  sfProductVersions?: ModelSFProductVersionConnection | null,
};

export type ModelSFProductSubCategoryConnection = {
  __typename: "ModelSFProductSubCategoryConnection",
  items:  Array<SFProductSubCategory | null >,
  nextToken?: string | null,
};

export type SFProductSubCategory = {
  __typename: "SFProductSubCategory",
  id: string,
  name?: string | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  sfProduct?: SFProduct | null,
};

export type ModelSFProductVersionConnection = {
  __typename: "ModelSFProductVersionConnection",
  items:  Array<SFProductVersion | null >,
  nextToken?: string | null,
};

export type SFProductVersion = {
  __typename: "SFProductVersion",
  id: string,
  name?: string | null,
  version?: string | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  sfProduct?: SFProduct | null,
};

export type UpdateSFAccountInput = {
  id: string,
  name?: string | null,
  accountName?: string | null,
  countryId?: string | null,
  parentAccountId?: string | null,
  emailDomain?: string | null,
  industry?: string | null,
  organizationId?: string | null,
  billingAddress?: string | null,
  shippingAddress?: string | null,
  copyBillingAddressToShippingAddress?: boolean | null,
  type?: string | null,
  phone?: string | null,
  website?: string | null,
  createdBy?: string | null,
  createdDate?: string | null,
  updatedBy?: string | null,
  updatedDate?: string | null,
  files?: Array< S3ObjectInput | null > | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFAccountInput = {
  id: string,
};

export type CreateSFContractInput = {
  id?: string | null,
  contractNumber?: string | null,
  accountId?: string | null,
  contractTerm?: string | null,
  status?: string | null,
  ownerExpirationNotice?: string | null,
  totalContractValue?: string | null,
  contractType?: string | null,
  poNumber?: string | null,
  poDate?: string | null,
  referenceNumber?: string | null,
  partnerAccountId?: string | null,
  distributorAccountId?: string | null,
  paymentTerms?: string | null,
  poIssuedById?: string | null,
  contactId?: string | null,
  salesRepresentativeNameId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  customerAddressId?: string | null,
  designationId?: string | null,
  emailId?: string | null,
  mobileId?: string | null,
  year1MaintenanceEndDate?: string | null,
  year1MaintenanceStartDate?: string | null,
  year2MaintenanceEndDate?: string | null,
  year2MaintenanceStartDate?: string | null,
  year3MaintenanceEndDate?: string | null,
  year3MaintenanceStartDate?: string | null,
  value?: string | null,
  billingAddress?: string | null,
  description?: string | null,
  specialTerms?: string | null,
  signedById?: string | null,
  companySignedId?: string | null,
  activatedById?: string | null,
  activatedDate?: string | null,
  files?: Array< S3ObjectInput | null > | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  invoiceDate?: string | null,
  tenantId: string,
};

export type ModelSFContractConditionInput = {
  contractNumber?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  contractTerm?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ownerExpirationNotice?: ModelStringInput | null,
  totalContractValue?: ModelStringInput | null,
  contractType?: ModelStringInput | null,
  poNumber?: ModelStringInput | null,
  poDate?: ModelStringInput | null,
  referenceNumber?: ModelStringInput | null,
  partnerAccountId?: ModelIDInput | null,
  distributorAccountId?: ModelIDInput | null,
  paymentTerms?: ModelStringInput | null,
  poIssuedById?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  salesRepresentativeNameId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  customerAddressId?: ModelIDInput | null,
  designationId?: ModelIDInput | null,
  emailId?: ModelIDInput | null,
  mobileId?: ModelIDInput | null,
  year1MaintenanceEndDate?: ModelStringInput | null,
  year1MaintenanceStartDate?: ModelStringInput | null,
  year2MaintenanceEndDate?: ModelStringInput | null,
  year2MaintenanceStartDate?: ModelStringInput | null,
  year3MaintenanceEndDate?: ModelStringInput | null,
  year3MaintenanceStartDate?: ModelStringInput | null,
  value?: ModelStringInput | null,
  billingAddress?: ModelStringInput | null,
  description?: ModelStringInput | null,
  specialTerms?: ModelStringInput | null,
  signedById?: ModelIDInput | null,
  companySignedId?: ModelIDInput | null,
  activatedById?: ModelIDInput | null,
  activatedDate?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  invoiceDate?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFContractConditionInput | null > | null,
  or?: Array< ModelSFContractConditionInput | null > | null,
  not?: ModelSFContractConditionInput | null,
};

export type SFContract = {
  __typename: "SFContract",
  id: string,
  contractNumber?: string | null,
  accountId?: string | null,
  contractTerm?: string | null,
  status?: string | null,
  ownerExpirationNotice?: string | null,
  totalContractValue?: string | null,
  contractType?: string | null,
  poNumber?: string | null,
  poDate?: string | null,
  referenceNumber?: string | null,
  partnerAccountId?: string | null,
  distributorAccountId?: string | null,
  paymentTerms?: string | null,
  poIssuedById?: string | null,
  contactId?: string | null,
  salesRepresentativeNameId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  customerAddressId?: string | null,
  designationId?: string | null,
  emailId?: string | null,
  mobileId?: string | null,
  year1MaintenanceEndDate?: string | null,
  year1MaintenanceStartDate?: string | null,
  year2MaintenanceEndDate?: string | null,
  year2MaintenanceStartDate?: string | null,
  year3MaintenanceEndDate?: string | null,
  year3MaintenanceStartDate?: string | null,
  value?: string | null,
  billingAddress?: string | null,
  description?: string | null,
  specialTerms?: string | null,
  signedById?: string | null,
  companySignedId?: string | null,
  activatedById?: string | null,
  activatedDate?: string | null,
  files?:  Array<S3Object | null > | null,
  notesAndAttachments?:  Array<S3Object | null > | null,
  allSFData?: string | null,
  invoiceDate?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  companySigned?: User | null,
  activatedBy?: User | null,
  account?: SFAccount | null,
  partnerAccount?: SFAccount | null,
  distributorAccount?: SFAccount | null,
  poIssuedBy?: SFAccount | null,
  customerAddress?: SFAccount | null,
  contact?: SFContact | null,
  salesRepresentativeName?: SFContact | null,
  designation?: SFContact | null,
  email?: SFContact | null,
  mobile?: SFContact | null,
  signedBy?: SFContact | null,
};

export type UpdateSFContractInput = {
  id: string,
  contractNumber?: string | null,
  accountId?: string | null,
  contractTerm?: string | null,
  status?: string | null,
  ownerExpirationNotice?: string | null,
  totalContractValue?: string | null,
  contractType?: string | null,
  poNumber?: string | null,
  poDate?: string | null,
  referenceNumber?: string | null,
  partnerAccountId?: string | null,
  distributorAccountId?: string | null,
  paymentTerms?: string | null,
  poIssuedById?: string | null,
  contactId?: string | null,
  salesRepresentativeNameId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  customerAddressId?: string | null,
  designationId?: string | null,
  emailId?: string | null,
  mobileId?: string | null,
  year1MaintenanceEndDate?: string | null,
  year1MaintenanceStartDate?: string | null,
  year2MaintenanceEndDate?: string | null,
  year2MaintenanceStartDate?: string | null,
  year3MaintenanceEndDate?: string | null,
  year3MaintenanceStartDate?: string | null,
  value?: string | null,
  billingAddress?: string | null,
  description?: string | null,
  specialTerms?: string | null,
  signedById?: string | null,
  companySignedId?: string | null,
  activatedById?: string | null,
  activatedDate?: string | null,
  files?: Array< S3ObjectInput | null > | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  invoiceDate?: string | null,
  tenantId?: string | null,
};

export type DeleteSFContractInput = {
  id: string,
};

export type CreateSFContractLineItemInput = {
  id?: string | null,
  contractName?: string | null,
  itemNo?: string | null,
  productId?: string | null,
  quantity?: string | null,
  contractId?: string | null,
  productListPrice?: string | null,
  discount?: string | null,
  amount?: string | null,
  version?: string | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFContractLineItemConditionInput = {
  contractName?: ModelStringInput | null,
  itemNo?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  quantity?: ModelStringInput | null,
  contractId?: ModelIDInput | null,
  productListPrice?: ModelStringInput | null,
  discount?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  version?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFContractLineItemConditionInput | null > | null,
  or?: Array< ModelSFContractLineItemConditionInput | null > | null,
  not?: ModelSFContractLineItemConditionInput | null,
};

export type SFContractLineItem = {
  __typename: "SFContractLineItem",
  id: string,
  contractName?: string | null,
  itemNo?: string | null,
  productId?: string | null,
  quantity?: string | null,
  contractId?: string | null,
  productListPrice?: string | null,
  discount?: string | null,
  amount?: string | null,
  version?: string | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  contract?: SFContract | null,
  product?: SFProduct | null,
};

export type UpdateSFContractLineItemInput = {
  id: string,
  contractName?: string | null,
  itemNo?: string | null,
  productId?: string | null,
  quantity?: string | null,
  contractId?: string | null,
  productListPrice?: string | null,
  discount?: string | null,
  amount?: string | null,
  version?: string | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFContractLineItemInput = {
  id: string,
};

export type CreateSFContactInput = {
  id?: string | null,
  name?: string | null,
  phone?: string | null,
  accountId?: string | null,
  email?: string | null,
  title?: string | null,
  role?: string | null,
  countryId?: string | null,
  superVisorId?: string | null,
  region?: string | null,
  mailingAddress?: string | null,
  otherAddress?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  files?: Array< S3ObjectInput | null > | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFContactConditionInput = {
  name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  title?: ModelStringInput | null,
  role?: ModelStringInput | null,
  countryId?: ModelIDInput | null,
  superVisorId?: ModelIDInput | null,
  region?: ModelStringInput | null,
  mailingAddress?: ModelStringInput | null,
  otherAddress?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFContactConditionInput | null > | null,
  or?: Array< ModelSFContactConditionInput | null > | null,
  not?: ModelSFContactConditionInput | null,
};

export type UpdateSFContactInput = {
  id: string,
  name?: string | null,
  phone?: string | null,
  accountId?: string | null,
  email?: string | null,
  title?: string | null,
  role?: string | null,
  countryId?: string | null,
  superVisorId?: string | null,
  region?: string | null,
  mailingAddress?: string | null,
  otherAddress?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  files?: Array< S3ObjectInput | null > | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFContactInput = {
  id: string,
};

export type CreateSFProductInput = {
  id?: string | null,
  productName?: string | null,
  productVersion?: string | null,
  productMetric?: string | null,
  productDescription?: string | null,
  sfAccountId?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  productCode?: string | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFProductConditionInput = {
  productName?: ModelStringInput | null,
  productVersion?: ModelStringInput | null,
  productMetric?: ModelStringInput | null,
  productDescription?: ModelStringInput | null,
  sfAccountId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  productCode?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFProductConditionInput | null > | null,
  or?: Array< ModelSFProductConditionInput | null > | null,
  not?: ModelSFProductConditionInput | null,
};

export type UpdateSFProductInput = {
  id: string,
  productName?: string | null,
  productVersion?: string | null,
  productMetric?: string | null,
  productDescription?: string | null,
  sfAccountId?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  productCode?: string | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFProductInput = {
  id: string,
};

export type CreateSFProductSubCategoryInput = {
  id?: string | null,
  name?: string | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFProductSubCategoryConditionInput = {
  name?: ModelStringInput | null,
  sfProductId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFProductSubCategoryConditionInput | null > | null,
  or?: Array< ModelSFProductSubCategoryConditionInput | null > | null,
  not?: ModelSFProductSubCategoryConditionInput | null,
};

export type UpdateSFProductSubCategoryInput = {
  id: string,
  name?: string | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFProductSubCategoryInput = {
  id: string,
};

export type CreateSFProductVersionInput = {
  id?: string | null,
  name?: string | null,
  version?: string | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFProductVersionConditionInput = {
  name?: ModelStringInput | null,
  version?: ModelStringInput | null,
  sfProductId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFProductVersionConditionInput | null > | null,
  or?: Array< ModelSFProductVersionConditionInput | null > | null,
  not?: ModelSFProductVersionConditionInput | null,
};

export type UpdateSFProductVersionInput = {
  id: string,
  name?: string | null,
  version?: string | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFProductVersionInput = {
  id: string,
};

export type CreateSFIndustryInput = {
  id?: string | null,
  label?: string | null,
  value?: string | null,
  tenantId: string,
};

export type ModelSFIndustryConditionInput = {
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFIndustryConditionInput | null > | null,
  or?: Array< ModelSFIndustryConditionInput | null > | null,
  not?: ModelSFIndustryConditionInput | null,
};

export type SFIndustry = {
  __typename: "SFIndustry",
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type UpdateSFIndustryInput = {
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId?: string | null,
};

export type DeleteSFIndustryInput = {
  id: string,
};

export type CreateSFIssueCategoryInput = {
  id?: string | null,
  label?: string | null,
  value?: string | null,
  tenantId: string,
};

export type ModelSFIssueCategoryConditionInput = {
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFIssueCategoryConditionInput | null > | null,
  or?: Array< ModelSFIssueCategoryConditionInput | null > | null,
  not?: ModelSFIssueCategoryConditionInput | null,
};

export type SFIssueCategory = {
  __typename: "SFIssueCategory",
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type UpdateSFIssueCategoryInput = {
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId?: string | null,
};

export type DeleteSFIssueCategoryInput = {
  id: string,
};

export type CreateSFCaseSeverityInput = {
  id?: string | null,
  label?: string | null,
  value?: string | null,
  tenantId: string,
};

export type ModelSFCaseSeverityConditionInput = {
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFCaseSeverityConditionInput | null > | null,
  or?: Array< ModelSFCaseSeverityConditionInput | null > | null,
  not?: ModelSFCaseSeverityConditionInput | null,
};

export type SFCaseSeverity = {
  __typename: "SFCaseSeverity",
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type UpdateSFCaseSeverityInput = {
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId?: string | null,
};

export type DeleteSFCaseSeverityInput = {
  id: string,
};

export type CreateSFCountryInput = {
  id?: string | null,
  name?: string | null,
  countryId?: string | null,
  region?: string | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFCountryConditionInput = {
  name?: ModelStringInput | null,
  countryId?: ModelStringInput | null,
  region?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFCountryConditionInput | null > | null,
  or?: Array< ModelSFCountryConditionInput | null > | null,
  not?: ModelSFCountryConditionInput | null,
};

export type UpdateSFCountryInput = {
  id: string,
  name?: string | null,
  countryId?: string | null,
  region?: string | null,
  notesAndAttachments?: Array< S3ObjectInput | null > | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFCountryInput = {
  id: string,
};

export type CreateSFRoleInput = {
  id?: string | null,
  label?: string | null,
  value?: string | null,
  tenantId: string,
};

export type ModelSFRoleConditionInput = {
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFRoleConditionInput | null > | null,
  or?: Array< ModelSFRoleConditionInput | null > | null,
  not?: ModelSFRoleConditionInput | null,
};

export type SFRole = {
  __typename: "SFRole",
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type UpdateSFRoleInput = {
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId?: string | null,
};

export type DeleteSFRoleInput = {
  id: string,
};

export type CreateSFSolutionInput = {
  id?: string | null,
  name?: string | null,
  leadId?: string | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFSolutionConditionInput = {
  name?: ModelStringInput | null,
  leadId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFSolutionConditionInput | null > | null,
  or?: Array< ModelSFSolutionConditionInput | null > | null,
  not?: ModelSFSolutionConditionInput | null,
};

export type SFSolution = {
  __typename: "SFSolution",
  id: string,
  name?: string | null,
  leadId?: string | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  competitors?: ModelSFCompetitorConnection | null,
};

export type ModelSFCompetitorConnection = {
  __typename: "ModelSFCompetitorConnection",
  items:  Array<SFCompetitor | null >,
  nextToken?: string | null,
};

export type SFCompetitor = {
  __typename: "SFCompetitor",
  id: string,
  name?: string | null,
  domain?: string | null,
  competitors?: string | null,
  solutionId?: string | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  solution?: SFSolution | null,
};

export type UpdateSFSolutionInput = {
  id: string,
  name?: string | null,
  leadId?: string | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFSolutionInput = {
  id: string,
};

export type CreateSFClosureReasonInput = {
  id?: string | null,
  label?: string | null,
  value?: string | null,
  tenantId: string,
};

export type ModelSFClosureReasonConditionInput = {
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFClosureReasonConditionInput | null > | null,
  or?: Array< ModelSFClosureReasonConditionInput | null > | null,
  not?: ModelSFClosureReasonConditionInput | null,
};

export type SFClosureReason = {
  __typename: "SFClosureReason",
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
};

export type UpdateSFClosureReasonInput = {
  id: string,
  label?: string | null,
  value?: string | null,
  tenantId?: string | null,
};

export type DeleteSFClosureReasonInput = {
  id: string,
};

export type CreateApplicationInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  defaultRoles?: Array< string | null > | null,
};

export type ModelApplicationConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  defaultRoles?: ModelStringInput | null,
  and?: Array< ModelApplicationConditionInput | null > | null,
  or?: Array< ModelApplicationConditionInput | null > | null,
  not?: ModelApplicationConditionInput | null,
};

export type UpdateApplicationInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  defaultRoles?: Array< string | null > | null,
};

export type DeleteApplicationInput = {
  id: string,
};

export type CreateApplicationRoleInput = {
  id?: string | null,
  roleId: string,
  applicationId: string,
  groupId: string,
  productDocs?: Array< string | null > | null,
  locations?: Array< string | null > | null,
  tenantId: string,
};

export type ModelApplicationRoleConditionInput = {
  roleId?: ModelStringInput | null,
  applicationId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  productDocs?: ModelIDInput | null,
  locations?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelApplicationRoleConditionInput | null > | null,
  or?: Array< ModelApplicationRoleConditionInput | null > | null,
  not?: ModelApplicationRoleConditionInput | null,
};

export type UpdateApplicationRoleInput = {
  id: string,
  roleId?: string | null,
  applicationId?: string | null,
  groupId?: string | null,
  productDocs?: Array< string | null > | null,
  locations?: Array< string | null > | null,
  tenantId?: string | null,
};

export type DeleteApplicationRoleInput = {
  id: string,
};

export type CreateFileListInput = {
  id?: string | null,
  file?: S3ObjectInput | null,
  taskId?: string | null,
  leadId?: string | null,
  tenantId: string,
};

export type ModelFileListConditionInput = {
  taskId?: ModelIDInput | null,
  leadId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelFileListConditionInput | null > | null,
  or?: Array< ModelFileListConditionInput | null > | null,
  not?: ModelFileListConditionInput | null,
};

export type UpdateFileListInput = {
  id: string,
  file?: S3ObjectInput | null,
  taskId?: string | null,
  leadId?: string | null,
  tenantId?: string | null,
};

export type DeleteFileListInput = {
  id: string,
};

export type CreateGroupInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  status?: ActiveStatusType | null,
  organizationId?: string | null,
  tenantId: string,
};

export type ModelGroupConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelActiveStatusTypeInput | null,
  organizationId?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
};

export type ModelActiveStatusTypeInput = {
  eq?: ActiveStatusType | null,
  ne?: ActiveStatusType | null,
};

export type UpdateGroupInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  status?: ActiveStatusType | null,
  organizationId?: string | null,
  tenantId?: string | null,
};

export type DeleteGroupInput = {
  id: string,
};

export type CreateUserGroupInput = {
  id?: string | null,
  userId: string,
  groupId: string,
};

export type ModelUserGroupConditionInput = {
  userId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  and?: Array< ModelUserGroupConditionInput | null > | null,
  or?: Array< ModelUserGroupConditionInput | null > | null,
  not?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupInput = {
  id: string,
  userId?: string | null,
  groupId?: string | null,
};

export type DeleteUserGroupInput = {
  id: string,
};

export type CreateWatcherTaskInput = {
  id?: string | null,
  userId: string,
  taskId: string,
};

export type ModelWatcherTaskConditionInput = {
  userId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  and?: Array< ModelWatcherTaskConditionInput | null > | null,
  or?: Array< ModelWatcherTaskConditionInput | null > | null,
  not?: ModelWatcherTaskConditionInput | null,
};

export type UpdateWatcherTaskInput = {
  id: string,
  userId?: string | null,
  taskId?: string | null,
};

export type DeleteWatcherTaskInput = {
  id: string,
};

export type CreateTaskInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  requestedByUserId: string,
  reviewedByUserId?: string | null,
  status: TaskStatusType,
  rootChapterId?: string | null,
  organizationId?: string | null,
  ticketRef?: string | null,
  severity?: string | null,
  severityUpdatedAt?: string | null,
  issueCategory?: string | null,
  subCategory?: string | null,
  productVersion?: string | null,
  closureReason?: string | null,
  type?: string | null,
  rootChapterVersion?: string | null,
  productDocId?: string | null,
  isRejected?: boolean | null,
  usersToNotify?: Array< string | null > | null,
  linkedTickets?: Array< string | null > | null,
  salesForceTicketId?: string | null,
  hasSensitiveInfo?: boolean | null,
  requestForInformation?: string | null,
  isSupportEngineerIdentityHidden?: boolean | null,
  tenantId: string,
  issueType?: string | null,
};

export type ModelTaskConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  requestedByUserId?: ModelIDInput | null,
  reviewedByUserId?: ModelIDInput | null,
  status?: ModelTaskStatusTypeInput | null,
  rootChapterId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  ticketRef?: ModelStringInput | null,
  severity?: ModelStringInput | null,
  severityUpdatedAt?: ModelStringInput | null,
  issueCategory?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  productVersion?: ModelStringInput | null,
  closureReason?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rootChapterVersion?: ModelStringInput | null,
  productDocId?: ModelIDInput | null,
  isRejected?: ModelBooleanInput | null,
  usersToNotify?: ModelStringInput | null,
  linkedTickets?: ModelStringInput | null,
  salesForceTicketId?: ModelStringInput | null,
  hasSensitiveInfo?: ModelBooleanInput | null,
  requestForInformation?: ModelStringInput | null,
  isSupportEngineerIdentityHidden?: ModelBooleanInput | null,
  tenantId?: ModelIDInput | null,
  issueType?: ModelStringInput | null,
  and?: Array< ModelTaskConditionInput | null > | null,
  or?: Array< ModelTaskConditionInput | null > | null,
  not?: ModelTaskConditionInput | null,
};

export type ModelTaskStatusTypeInput = {
  eq?: TaskStatusType | null,
  ne?: TaskStatusType | null,
};

export type UpdateTaskInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  requestedByUserId?: string | null,
  reviewedByUserId?: string | null,
  status?: TaskStatusType | null,
  rootChapterId?: string | null,
  organizationId?: string | null,
  ticketRef?: string | null,
  severity?: string | null,
  severityUpdatedAt?: string | null,
  issueCategory?: string | null,
  subCategory?: string | null,
  productVersion?: string | null,
  closureReason?: string | null,
  type?: string | null,
  rootChapterVersion?: string | null,
  productDocId?: string | null,
  isRejected?: boolean | null,
  usersToNotify?: Array< string | null > | null,
  linkedTickets?: Array< string | null > | null,
  salesForceTicketId?: string | null,
  hasSensitiveInfo?: boolean | null,
  requestForInformation?: string | null,
  isSupportEngineerIdentityHidden?: boolean | null,
  tenantId?: string | null,
  issueType?: string | null,
};

export type DeleteTaskInput = {
  id: string,
};

export type CreateChapterInput = {
  id?: string | null,
  version: string,
  status: string,
  name: string,
  description?: string | null,
  file?: S3ObjectInput | null,
  pdfFile?: S3ObjectInput | null,
  isPdfBeingGenerated?: boolean | null,
  createdByUserId: string,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  rootChapterId: string,
  parentProductDocId?: string | null,
  parentChapterId?: string | null,
  shouldBeDeleted?: boolean | null,
  currentTaskId?: string | null,
  isRejected?: boolean | null,
  orderFloat?: string | null,
  tenantId: string,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
};

export type ModelChapterConditionInput = {
  status?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  isPdfBeingGenerated?: ModelBooleanInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rootChapterId?: ModelIDInput | null,
  parentProductDocId?: ModelIDInput | null,
  parentChapterId?: ModelIDInput | null,
  shouldBeDeleted?: ModelBooleanInput | null,
  currentTaskId?: ModelIDInput | null,
  isRejected?: ModelBooleanInput | null,
  orderFloat?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  isArchivedByOrphanRemovalScript?: ModelBooleanInput | null,
  archivedByOrphanRemovalScript?: ModelStringInput | null,
  archivedByIrrelevantContentRemovalScript?: ModelStringInput | null,
  and?: Array< ModelChapterConditionInput | null > | null,
  or?: Array< ModelChapterConditionInput | null > | null,
  not?: ModelChapterConditionInput | null,
};

export type UpdateChapterInput = {
  id: string,
  version: string,
  status?: string | null,
  name?: string | null,
  description?: string | null,
  file?: S3ObjectInput | null,
  pdfFile?: S3ObjectInput | null,
  isPdfBeingGenerated?: boolean | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  approvedByUserId?: string | null,
  rootChapterId?: string | null,
  parentProductDocId?: string | null,
  parentChapterId?: string | null,
  shouldBeDeleted?: boolean | null,
  currentTaskId?: string | null,
  isRejected?: boolean | null,
  orderFloat?: string | null,
  tenantId?: string | null,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
};

export type DeleteChapterInput = {
  id: string,
  version: string,
};

export type CreateDocInput = {
  id?: string | null,
  version: string,
  status: string,
  title: string,
  description?: string | null,
  createdByUserId: string,
  pdfFile?: S3ObjectInput | null,
  isPdfBeingGenerated?: boolean | null,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  rootChapterId: string,
  chapterId: string,
  shouldBeDeleted?: boolean | null,
  orderFloat?: string | null,
  referenceDocId?: string | null,
  tenantId: string,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
};

export type ModelDocConditionInput = {
  status?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  isPdfBeingGenerated?: ModelBooleanInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rootChapterId?: ModelIDInput | null,
  chapterId?: ModelIDInput | null,
  shouldBeDeleted?: ModelBooleanInput | null,
  orderFloat?: ModelStringInput | null,
  referenceDocId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  isArchivedByOrphanRemovalScript?: ModelBooleanInput | null,
  archivedByOrphanRemovalScript?: ModelStringInput | null,
  archivedByIrrelevantContentRemovalScript?: ModelStringInput | null,
  and?: Array< ModelDocConditionInput | null > | null,
  or?: Array< ModelDocConditionInput | null > | null,
  not?: ModelDocConditionInput | null,
};

export type UpdateDocInput = {
  id: string,
  version: string,
  status?: string | null,
  title?: string | null,
  description?: string | null,
  createdByUserId?: string | null,
  pdfFile?: S3ObjectInput | null,
  isPdfBeingGenerated?: boolean | null,
  lastModifiedByUserId?: string | null,
  approvedByUserId?: string | null,
  rootChapterId?: string | null,
  chapterId?: string | null,
  shouldBeDeleted?: boolean | null,
  orderFloat?: string | null,
  referenceDocId?: string | null,
  tenantId?: string | null,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
};

export type DeleteDocInput = {
  id: string,
  version: string,
};

export type CreateDocItemInput = {
  id?: string | null,
  version: string,
  status: string,
  title: string,
  description?: string | null,
  itemType: DocItemType,
  file?: S3ObjectInput | null,
  createdByUserId: string,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  rootChapterId: string,
  docId: string,
  createdAt?: string | null,
  shouldBeDeleted?: boolean | null,
  orderFloat?: string | null,
  tenantId: string,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
};

export type ModelDocItemConditionInput = {
  status?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  itemType?: ModelDocItemTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rootChapterId?: ModelIDInput | null,
  docId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  shouldBeDeleted?: ModelBooleanInput | null,
  orderFloat?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  isArchivedByOrphanRemovalScript?: ModelBooleanInput | null,
  archivedByOrphanRemovalScript?: ModelStringInput | null,
  archivedByIrrelevantContentRemovalScript?: ModelStringInput | null,
  and?: Array< ModelDocItemConditionInput | null > | null,
  or?: Array< ModelDocItemConditionInput | null > | null,
  not?: ModelDocItemConditionInput | null,
};

export type ModelDocItemTypeInput = {
  eq?: DocItemType | null,
  ne?: DocItemType | null,
};

export type UpdateDocItemInput = {
  id: string,
  version: string,
  status?: string | null,
  title?: string | null,
  description?: string | null,
  itemType?: DocItemType | null,
  file?: S3ObjectInput | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  approvedByUserId?: string | null,
  rootChapterId?: string | null,
  docId?: string | null,
  createdAt?: string | null,
  shouldBeDeleted?: boolean | null,
  orderFloat?: string | null,
  tenantId?: string | null,
  isArchivedByOrphanRemovalScript?: boolean | null,
  archivedByOrphanRemovalScript?: string | null,
  archivedByIrrelevantContentRemovalScript?: string | null,
};

export type DeleteDocItemInput = {
  id: string,
  version: string,
};

export type CreateProductDocInput = {
  id?: string | null,
  version: string,
  status: string,
  name: string,
  description?: string | null,
  file?: S3ObjectInput | null,
  createdByUserId: string,
  lastModifiedByUserId: string,
  approvedByUserId?: string | null,
  applicationId: string,
  orderFloat?: string | null,
  tenantId: string,
};

export type ModelProductDocConditionInput = {
  status?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  applicationId?: ModelIDInput | null,
  orderFloat?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelProductDocConditionInput | null > | null,
  or?: Array< ModelProductDocConditionInput | null > | null,
  not?: ModelProductDocConditionInput | null,
};

export type UpdateProductDocInput = {
  id: string,
  version: string,
  status?: string | null,
  name?: string | null,
  description?: string | null,
  file?: S3ObjectInput | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  approvedByUserId?: string | null,
  applicationId?: string | null,
  orderFloat?: string | null,
  tenantId?: string | null,
};

export type DeleteProductDocInput = {
  id: string,
  version: string,
};

export type CreateCommentInput = {
  id?: string | null,
  comment: string,
  commentedByUserId: string,
  parentCommentId?: string | null,
  taskId?: string | null,
  leadId?: string | null,
  file?: S3ObjectInput | null,
  attachments?: Array< S3ObjectInput | null > | null,
  hasSensitiveInfo?: boolean | null,
  requestForInformation?: boolean | null,
  type?: CommentType | null,
  tenantId: string,
};

export type ModelCommentConditionInput = {
  comment?: ModelStringInput | null,
  commentedByUserId?: ModelIDInput | null,
  parentCommentId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  leadId?: ModelIDInput | null,
  hasSensitiveInfo?: ModelBooleanInput | null,
  requestForInformation?: ModelBooleanInput | null,
  type?: ModelCommentTypeInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type ModelCommentTypeInput = {
  eq?: CommentType | null,
  ne?: CommentType | null,
};

export type UpdateCommentInput = {
  id: string,
  comment?: string | null,
  commentedByUserId?: string | null,
  parentCommentId?: string | null,
  taskId?: string | null,
  leadId?: string | null,
  file?: S3ObjectInput | null,
  attachments?: Array< S3ObjectInput | null > | null,
  hasSensitiveInfo?: boolean | null,
  requestForInformation?: boolean | null,
  type?: CommentType | null,
  tenantId?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  userType: UserType,
  firstName: string,
  lastName: string,
  displayName?: string | null,
  jobTitle?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  email: string,
  organizationId?: string | null,
  salesForceId?: string | null,
  role?: RoleType | null,
  secondaryEmail: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  file?: S3ObjectInput | null,
  status?: ActiveStatusType | null,
  isMigrated?: boolean | null,
  isTenantAdmin?: boolean | null,
  ticketsToReviewOrder?: Array< string | null > | null,
  supportPortalDashBoardChartsOrder?: Array< number | null > | null,
  tenantId: string,
  preferredCurrency?: string | null,
};

export type ModelUserConditionInput = {
  userType?: ModelUserTypeInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  email?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  salesForceId?: ModelStringInput | null,
  role?: ModelRoleTypeInput | null,
  secondaryEmail?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelActiveStatusTypeInput | null,
  isMigrated?: ModelBooleanInput | null,
  isTenantAdmin?: ModelBooleanInput | null,
  ticketsToReviewOrder?: ModelStringInput | null,
  supportPortalDashBoardChartsOrder?: ModelIntInput | null,
  tenantId?: ModelIDInput | null,
  preferredCurrency?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type ModelRoleTypeInput = {
  eq?: RoleType | null,
  ne?: RoleType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateUserInput = {
  id: string,
  userType?: UserType | null,
  firstName?: string | null,
  lastName?: string | null,
  displayName?: string | null,
  jobTitle?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  email?: string | null,
  organizationId?: string | null,
  salesForceId?: string | null,
  role?: RoleType | null,
  secondaryEmail?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  file?: S3ObjectInput | null,
  status?: ActiveStatusType | null,
  isMigrated?: boolean | null,
  isTenantAdmin?: boolean | null,
  ticketsToReviewOrder?: Array< string | null > | null,
  supportPortalDashBoardChartsOrder?: Array< number | null > | null,
  tenantId?: string | null,
  preferredCurrency?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateLeadInput = {
  id?: string | null,
  leadReferenceNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  role?: string | null,
  jobTitle?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  industry?: string | null,
  companyUrl?: string | null,
  leadName?: string | null,
  description?: string | null,
  timeline?: string | null,
  estimatedBudget?: string | null,
  estimatedNumberOfUsers?: number | null,
  solution?: string | null,
  incumbentProduct?: string | null,
  competitors?: string | null,
  submissionDate?: string | null,
  remarks?: string | null,
  createdByUserId: string,
  lastModifiedByUserId?: string | null,
  qualifiedByUserId?: string | null,
  disqualifiedByUserId?: string | null,
  approvedByUserId?: string | null,
  rejectedByUserId?: string | null,
  usersToNotify?: Array< string | null > | null,
  overallStatus?: LeadOverallStatusType | null,
  qualifiedStatus?: LeadQualifiedStatusType | null,
  approvedStatus?: LeadApprovedStatusType | null,
  qualifiedDate?: string | null,
  approvedDate?: string | null,
  qualifiedRemarks?: string | null,
  approvedRemarks?: string | null,
  salesForceLeadId?: string | null,
  tenantId: string,
};

export type ModelLeadConditionInput = {
  leadReferenceNumber?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  role?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  companyAddress?: ModelStringInput | null,
  industry?: ModelStringInput | null,
  companyUrl?: ModelStringInput | null,
  leadName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  timeline?: ModelStringInput | null,
  estimatedBudget?: ModelStringInput | null,
  estimatedNumberOfUsers?: ModelIntInput | null,
  solution?: ModelStringInput | null,
  incumbentProduct?: ModelStringInput | null,
  competitors?: ModelStringInput | null,
  submissionDate?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  qualifiedByUserId?: ModelIDInput | null,
  disqualifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rejectedByUserId?: ModelIDInput | null,
  usersToNotify?: ModelStringInput | null,
  overallStatus?: ModelLeadOverallStatusTypeInput | null,
  qualifiedStatus?: ModelLeadQualifiedStatusTypeInput | null,
  approvedStatus?: ModelLeadApprovedStatusTypeInput | null,
  qualifiedDate?: ModelStringInput | null,
  approvedDate?: ModelStringInput | null,
  qualifiedRemarks?: ModelStringInput | null,
  approvedRemarks?: ModelStringInput | null,
  salesForceLeadId?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelLeadConditionInput | null > | null,
  or?: Array< ModelLeadConditionInput | null > | null,
  not?: ModelLeadConditionInput | null,
};

export type ModelLeadOverallStatusTypeInput = {
  eq?: LeadOverallStatusType | null,
  ne?: LeadOverallStatusType | null,
};

export type ModelLeadQualifiedStatusTypeInput = {
  eq?: LeadQualifiedStatusType | null,
  ne?: LeadQualifiedStatusType | null,
};

export type ModelLeadApprovedStatusTypeInput = {
  eq?: LeadApprovedStatusType | null,
  ne?: LeadApprovedStatusType | null,
};

export type UpdateLeadInput = {
  id: string,
  leadReferenceNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  role?: string | null,
  jobTitle?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  industry?: string | null,
  companyUrl?: string | null,
  leadName?: string | null,
  description?: string | null,
  timeline?: string | null,
  estimatedBudget?: string | null,
  estimatedNumberOfUsers?: number | null,
  solution?: string | null,
  incumbentProduct?: string | null,
  competitors?: string | null,
  submissionDate?: string | null,
  remarks?: string | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  qualifiedByUserId?: string | null,
  disqualifiedByUserId?: string | null,
  approvedByUserId?: string | null,
  rejectedByUserId?: string | null,
  usersToNotify?: Array< string | null > | null,
  overallStatus?: LeadOverallStatusType | null,
  qualifiedStatus?: LeadQualifiedStatusType | null,
  approvedStatus?: LeadApprovedStatusType | null,
  qualifiedDate?: string | null,
  approvedDate?: string | null,
  qualifiedRemarks?: string | null,
  approvedRemarks?: string | null,
  salesForceLeadId?: string | null,
  tenantId?: string | null,
};

export type DeleteLeadInput = {
  id: string,
};

export type CreateWatcherLeadInput = {
  id?: string | null,
  userId: string,
  leadId: string,
};

export type ModelWatcherLeadConditionInput = {
  userId?: ModelIDInput | null,
  leadId?: ModelIDInput | null,
  and?: Array< ModelWatcherLeadConditionInput | null > | null,
  or?: Array< ModelWatcherLeadConditionInput | null > | null,
  not?: ModelWatcherLeadConditionInput | null,
};

export type UpdateWatcherLeadInput = {
  id: string,
  userId?: string | null,
  leadId?: string | null,
};

export type DeleteWatcherLeadInput = {
  id: string,
};

export type CreateCurrencyInput = {
  id?: string | null,
  name?: string | null,
  symbol?: string | null,
  isDefaultCurrency?: boolean | null,
  code?: string | null,
  country?: string | null,
  exchangeRate?: string | null,
  tenantId: string,
};

export type ModelCurrencyConditionInput = {
  name?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  isDefaultCurrency?: ModelBooleanInput | null,
  code?: ModelStringInput | null,
  country?: ModelStringInput | null,
  exchangeRate?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelCurrencyConditionInput | null > | null,
  or?: Array< ModelCurrencyConditionInput | null > | null,
  not?: ModelCurrencyConditionInput | null,
};

export type UpdateCurrencyInput = {
  id: string,
  name?: string | null,
  symbol?: string | null,
  isDefaultCurrency?: boolean | null,
  code?: string | null,
  country?: string | null,
  exchangeRate?: string | null,
  tenantId?: string | null,
};

export type DeleteCurrencyInput = {
  id: string,
};

export type CreateTenantInput = {
  id?: string | null,
  name: string,
  subscribedServices?: string | null,
  domain?: string | null,
  organizationId?: string | null,
  tenantCustomizationId?: string | null,
  sidebarLinkId?: string | null,
  usedSpaceByDoc?: string | null,
  totalSpaceForDoc?: string | null,
};

export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  subscribedServices?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  tenantCustomizationId?: ModelIDInput | null,
  sidebarLinkId?: ModelIDInput | null,
  usedSpaceByDoc?: ModelStringInput | null,
  totalSpaceForDoc?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  subscribedServices?: string | null,
  domain?: string | null,
  organizationId?: string | null,
  tenantCustomizationId?: string | null,
  sidebarLinkId?: string | null,
  usedSpaceByDoc?: string | null,
  totalSpaceForDoc?: string | null,
};

export type DeleteTenantInput = {
  id: string,
};

export type AssignTicketInput = {
  ticketIds: Array< string | null >,
  usersToAssignTicket: Array< UserToAssignTicketType >,
  groupsToAssignTicket: Array< GroupToAssignTicketType >,
};

export type UserToAssignTicketType = {
  userId: string,
  status: TaskUserGroupStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type GroupToAssignTicketType = {
  groupId: string,
  status: TaskUserGroupStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type AssignTicketResult = {
  __typename: "AssignTicketResult",
  data?: AssignTicketResultData | null,
  error?: string | null,
};

export type AssignTicketResultData = {
  __typename: "AssignTicketResultData",
  statusCode?: number | null,
  message?: string | null,
};

export type CreateTicketRuleInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  status: string,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  tenantId: string,
};

export type ModelTicketRuleConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelTicketRuleConditionInput | null > | null,
  or?: Array< ModelTicketRuleConditionInput | null > | null,
  not?: ModelTicketRuleConditionInput | null,
};

export type UpdateTicketRuleInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  status?: string | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  tenantId?: string | null,
};

export type DeleteTicketRuleInput = {
  id: string,
};

export type CreateTicketAssignmentRuleInput = {
  ticketAssignmentRuleId: string,
  ticketAssignmentRuleSFAccounts: Array< TicketAssignmentRuleSFAccountsType >,
  ticketAssignmentRuleSFCountries: Array< TicketAssignmentRuleSFCountriesType >,
  ticketAssignmentRuleUsers: Array< TicketAssignmentRuleUsersType >,
  ticketAssignmentRuleGroups: Array< TicketAssignmentRuleGroupsType >,
};

export type TicketAssignmentRuleSFAccountsType = {
  sfAccountId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type TicketAssignmentRuleSFCountriesType = {
  sfCountryId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type TicketAssignmentRuleUsersType = {
  userId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type TicketAssignmentRuleGroupsType = {
  groupId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type CreateTicketAssignmentRuleResult = {
  __typename: "CreateTicketAssignmentRuleResult",
  data?: CreateTicketAssignmentRuleResultData | null,
  error?: string | null,
};

export type CreateTicketAssignmentRuleResultData = {
  __typename: "CreateTicketAssignmentRuleResultData",
  statusCode?: number | null,
  message?: string | null,
};

export type AssignUsersToGroupInput = {
  groupId: string,
  userGroupIdsToRemove?: Array< string | null > | null,
  userIdsToAddToGroup?: Array< string | null > | null,
};

export type AssignUsersToGroupResult = {
  __typename: "AssignUsersToGroupResult",
  data?: AssignUsersToGroupResultData | null,
  error?: string | null,
};

export type AssignUsersToGroupResultData = {
  __typename: "AssignUsersToGroupResultData",
  statusCode?: number | null,
  message?: string | null,
};

export type AssignGroupsToUserInput = {
  userId: string,
  userGroupIdsToRemove?: Array< string | null > | null,
  groupIdsToAddToUser?: Array< string | null > | null,
};

export type AssignGroupsToUserResult = {
  __typename: "AssignGroupsToUserResult",
  data?: AssignGroupsToUserResultData | null,
  error?: string | null,
};

export type AssignGroupsToUserResultData = {
  __typename: "AssignGroupsToUserResultData",
  statusCode?: number | null,
  message?: string | null,
};

export type ApproveDocumentsInput = {
  taskId: string,
};

export type ApproveDocumentsResult = {
  __typename: "ApproveDocumentsResult",
  data?: ApproveDocumentsResultData | null,
  error?: string | null,
};

export type ApproveDocumentsResultData = {
  __typename: "ApproveDocumentsResultData",
  statusCode?: number | null,
  message?: string | null,
};

export type DiscardDocumentsInput = {
  document: DiscardDocument,
  type: DiscardDocumentType,
};

export type DiscardDocument = {
  id: string,
  version: string,
  tenantId: string,
};

export enum DiscardDocumentType {
  ROOTCHAPTER = "ROOTCHAPTER",
  SUBCHAPTER = "SUBCHAPTER",
  DOC = "DOC",
  DOCITEM = "DOCITEM",
}


export type DiscardDocumentsResult = {
  __typename: "DiscardDocumentsResult",
  data?: DiscardDocumentsResultData | null,
  error?: string | null,
};

export type DiscardDocumentsResultData = {
  __typename: "DiscardDocumentsResultData",
  statusCode?: number | null,
  message?: string | null,
};

export type CompanyVerificationInput = {
  companyEmail: string,
  companyId: string,
};

export type CompanyVerificationResult = {
  __typename: "CompanyVerificationResult",
  statusCode?: number | null,
  message?: string | null,
};

export type ImportDocumentInput = {
  rootChapter: ImportRootChapterInput,
  metaData: ImportMetaDataInput,
  chapters: Array< ImportChapterInput >,
  docs: Array< ImportDocInput >,
  docItems: Array< ImportDocItemInput >,
};

export type ImportRootChapterInput = {
  id: string,
  version?: string | null,
  name?: string | null,
  status?: StatusType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  description?: string | null,
  parentChapterId?: string | null,
  parentProductDocId?: string | null,
  rootChapterId?: string | null,
  file?: S3ObjectInput | null,
  tenantId?: string | null,
  pdfFile?: S3ObjectInput | null,
};

export enum StatusType {
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}


export type ImportMetaDataInput = {
  importedByUserId?: string | null,
  parentProductDocId?: string | null,
};

export type ImportChapterInput = {
  id: string,
  version?: string | null,
  name?: string | null,
  status?: StatusType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  description?: string | null,
  parentChapterId?: string | null,
  parentProductDocId?: string | null,
  rootChapterId?: string | null,
  file?: S3ObjectInput | null,
  tenantId?: string | null,
  orderFloat?: string | null,
  pdfFile?: S3ObjectInput | null,
  shouldBeDeleted?: boolean | null,
};

export type ImportDocInput = {
  id: string,
  version?: string | null,
  title?: string | null,
  status?: StatusType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  description?: string | null,
  chapterId?: string | null,
  rootChapterId?: string | null,
  orderFloat?: string | null,
  tenantId?: string | null,
  referenceDocId?: string | null,
  pdfFile?: S3ObjectInput | null,
  shouldBeDeleted?: boolean | null,
};

export type ImportDocItemInput = {
  id: string,
  version?: string | null,
  title?: string | null,
  status?: StatusType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
  description?: string | null,
  itemType?: DocItemType | null,
  orderFloat?: string | null,
  tenantId?: string | null,
  docId?: string | null,
  rootChapterId?: string | null,
  file?: S3ObjectInput | null,
  shouldBeDeleted?: boolean | null,
};

export type ImportRootChapterFromExportedFileResult = {
  __typename: "ImportRootChapterFromExportedFileResult",
  data?: ImportRootChapterFromExportedFileData | null,
  error?: string | null,
};

export type ImportRootChapterFromExportedFileData = {
  __typename: "ImportRootChapterFromExportedFileData",
  statusCode?: number | null,
  message?: string | null,
};

export type SendMailNotificationInput = {
  templateName: string,
  toAddresses: string,
  data: string,
};

export type SendMailNotificationResult = {
  __typename: "SendMailNotificationResult",
  data?: SendMailNotificationResultdata | null,
  error?: string | null,
};

export type SendMailNotificationResultdata = {
  __typename: "SendMailNotificationResultdata",
  statusCode?: number | null,
  message?: string | null,
};

export type ModelSFAccountFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  accountName?: ModelStringInput | null,
  countryId?: ModelIDInput | null,
  parentAccountId?: ModelIDInput | null,
  emailDomain?: ModelStringInput | null,
  industry?: ModelStringInput | null,
  organizationId?: ModelStringInput | null,
  billingAddress?: ModelStringInput | null,
  shippingAddress?: ModelStringInput | null,
  copyBillingAddressToShippingAddress?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  website?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdDate?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  updatedDate?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFAccountFilterInput | null > | null,
  or?: Array< ModelSFAccountFilterInput | null > | null,
  not?: ModelSFAccountFilterInput | null,
};

export type ModelSFContractFilterInput = {
  id?: ModelIDInput | null,
  contractNumber?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  contractTerm?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ownerExpirationNotice?: ModelStringInput | null,
  totalContractValue?: ModelStringInput | null,
  contractType?: ModelStringInput | null,
  poNumber?: ModelStringInput | null,
  poDate?: ModelStringInput | null,
  referenceNumber?: ModelStringInput | null,
  partnerAccountId?: ModelIDInput | null,
  distributorAccountId?: ModelIDInput | null,
  paymentTerms?: ModelStringInput | null,
  poIssuedById?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  salesRepresentativeNameId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  customerAddressId?: ModelIDInput | null,
  designationId?: ModelIDInput | null,
  emailId?: ModelIDInput | null,
  mobileId?: ModelIDInput | null,
  year1MaintenanceEndDate?: ModelStringInput | null,
  year1MaintenanceStartDate?: ModelStringInput | null,
  year2MaintenanceEndDate?: ModelStringInput | null,
  year2MaintenanceStartDate?: ModelStringInput | null,
  year3MaintenanceEndDate?: ModelStringInput | null,
  year3MaintenanceStartDate?: ModelStringInput | null,
  value?: ModelStringInput | null,
  billingAddress?: ModelStringInput | null,
  description?: ModelStringInput | null,
  specialTerms?: ModelStringInput | null,
  signedById?: ModelIDInput | null,
  companySignedId?: ModelIDInput | null,
  activatedById?: ModelIDInput | null,
  activatedDate?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  invoiceDate?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFContractFilterInput | null > | null,
  or?: Array< ModelSFContractFilterInput | null > | null,
  not?: ModelSFContractFilterInput | null,
};

export type ModelSFContractConnection = {
  __typename: "ModelSFContractConnection",
  items:  Array<SFContract | null >,
  nextToken?: string | null,
};

export type ModelSFContractLineItemFilterInput = {
  id?: ModelIDInput | null,
  contractName?: ModelStringInput | null,
  itemNo?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  quantity?: ModelStringInput | null,
  contractId?: ModelIDInput | null,
  productListPrice?: ModelStringInput | null,
  discount?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  version?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFContractLineItemFilterInput | null > | null,
  or?: Array< ModelSFContractLineItemFilterInput | null > | null,
  not?: ModelSFContractLineItemFilterInput | null,
};

export type ModelSFContractLineItemConnection = {
  __typename: "ModelSFContractLineItemConnection",
  items:  Array<SFContractLineItem | null >,
  nextToken?: string | null,
};

export type ModelSFProductFilterInput = {
  id?: ModelIDInput | null,
  productName?: ModelStringInput | null,
  productVersion?: ModelStringInput | null,
  productMetric?: ModelStringInput | null,
  productDescription?: ModelStringInput | null,
  sfAccountId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  productCode?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFProductFilterInput | null > | null,
  or?: Array< ModelSFProductFilterInput | null > | null,
  not?: ModelSFProductFilterInput | null,
};

export type ModelSFProductSubCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  sfProductId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFProductSubCategoryFilterInput | null > | null,
  or?: Array< ModelSFProductSubCategoryFilterInput | null > | null,
  not?: ModelSFProductSubCategoryFilterInput | null,
};

export type ModelSFProductVersionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  version?: ModelStringInput | null,
  sfProductId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFProductVersionFilterInput | null > | null,
  or?: Array< ModelSFProductVersionFilterInput | null > | null,
  not?: ModelSFProductVersionFilterInput | null,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelActiveStatusTypeInput | null,
  organizationId?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
};

export type ModelTaskFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  requestedByUserId?: ModelIDInput | null,
  reviewedByUserId?: ModelIDInput | null,
  status?: ModelTaskStatusTypeInput | null,
  rootChapterId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  ticketRef?: ModelStringInput | null,
  severity?: ModelStringInput | null,
  severityUpdatedAt?: ModelStringInput | null,
  issueCategory?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  productVersion?: ModelStringInput | null,
  closureReason?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rootChapterVersion?: ModelStringInput | null,
  productDocId?: ModelIDInput | null,
  isRejected?: ModelBooleanInput | null,
  usersToNotify?: ModelStringInput | null,
  linkedTickets?: ModelStringInput | null,
  salesForceTicketId?: ModelStringInput | null,
  hasSensitiveInfo?: ModelBooleanInput | null,
  requestForInformation?: ModelStringInput | null,
  isSupportEngineerIdentityHidden?: ModelBooleanInput | null,
  tenantId?: ModelIDInput | null,
  issueType?: ModelStringInput | null,
  and?: Array< ModelTaskFilterInput | null > | null,
  or?: Array< ModelTaskFilterInput | null > | null,
  not?: ModelTaskFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  userType?: ModelUserTypeInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  email?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  salesForceId?: ModelStringInput | null,
  role?: ModelRoleTypeInput | null,
  secondaryEmail?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelActiveStatusTypeInput | null,
  isMigrated?: ModelBooleanInput | null,
  isTenantAdmin?: ModelBooleanInput | null,
  ticketsToReviewOrder?: ModelStringInput | null,
  supportPortalDashBoardChartsOrder?: ModelIntInput | null,
  tenantId?: ModelIDInput | null,
  preferredCurrency?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelApplicationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  defaultRoles?: ModelStringInput | null,
  and?: Array< ModelApplicationFilterInput | null > | null,
  or?: Array< ModelApplicationFilterInput | null > | null,
  not?: ModelApplicationFilterInput | null,
};

export type ModelProductDocFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelStringInput | null,
  status?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  applicationId?: ModelIDInput | null,
  orderFloat?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelProductDocFilterInput | null > | null,
  or?: Array< ModelProductDocFilterInput | null > | null,
  not?: ModelProductDocFilterInput | null,
};

export type ModelApplicationConnection = {
  __typename: "ModelApplicationConnection",
  items:  Array<Application | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelChapterFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelStringInput | null,
  status?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  isPdfBeingGenerated?: ModelBooleanInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rootChapterId?: ModelIDInput | null,
  parentProductDocId?: ModelIDInput | null,
  parentChapterId?: ModelIDInput | null,
  shouldBeDeleted?: ModelBooleanInput | null,
  currentTaskId?: ModelIDInput | null,
  isRejected?: ModelBooleanInput | null,
  orderFloat?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  isArchivedByOrphanRemovalScript?: ModelBooleanInput | null,
  archivedByOrphanRemovalScript?: ModelStringInput | null,
  archivedByIrrelevantContentRemovalScript?: ModelStringInput | null,
  and?: Array< ModelChapterFilterInput | null > | null,
  or?: Array< ModelChapterFilterInput | null > | null,
  not?: ModelChapterFilterInput | null,
};

export type ModelDocFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelStringInput | null,
  status?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  isPdfBeingGenerated?: ModelBooleanInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rootChapterId?: ModelIDInput | null,
  chapterId?: ModelIDInput | null,
  shouldBeDeleted?: ModelBooleanInput | null,
  orderFloat?: ModelStringInput | null,
  referenceDocId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  isArchivedByOrphanRemovalScript?: ModelBooleanInput | null,
  archivedByOrphanRemovalScript?: ModelStringInput | null,
  archivedByIrrelevantContentRemovalScript?: ModelStringInput | null,
  and?: Array< ModelDocFilterInput | null > | null,
  or?: Array< ModelDocFilterInput | null > | null,
  not?: ModelDocFilterInput | null,
};

export type ModelDocItemFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelStringInput | null,
  status?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  itemType?: ModelDocItemTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rootChapterId?: ModelIDInput | null,
  docId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  shouldBeDeleted?: ModelBooleanInput | null,
  orderFloat?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  isArchivedByOrphanRemovalScript?: ModelBooleanInput | null,
  archivedByOrphanRemovalScript?: ModelStringInput | null,
  archivedByIrrelevantContentRemovalScript?: ModelStringInput | null,
  and?: Array< ModelDocItemFilterInput | null > | null,
  or?: Array< ModelDocItemFilterInput | null > | null,
  not?: ModelDocItemFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchableTaskFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  requestedByUserId?: SearchableIDFilterInput | null,
  reviewedByUserId?: SearchableIDFilterInput | null,
  status?: SearchableStringFilterInput | null,
  rootChapterId?: SearchableIDFilterInput | null,
  organizationId?: SearchableIDFilterInput | null,
  ticketRef?: SearchableStringFilterInput | null,
  severity?: SearchableStringFilterInput | null,
  severityUpdatedAt?: SearchableStringFilterInput | null,
  issueCategory?: SearchableStringFilterInput | null,
  issueType?: SearchableStringFilterInput | null,
  subCategory?: SearchableStringFilterInput | null,
  productVersion?: SearchableStringFilterInput | null,
  closureReason?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  rootChapterVersion?: SearchableStringFilterInput | null,
  productDocId?: SearchableIDFilterInput | null,
  isRejected?: SearchableBooleanFilterInput | null,
  usersToNotify?: SearchableStringFilterInput | null,
  linkedTickets?: SearchableStringFilterInput | null,
  salesForceTicketId?: SearchableStringFilterInput | null,
  hasSensitiveInfo?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableTaskFilterInput | null > | null,
  or?: Array< SearchableTaskFilterInput | null > | null,
  not?: SearchableTaskFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableTaskSortInput = {
  field?: SearchableTaskSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTaskSortableFields {
  id = "id",
  name = "name",
  description = "description",
  requestedByUserId = "requestedByUserId",
  reviewedByUserId = "reviewedByUserId",
  status = "status",
  rootChapterId = "rootChapterId",
  organizationId = "organizationId",
  ticketRef = "ticketRef",
  severity = "severity",
  severityUpdatedAt = "severityUpdatedAt",
  issueCategory = "issueCategory",
  subCategory = "subCategory",
  productVersion = "productVersion",
  closureReason = "closureReason",
  type = "type",
  rootChapterVersion = "rootChapterVersion",
  productDocId = "productDocId",
  isRejected = "isRejected",
  usersToNotify = "usersToNotify",
  linkedTickets = "linkedTickets",
  salesForceTicketId = "salesForceTicketId",
  hasSensitiveInfo = "hasSensitiveInfo",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableTaskConnection = {
  __typename: "SearchableTaskConnection",
  items:  Array<Task | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type SearchableLeadFilterInput = {
  id?: SearchableIDFilterInput | null,
  leadReferenceNumber?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  phoneNumber?: SearchableStringFilterInput | null,
  role?: SearchableStringFilterInput | null,
  jobTitle?: SearchableStringFilterInput | null,
  companyName?: SearchableStringFilterInput | null,
  companyAddress?: SearchableStringFilterInput | null,
  industry?: SearchableStringFilterInput | null,
  companyUrl?: SearchableStringFilterInput | null,
  leadName?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  timeline?: SearchableStringFilterInput | null,
  estimatedBudget?: SearchableStringFilterInput | null,
  estimatedNumberOfUsers?: SearchableIntFilterInput | null,
  solution?: SearchableStringFilterInput | null,
  incumbentProduct?: SearchableStringFilterInput | null,
  competitors?: SearchableStringFilterInput | null,
  submissionDate?: SearchableStringFilterInput | null,
  remarks?: SearchableStringFilterInput | null,
  createdByUserId?: SearchableIDFilterInput | null,
  lastModifiedByUserId?: SearchableIDFilterInput | null,
  qualifiedByUserId?: SearchableIDFilterInput | null,
  disqualifiedByUserId?: SearchableIDFilterInput | null,
  approvedByUserId?: SearchableIDFilterInput | null,
  rejectedByUserId?: SearchableIDFilterInput | null,
  usersToNotify?: SearchableStringFilterInput | null,
  overallStatus?: SearchableStringFilterInput | null,
  qualifiedStatus?: SearchableStringFilterInput | null,
  approvedStatus?: SearchableStringFilterInput | null,
  qualifiedDate?: SearchableStringFilterInput | null,
  approvedDate?: SearchableStringFilterInput | null,
  qualifiedRemarks?: SearchableStringFilterInput | null,
  approvedRemarks?: SearchableStringFilterInput | null,
  salesForceLeadId?: SearchableStringFilterInput | null,
  and?: Array< SearchableLeadFilterInput | null > | null,
  or?: Array< SearchableLeadFilterInput | null > | null,
  not?: SearchableLeadFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableLeadSortInput = {
  field?: SearchableLeadSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableLeadSortableFields {
  id = "id",
  leadReferenceNumber = "leadReferenceNumber",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  phoneNumber = "phoneNumber",
  role = "role",
  jobTitle = "jobTitle",
  companyName = "companyName",
  companyAddress = "companyAddress",
  industry = "industry",
  companyUrl = "companyUrl",
  leadName = "leadName",
  description = "description",
  timeline = "timeline",
  estimatedBudget = "estimatedBudget",
  estimatedNumberOfUsers = "estimatedNumberOfUsers",
  solution = "solution",
  incumbentProduct = "incumbentProduct",
  competitors = "competitors",
  submissionDate = "submissionDate",
  remarks = "remarks",
  createdByUserId = "createdByUserId",
  lastModifiedByUserId = "lastModifiedByUserId",
  qualifiedByUserId = "qualifiedByUserId",
  disqualifiedByUserId = "disqualifiedByUserId",
  approvedByUserId = "approvedByUserId",
  rejectedByUserId = "rejectedByUserId",
  usersToNotify = "usersToNotify",
  overallStatus = "overallStatus",
  qualifiedStatus = "qualifiedStatus",
  approvedStatus = "approvedStatus",
  qualifiedDate = "qualifiedDate",
  approvedDate = "approvedDate",
  qualifiedRemarks = "qualifiedRemarks",
  approvedRemarks = "approvedRemarks",
  salesForceLeadId = "salesForceLeadId",
}


export type SearchableLeadConnection = {
  __typename: "SearchableLeadConnection",
  items:  Array<Lead | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelLeadFilterInput = {
  id?: ModelIDInput | null,
  leadReferenceNumber?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  role?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  companyAddress?: ModelStringInput | null,
  industry?: ModelStringInput | null,
  companyUrl?: ModelStringInput | null,
  leadName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  timeline?: ModelStringInput | null,
  estimatedBudget?: ModelStringInput | null,
  estimatedNumberOfUsers?: ModelIntInput | null,
  solution?: ModelStringInput | null,
  incumbentProduct?: ModelStringInput | null,
  competitors?: ModelStringInput | null,
  submissionDate?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  qualifiedByUserId?: ModelIDInput | null,
  disqualifiedByUserId?: ModelIDInput | null,
  approvedByUserId?: ModelIDInput | null,
  rejectedByUserId?: ModelIDInput | null,
  usersToNotify?: ModelStringInput | null,
  overallStatus?: ModelLeadOverallStatusTypeInput | null,
  qualifiedStatus?: ModelLeadQualifiedStatusTypeInput | null,
  approvedStatus?: ModelLeadApprovedStatusTypeInput | null,
  qualifiedDate?: ModelStringInput | null,
  approvedDate?: ModelStringInput | null,
  qualifiedRemarks?: ModelStringInput | null,
  approvedRemarks?: ModelStringInput | null,
  salesForceLeadId?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelLeadFilterInput | null > | null,
  or?: Array< ModelLeadFilterInput | null > | null,
  not?: ModelLeadFilterInput | null,
};

export type ModelCurrencyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  isDefaultCurrency?: ModelBooleanInput | null,
  code?: ModelStringInput | null,
  country?: ModelStringInput | null,
  exchangeRate?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelCurrencyFilterInput | null > | null,
  or?: Array< ModelCurrencyFilterInput | null > | null,
  not?: ModelCurrencyFilterInput | null,
};

export type SearchableCurrencyFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  symbol?: SearchableStringFilterInput | null,
  isDefaultCurrency?: SearchableBooleanFilterInput | null,
  code?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  exchangeRate?: SearchableStringFilterInput | null,
  tenantId?: SearchableIDFilterInput | null,
  and?: Array< SearchableCurrencyFilterInput | null > | null,
  or?: Array< SearchableCurrencyFilterInput | null > | null,
  not?: SearchableCurrencyFilterInput | null,
};

export type SearchableCurrencySortInput = {
  field?: SearchableCurrencySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCurrencySortableFields {
  id = "id",
  name = "name",
  symbol = "symbol",
  isDefaultCurrency = "isDefaultCurrency",
  code = "code",
  country = "country",
  exchangeRate = "exchangeRate",
  tenantId = "tenantId",
}


export type SearchableCurrencyConnection = {
  __typename: "SearchableCurrencyConnection",
  items:  Array<Currency | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  subscribedServices?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  tenantCustomizationId?: ModelIDInput | null,
  sidebarLinkId?: ModelIDInput | null,
  usedSpaceByDoc?: ModelStringInput | null,
  totalSpaceForDoc?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items:  Array<Tenant | null >,
  nextToken?: string | null,
};

export type ModelSFCaseSeverityFilterInput = {
  id?: ModelIDInput | null,
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFCaseSeverityFilterInput | null > | null,
  or?: Array< ModelSFCaseSeverityFilterInput | null > | null,
  not?: ModelSFCaseSeverityFilterInput | null,
};

export type ModelSFCaseSeverityConnection = {
  __typename: "ModelSFCaseSeverityConnection",
  items:  Array<SFCaseSeverity | null >,
  nextToken?: string | null,
};

export type ModelSFClosureReasonFilterInput = {
  id?: ModelIDInput | null,
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFClosureReasonFilterInput | null > | null,
  or?: Array< ModelSFClosureReasonFilterInput | null > | null,
  not?: ModelSFClosureReasonFilterInput | null,
};

export type ModelSFClosureReasonConnection = {
  __typename: "ModelSFClosureReasonConnection",
  items:  Array<SFClosureReason | null >,
  nextToken?: string | null,
};

export type ModelSFContactFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  title?: ModelStringInput | null,
  role?: ModelStringInput | null,
  countryId?: ModelIDInput | null,
  superVisorId?: ModelIDInput | null,
  region?: ModelStringInput | null,
  mailingAddress?: ModelStringInput | null,
  otherAddress?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFContactFilterInput | null > | null,
  or?: Array< ModelSFContactFilterInput | null > | null,
  not?: ModelSFContactFilterInput | null,
};

export type ModelSFCountryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  countryId?: ModelStringInput | null,
  region?: ModelStringInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFCountryFilterInput | null > | null,
  or?: Array< ModelSFCountryFilterInput | null > | null,
  not?: ModelSFCountryFilterInput | null,
};

export type ModelSFCountryConnection = {
  __typename: "ModelSFCountryConnection",
  items:  Array<SFCountry | null >,
  nextToken?: string | null,
};

export type ModelSFIndustryFilterInput = {
  id?: ModelIDInput | null,
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFIndustryFilterInput | null > | null,
  or?: Array< ModelSFIndustryFilterInput | null > | null,
  not?: ModelSFIndustryFilterInput | null,
};

export type ModelSFIndustryConnection = {
  __typename: "ModelSFIndustryConnection",
  items:  Array<SFIndustry | null >,
  nextToken?: string | null,
};

export type ModelSFIssueCategoryFilterInput = {
  id?: ModelIDInput | null,
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFIssueCategoryFilterInput | null > | null,
  or?: Array< ModelSFIssueCategoryFilterInput | null > | null,
  not?: ModelSFIssueCategoryFilterInput | null,
};

export type ModelSFIssueCategoryConnection = {
  __typename: "ModelSFIssueCategoryConnection",
  items:  Array<SFIssueCategory | null >,
  nextToken?: string | null,
};

export type ModelSFRoleFilterInput = {
  id?: ModelIDInput | null,
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFRoleFilterInput | null > | null,
  or?: Array< ModelSFRoleFilterInput | null > | null,
  not?: ModelSFRoleFilterInput | null,
};

export type ModelSFRoleConnection = {
  __typename: "ModelSFRoleConnection",
  items:  Array<SFRole | null >,
  nextToken?: string | null,
};

export type ModelSFSolutionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  leadId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFSolutionFilterInput | null > | null,
  or?: Array< ModelSFSolutionFilterInput | null > | null,
  not?: ModelSFSolutionFilterInput | null,
};

export type ModelSFSolutionConnection = {
  __typename: "ModelSFSolutionConnection",
  items:  Array<SFSolution | null >,
  nextToken?: string | null,
};

export type ModelSFCompetitorFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  competitors?: ModelStringInput | null,
  solutionId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFCompetitorFilterInput | null > | null,
  or?: Array< ModelSFCompetitorFilterInput | null > | null,
  not?: ModelSFCompetitorFilterInput | null,
};

export type ModelApplicationRoleFilterInput = {
  id?: ModelIDInput | null,
  roleId?: ModelStringInput | null,
  applicationId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  productDocs?: ModelIDInput | null,
  locations?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelApplicationRoleFilterInput | null > | null,
  or?: Array< ModelApplicationRoleFilterInput | null > | null,
  not?: ModelApplicationRoleFilterInput | null,
};

export type ModelTicketRuleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelTicketRuleFilterInput | null > | null,
  or?: Array< ModelTicketRuleFilterInput | null > | null,
  not?: ModelTicketRuleFilterInput | null,
};

export type ModelTicketRuleConnection = {
  __typename: "ModelTicketRuleConnection",
  items:  Array<TicketRule | null >,
  nextToken?: string | null,
};

export type CreateSidebarLinkInput = {
  id?: string | null,
  name?: string | null,
  url?: string | null,
  parentLinkId?: string | null,
  tenantId: string,
};

export type ModelSidebarLinkConditionInput = {
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  parentLinkId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSidebarLinkConditionInput | null > | null,
  or?: Array< ModelSidebarLinkConditionInput | null > | null,
  not?: ModelSidebarLinkConditionInput | null,
};

export type UpdateSidebarLinkInput = {
  id: string,
  name?: string | null,
  url?: string | null,
  parentLinkId?: string | null,
  tenantId?: string | null,
};

export type DeleteSidebarLinkInput = {
  id: string,
};

export type CreateTenantCustomizationInput = {
  id?: string | null,
  color?: string | null,
  tenantId: string,
};

export type ModelTenantCustomizationConditionInput = {
  color?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelTenantCustomizationConditionInput | null > | null,
  or?: Array< ModelTenantCustomizationConditionInput | null > | null,
  not?: ModelTenantCustomizationConditionInput | null,
};

export type UpdateTenantCustomizationInput = {
  id: string,
  color?: string | null,
  tenantId?: string | null,
};

export type DeleteTenantCustomizationInput = {
  id: string,
};

export type CreateTenantApplicationInput = {
  id?: string | null,
  tenantId: string,
  applicationId: string,
};

export type ModelTenantApplicationConditionInput = {
  tenantId?: ModelIDInput | null,
  applicationId?: ModelIDInput | null,
  and?: Array< ModelTenantApplicationConditionInput | null > | null,
  or?: Array< ModelTenantApplicationConditionInput | null > | null,
  not?: ModelTenantApplicationConditionInput | null,
};

export type UpdateTenantApplicationInput = {
  id: string,
  tenantId?: string | null,
  applicationId?: string | null,
};

export type DeleteTenantApplicationInput = {
  id: string,
};

export type CreateTicketRuleSFCountryInput = {
  id?: string | null,
  ticketRuleId: string,
  sfCountryId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type ModelTicketRuleSFCountryConditionInput = {
  ticketRuleId?: ModelIDInput | null,
  sfCountryId?: ModelIDInput | null,
  status?: ModelTicketRuleJoinTableStatusTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  and?: Array< ModelTicketRuleSFCountryConditionInput | null > | null,
  or?: Array< ModelTicketRuleSFCountryConditionInput | null > | null,
  not?: ModelTicketRuleSFCountryConditionInput | null,
};

export type ModelTicketRuleJoinTableStatusTypeInput = {
  eq?: TicketRuleJoinTableStatusType | null,
  ne?: TicketRuleJoinTableStatusType | null,
};

export type UpdateTicketRuleSFCountryInput = {
  id: string,
  ticketRuleId?: string | null,
  sfCountryId?: string | null,
  status?: TicketRuleJoinTableStatusType | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type DeleteTicketRuleSFCountryInput = {
  id: string,
};

export type CreateTicketRuleSFAccountInput = {
  id?: string | null,
  ticketRuleId: string,
  sfAccountId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type ModelTicketRuleSFAccountConditionInput = {
  ticketRuleId?: ModelIDInput | null,
  sfAccountId?: ModelIDInput | null,
  status?: ModelTicketRuleJoinTableStatusTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  and?: Array< ModelTicketRuleSFAccountConditionInput | null > | null,
  or?: Array< ModelTicketRuleSFAccountConditionInput | null > | null,
  not?: ModelTicketRuleSFAccountConditionInput | null,
};

export type UpdateTicketRuleSFAccountInput = {
  id: string,
  ticketRuleId?: string | null,
  sfAccountId?: string | null,
  status?: TicketRuleJoinTableStatusType | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type DeleteTicketRuleSFAccountInput = {
  id: string,
};

export type CreateTicketRuleUserInput = {
  id?: string | null,
  ticketRuleId: string,
  userId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type ModelTicketRuleUserConditionInput = {
  ticketRuleId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  status?: ModelTicketRuleJoinTableStatusTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  and?: Array< ModelTicketRuleUserConditionInput | null > | null,
  or?: Array< ModelTicketRuleUserConditionInput | null > | null,
  not?: ModelTicketRuleUserConditionInput | null,
};

export type UpdateTicketRuleUserInput = {
  id: string,
  ticketRuleId?: string | null,
  userId?: string | null,
  status?: TicketRuleJoinTableStatusType | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type DeleteTicketRuleUserInput = {
  id: string,
};

export type CreateTicketRuleGroupInput = {
  id?: string | null,
  ticketRuleId: string,
  groupId: string,
  status: TicketRuleJoinTableStatusType,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type ModelTicketRuleGroupConditionInput = {
  ticketRuleId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  status?: ModelTicketRuleJoinTableStatusTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  lastModifiedByUserId?: ModelIDInput | null,
  and?: Array< ModelTicketRuleGroupConditionInput | null > | null,
  or?: Array< ModelTicketRuleGroupConditionInput | null > | null,
  not?: ModelTicketRuleGroupConditionInput | null,
};

export type UpdateTicketRuleGroupInput = {
  id: string,
  ticketRuleId?: string | null,
  groupId?: string | null,
  status?: TicketRuleJoinTableStatusType | null,
  createdByUserId?: string | null,
  lastModifiedByUserId?: string | null,
};

export type DeleteTicketRuleGroupInput = {
  id: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name?: string | null,
  domain?: string | null,
  accountId?: string | null,
  recordId?: string | null,
  type?: string | null,
  tenantId: string,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  accountId?: ModelStringInput | null,
  recordId?: ModelStringInput | null,
  type?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  domain?: string | null,
  accountId?: string | null,
  recordId?: string | null,
  type?: string | null,
  tenantId?: string | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateTaskFeedInput = {
  id?: string | null,
  tenantId: string,
  userId: string,
  taskId: string,
};

export type ModelTaskFeedConditionInput = {
  userId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  and?: Array< ModelTaskFeedConditionInput | null > | null,
  or?: Array< ModelTaskFeedConditionInput | null > | null,
  not?: ModelTaskFeedConditionInput | null,
};

export type TaskFeed = {
  __typename: "TaskFeed",
  id: string,
  tenantId: string,
  userId: string,
  taskId: string,
  createdAt: string,
  updatedAt: string,
  tenant: Tenant,
  user: User,
  task: Task,
};

export type UpdateTaskFeedInput = {
  id: string,
  tenantId: string,
  userId?: string | null,
  taskId?: string | null,
};

export type DeleteTaskFeedInput = {
  id: string,
  tenantId: string,
};

export type CreateUserTaskInput = {
  id?: string | null,
  userId: string,
  taskId: string,
  status: TaskUserGroupStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
};

export type ModelUserTaskConditionInput = {
  userId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  status?: ModelTaskUserGroupStatusTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  updatedByUserId?: ModelIDInput | null,
  and?: Array< ModelUserTaskConditionInput | null > | null,
  or?: Array< ModelUserTaskConditionInput | null > | null,
  not?: ModelUserTaskConditionInput | null,
};

export type ModelTaskUserGroupStatusTypeInput = {
  eq?: TaskUserGroupStatusType | null,
  ne?: TaskUserGroupStatusType | null,
};

export type UpdateUserTaskInput = {
  id: string,
  userId?: string | null,
  taskId?: string | null,
  status?: TaskUserGroupStatusType | null,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
};

export type DeleteUserTaskInput = {
  id: string,
};

export type CreateGroupTaskInput = {
  id?: string | null,
  groupId: string,
  taskId: string,
  status: TaskUserGroupStatusType,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
};

export type ModelGroupTaskConditionInput = {
  groupId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  status?: ModelTaskUserGroupStatusTypeInput | null,
  createdByUserId?: ModelIDInput | null,
  updatedByUserId?: ModelIDInput | null,
  and?: Array< ModelGroupTaskConditionInput | null > | null,
  or?: Array< ModelGroupTaskConditionInput | null > | null,
  not?: ModelGroupTaskConditionInput | null,
};

export type UpdateGroupTaskInput = {
  id: string,
  groupId?: string | null,
  taskId?: string | null,
  status?: TaskUserGroupStatusType | null,
  createdByUserId?: string | null,
  updatedByUserId?: string | null,
};

export type DeleteGroupTaskInput = {
  id: string,
};

export type CreateTempTokenInput = {
  id?: string | null,
  value?: string | null,
};

export type ModelTempTokenConditionInput = {
  value?: ModelStringInput | null,
  and?: Array< ModelTempTokenConditionInput | null > | null,
  or?: Array< ModelTempTokenConditionInput | null > | null,
  not?: ModelTempTokenConditionInput | null,
};

export type TempToken = {
  __typename: "TempToken",
  id: string,
  value?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTempTokenInput = {
  id: string,
  value?: string | null,
};

export type DeleteTempTokenInput = {
  id: string,
};

export type CreateSFPriceBookInput = {
  id?: string | null,
  priceBookName?: string | null,
  active?: boolean | null,
  description?: string | null,
  isStandardPriceBook?: boolean | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFPriceBookConditionInput = {
  priceBookName?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  isStandardPriceBook?: ModelBooleanInput | null,
  sfProductId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFPriceBookConditionInput | null > | null,
  or?: Array< ModelSFPriceBookConditionInput | null > | null,
  not?: ModelSFPriceBookConditionInput | null,
};

export type SFPriceBook = {
  __typename: "SFPriceBook",
  id: string,
  priceBookName?: string | null,
  active?: boolean | null,
  description?: string | null,
  isStandardPriceBook?: boolean | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  tenant?: Tenant | null,
  sfProduct?: SFProduct | null,
};

export type UpdateSFPriceBookInput = {
  id: string,
  priceBookName?: string | null,
  active?: boolean | null,
  description?: string | null,
  isStandardPriceBook?: boolean | null,
  sfProductId?: string | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFPriceBookInput = {
  id: string,
};

export type CreateSFCompetitorInput = {
  id?: string | null,
  name?: string | null,
  domain?: string | null,
  competitors?: string | null,
  solutionId?: string | null,
  allSFData?: string | null,
  tenantId: string,
};

export type ModelSFCompetitorConditionInput = {
  name?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  competitors?: ModelStringInput | null,
  solutionId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFCompetitorConditionInput | null > | null,
  or?: Array< ModelSFCompetitorConditionInput | null > | null,
  not?: ModelSFCompetitorConditionInput | null,
};

export type UpdateSFCompetitorInput = {
  id: string,
  name?: string | null,
  domain?: string | null,
  competitors?: string | null,
  solutionId?: string | null,
  allSFData?: string | null,
  tenantId?: string | null,
};

export type DeleteSFCompetitorInput = {
  id: string,
};

export type ModelSidebarLinkFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  parentLinkId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSidebarLinkFilterInput | null > | null,
  or?: Array< ModelSidebarLinkFilterInput | null > | null,
  not?: ModelSidebarLinkFilterInput | null,
};

export type SearchableSidebarLinkFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  url?: SearchableStringFilterInput | null,
  parentLinkId?: SearchableIDFilterInput | null,
  tenantId?: SearchableIDFilterInput | null,
  and?: Array< SearchableSidebarLinkFilterInput | null > | null,
  or?: Array< SearchableSidebarLinkFilterInput | null > | null,
  not?: SearchableSidebarLinkFilterInput | null,
};

export type SearchableSidebarLinkSortInput = {
  field?: SearchableSidebarLinkSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSidebarLinkSortableFields {
  id = "id",
  name = "name",
  url = "url",
  parentLinkId = "parentLinkId",
  tenantId = "tenantId",
}


export type SearchableSidebarLinkConnection = {
  __typename: "SearchableSidebarLinkConnection",
  items:  Array<SidebarLink | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTenantCustomizationFilterInput = {
  id?: ModelIDInput | null,
  color?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelTenantCustomizationFilterInput | null > | null,
  or?: Array< ModelTenantCustomizationFilterInput | null > | null,
  not?: ModelTenantCustomizationFilterInput | null,
};

export type ModelTenantCustomizationConnection = {
  __typename: "ModelTenantCustomizationConnection",
  items:  Array<TenantCustomization | null >,
  nextToken?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  commentedByUserId?: ModelIDInput | null,
  parentCommentId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  leadId?: ModelIDInput | null,
  hasSensitiveInfo?: ModelBooleanInput | null,
  requestForInformation?: ModelBooleanInput | null,
  type?: ModelCommentTypeInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  accountId?: ModelStringInput | null,
  recordId?: ModelStringInput | null,
  type?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelFileListFilterInput = {
  taskId?: ModelIDInput | null,
  leadId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelFileListFilterInput | null > | null,
  or?: Array< ModelFileListFilterInput | null > | null,
  not?: ModelFileListFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTaskFeedFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  and?: Array< ModelTaskFeedFilterInput | null > | null,
  or?: Array< ModelTaskFeedFilterInput | null > | null,
  not?: ModelTaskFeedFilterInput | null,
};

export type ModelTaskFeedConnection = {
  __typename: "ModelTaskFeedConnection",
  items:  Array<TaskFeed | null >,
  nextToken?: string | null,
};

export type ModelTempTokenFilterInput = {
  id?: ModelIDInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelTempTokenFilterInput | null > | null,
  or?: Array< ModelTempTokenFilterInput | null > | null,
  not?: ModelTempTokenFilterInput | null,
};

export type ModelTempTokenConnection = {
  __typename: "ModelTempTokenConnection",
  items:  Array<TempToken | null >,
  nextToken?: string | null,
};

export type ModelSFPriceBookFilterInput = {
  id?: ModelIDInput | null,
  priceBookName?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  isStandardPriceBook?: ModelBooleanInput | null,
  sfProductId?: ModelIDInput | null,
  allSFData?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelSFPriceBookFilterInput | null > | null,
  or?: Array< ModelSFPriceBookFilterInput | null > | null,
  not?: ModelSFPriceBookFilterInput | null,
};

export type ModelSFPriceBookConnection = {
  __typename: "ModelSFPriceBookConnection",
  items:  Array<SFPriceBook | null >,
  nextToken?: string | null,
};

export type CustomCreateSFAccountMutationVariables = {
  input: CreateSFAccountInput,
  condition?: ModelSFAccountConditionInput | null,
};

export type CustomCreateSFAccountMutation = {
  createSFAccount?:  {
    __typename: "SFAccount",
    id: string,
  } | null,
};

export type CustomUpdateSFAccountMutationVariables = {
  input: UpdateSFAccountInput,
  condition?: ModelSFAccountConditionInput | null,
};

export type CustomUpdateSFAccountMutation = {
  updateSFAccount?:  {
    __typename: "SFAccount",
    id: string,
  } | null,
};

export type CustomDeleteSFAccountMutationVariables = {
  input: DeleteSFAccountInput,
  condition?: ModelSFAccountConditionInput | null,
};

export type CustomDeleteSFAccountMutation = {
  deleteSFAccount?:  {
    __typename: "SFAccount",
    id: string,
  } | null,
};

export type CustomCreateSFContractMutationVariables = {
  input: CreateSFContractInput,
  condition?: ModelSFContractConditionInput | null,
};

export type CustomCreateSFContractMutation = {
  createSFContract?:  {
    __typename: "SFContract",
    id: string,
  } | null,
};

export type CustomUpdateSFContractMutationVariables = {
  input: UpdateSFContractInput,
  condition?: ModelSFContractConditionInput | null,
};

export type CustomUpdateSFContractMutation = {
  updateSFContract?:  {
    __typename: "SFContract",
    id: string,
  } | null,
};

export type CustomDeleteSFContractMutationVariables = {
  input: DeleteSFContractInput,
  condition?: ModelSFContractConditionInput | null,
};

export type CustomDeleteSFContractMutation = {
  deleteSFContract?:  {
    __typename: "SFContract",
    id: string,
  } | null,
};

export type CustomCreateSFContractLineItemMutationVariables = {
  input: CreateSFContractLineItemInput,
  condition?: ModelSFContractLineItemConditionInput | null,
};

export type CustomCreateSFContractLineItemMutation = {
  createSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
  } | null,
};

export type CustomUpdateSFContractLineItemMutationVariables = {
  input: UpdateSFContractLineItemInput,
  condition?: ModelSFContractLineItemConditionInput | null,
};

export type CustomUpdateSFContractLineItemMutation = {
  updateSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
  } | null,
};

export type CustomDeleteSFContractLineItemMutationVariables = {
  input: DeleteSFContractLineItemInput,
  condition?: ModelSFContractLineItemConditionInput | null,
};

export type CustomDeleteSFContractLineItemMutation = {
  deleteSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
  } | null,
};

export type CustomCreateSFContactMutationVariables = {
  input: CreateSFContactInput,
  condition?: ModelSFContactConditionInput | null,
};

export type CustomCreateSFContactMutation = {
  createSFContact?:  {
    __typename: "SFContact",
    id: string,
  } | null,
};

export type CustomUpdateSFContactMutationVariables = {
  input: UpdateSFContactInput,
  condition?: ModelSFContactConditionInput | null,
};

export type CustomUpdateSFContactMutation = {
  updateSFContact?:  {
    __typename: "SFContact",
    id: string,
  } | null,
};

export type CustomDeleteSFContactMutationVariables = {
  input: DeleteSFContactInput,
  condition?: ModelSFContactConditionInput | null,
};

export type CustomDeleteSFContactMutation = {
  deleteSFContact?:  {
    __typename: "SFContact",
    id: string,
  } | null,
};

export type CustomCreateSFProductMutationVariables = {
  input: CreateSFProductInput,
  condition?: ModelSFProductConditionInput | null,
};

export type CustomCreateSFProductMutation = {
  createSFProduct?:  {
    __typename: "SFProduct",
    id: string,
  } | null,
};

export type CustomUpdateSFProductMutationVariables = {
  input: UpdateSFProductInput,
  condition?: ModelSFProductConditionInput | null,
};

export type CustomUpdateSFProductMutation = {
  updateSFProduct?:  {
    __typename: "SFProduct",
    id: string,
  } | null,
};

export type CustomDeleteSFProductMutationVariables = {
  input: DeleteSFProductInput,
  condition?: ModelSFProductConditionInput | null,
};

export type CustomDeleteSFProductMutation = {
  deleteSFProduct?:  {
    __typename: "SFProduct",
    id: string,
  } | null,
};

export type CustomCreateSFProductSubCategoryMutationVariables = {
  input: CreateSFProductSubCategoryInput,
  condition?: ModelSFProductSubCategoryConditionInput | null,
};

export type CustomCreateSFProductSubCategoryMutation = {
  createSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
  } | null,
};

export type CustomUpdateSFProductSubCategoryMutationVariables = {
  input: UpdateSFProductSubCategoryInput,
  condition?: ModelSFProductSubCategoryConditionInput | null,
};

export type CustomUpdateSFProductSubCategoryMutation = {
  updateSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
  } | null,
};

export type CustomDeleteSFProductSubCategoryMutationVariables = {
  input: DeleteSFProductSubCategoryInput,
  condition?: ModelSFProductSubCategoryConditionInput | null,
};

export type CustomDeleteSFProductSubCategoryMutation = {
  deleteSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
  } | null,
};

export type CustomCreateSFProductVersionMutationVariables = {
  input: CreateSFProductVersionInput,
  condition?: ModelSFProductVersionConditionInput | null,
};

export type CustomCreateSFProductVersionMutation = {
  createSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
  } | null,
};

export type CustomUpdateSFProductVersionMutationVariables = {
  input: UpdateSFProductVersionInput,
  condition?: ModelSFProductVersionConditionInput | null,
};

export type CustomUpdateSFProductVersionMutation = {
  updateSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
  } | null,
};

export type CustomDeleteSFProductVersionMutationVariables = {
  input: DeleteSFProductVersionInput,
  condition?: ModelSFProductVersionConditionInput | null,
};

export type CustomDeleteSFProductVersionMutation = {
  deleteSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
  } | null,
};

export type CustomCreateSFIndustryMutationVariables = {
  input: CreateSFIndustryInput,
  condition?: ModelSFIndustryConditionInput | null,
};

export type CustomCreateSFIndustryMutation = {
  createSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
  } | null,
};

export type CustomUpdateSFIndustryMutationVariables = {
  input: UpdateSFIndustryInput,
  condition?: ModelSFIndustryConditionInput | null,
};

export type CustomUpdateSFIndustryMutation = {
  updateSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
  } | null,
};

export type CustomDeleteSFIndustryMutationVariables = {
  input: DeleteSFIndustryInput,
  condition?: ModelSFIndustryConditionInput | null,
};

export type CustomDeleteSFIndustryMutation = {
  deleteSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
  } | null,
};

export type CustomCreateSFIssueCategoryMutationVariables = {
  input: CreateSFIssueCategoryInput,
  condition?: ModelSFIssueCategoryConditionInput | null,
};

export type CustomCreateSFIssueCategoryMutation = {
  createSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
  } | null,
};

export type CustomUpdateSFIssueCategoryMutationVariables = {
  input: UpdateSFIssueCategoryInput,
  condition?: ModelSFIssueCategoryConditionInput | null,
};

export type CustomUpdateSFIssueCategoryMutation = {
  updateSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
  } | null,
};

export type CustomDeleteSFIssueCategoryMutationVariables = {
  input: DeleteSFIssueCategoryInput,
  condition?: ModelSFIssueCategoryConditionInput | null,
};

export type CustomDeleteSFIssueCategoryMutation = {
  deleteSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
  } | null,
};

export type CustomCreateSFCaseSeverityMutationVariables = {
  input: CreateSFCaseSeverityInput,
  condition?: ModelSFCaseSeverityConditionInput | null,
};

export type CustomCreateSFCaseSeverityMutation = {
  createSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
  } | null,
};

export type CustomUpdateSFCaseSeverityMutationVariables = {
  input: UpdateSFCaseSeverityInput,
  condition?: ModelSFCaseSeverityConditionInput | null,
};

export type CustomUpdateSFCaseSeverityMutation = {
  updateSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
  } | null,
};

export type CustomDeleteSFCaseSeverityMutationVariables = {
  input: DeleteSFCaseSeverityInput,
  condition?: ModelSFCaseSeverityConditionInput | null,
};

export type CustomDeleteSFCaseSeverityMutation = {
  deleteSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
  } | null,
};

export type CustomCreateSFCountryMutationVariables = {
  input: CreateSFCountryInput,
  condition?: ModelSFCountryConditionInput | null,
};

export type CustomCreateSFCountryMutation = {
  createSFCountry?:  {
    __typename: "SFCountry",
    id: string,
  } | null,
};

export type CustomUpdateSFCountryMutationVariables = {
  input: UpdateSFCountryInput,
  condition?: ModelSFCountryConditionInput | null,
};

export type CustomUpdateSFCountryMutation = {
  updateSFCountry?:  {
    __typename: "SFCountry",
    id: string,
  } | null,
};

export type CustomDeleteSFCountryMutationVariables = {
  input: DeleteSFCountryInput,
  condition?: ModelSFCountryConditionInput | null,
};

export type CustomDeleteSFCountryMutation = {
  deleteSFCountry?:  {
    __typename: "SFCountry",
    id: string,
  } | null,
};

export type CustomCreateSFRoleMutationVariables = {
  input: CreateSFRoleInput,
  condition?: ModelSFRoleConditionInput | null,
};

export type CustomCreateSFRoleMutation = {
  createSFRole?:  {
    __typename: "SFRole",
    id: string,
  } | null,
};

export type CustomUpdateSFRoleMutationVariables = {
  input: UpdateSFRoleInput,
  condition?: ModelSFRoleConditionInput | null,
};

export type CustomUpdateSFRoleMutation = {
  updateSFRole?:  {
    __typename: "SFRole",
    id: string,
  } | null,
};

export type CustomDeleteSFRoleMutationVariables = {
  input: DeleteSFRoleInput,
  condition?: ModelSFRoleConditionInput | null,
};

export type CustomDeleteSFRoleMutation = {
  deleteSFRole?:  {
    __typename: "SFRole",
    id: string,
  } | null,
};

export type CustomCreateSFSolutionMutationVariables = {
  input: CreateSFSolutionInput,
  condition?: ModelSFSolutionConditionInput | null,
};

export type CustomCreateSFSolutionMutation = {
  createSFSolution?:  {
    __typename: "SFSolution",
    id: string,
  } | null,
};

export type CustomUpdateSFSolutionMutationVariables = {
  input: UpdateSFSolutionInput,
  condition?: ModelSFSolutionConditionInput | null,
};

export type CustomUpdateSFSolutionMutation = {
  updateSFSolution?:  {
    __typename: "SFSolution",
    id: string,
  } | null,
};

export type CustomDeleteSFSolutionMutationVariables = {
  input: DeleteSFSolutionInput,
  condition?: ModelSFSolutionConditionInput | null,
};

export type CustomDeleteSFSolutionMutation = {
  deleteSFSolution?:  {
    __typename: "SFSolution",
    id: string,
  } | null,
};

export type CustomCreateSFClosureReasonMutationVariables = {
  input: CreateSFClosureReasonInput,
  condition?: ModelSFClosureReasonConditionInput | null,
};

export type CustomCreateSFClosureReasonMutation = {
  createSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
  } | null,
};

export type CustomUpdateSFClosureReasonMutationVariables = {
  input: UpdateSFClosureReasonInput,
  condition?: ModelSFClosureReasonConditionInput | null,
};

export type CustomUpdateSFClosureReasonMutation = {
  updateSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
  } | null,
};

export type CustomDeleteSFClosureReasonMutationVariables = {
  input: DeleteSFClosureReasonInput,
  condition?: ModelSFClosureReasonConditionInput | null,
};

export type CustomDeleteSFClosureReasonMutation = {
  deleteSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
  } | null,
};

export type CustomCreateApplicationMutationVariables = {
  input: CreateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type CustomCreateApplicationMutation = {
  createApplication?:  {
    __typename: "Application",
    id: string,
  } | null,
};

export type CustomUpdateApplicationMutationVariables = {
  input: UpdateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type CustomUpdateApplicationMutation = {
  updateApplication?:  {
    __typename: "Application",
    id: string,
  } | null,
};

export type CustomDeleteApplicationMutationVariables = {
  input: DeleteApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type CustomDeleteApplicationMutation = {
  deleteApplication?:  {
    __typename: "Application",
    id: string,
  } | null,
};

export type CustomCreateApplicationRoleMutationVariables = {
  input: CreateApplicationRoleInput,
  condition?: ModelApplicationRoleConditionInput | null,
};

export type CustomCreateApplicationRoleMutation = {
  createApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
  } | null,
};

export type CustomUpdateApplicationRoleMutationVariables = {
  input: UpdateApplicationRoleInput,
  condition?: ModelApplicationRoleConditionInput | null,
};

export type CustomUpdateApplicationRoleMutation = {
  updateApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
  } | null,
};

export type CustomDeleteApplicationRoleMutationVariables = {
  input: DeleteApplicationRoleInput,
  condition?: ModelApplicationRoleConditionInput | null,
};

export type CustomDeleteApplicationRoleMutation = {
  deleteApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
  } | null,
};

export type CustomCreateFileListMutationVariables = {
  input: CreateFileListInput,
  condition?: ModelFileListConditionInput | null,
};

export type CustomCreateFileListMutation = {
  createFileList?:  {
    __typename: "FileList",
    id: string,
  } | null,
};

export type CustomUpdateFileListMutationVariables = {
  input: UpdateFileListInput,
  condition?: ModelFileListConditionInput | null,
};

export type CustomUpdateFileListMutation = {
  updateFileList?:  {
    __typename: "FileList",
    id: string,
  } | null,
};

export type CustomDeleteFileListMutationVariables = {
  input: DeleteFileListInput,
  condition?: ModelFileListConditionInput | null,
};

export type CustomDeleteFileListMutation = {
  deleteFileList?:  {
    __typename: "FileList",
    id: string,
  } | null,
};

export type CustomCreateGroupMutationVariables = {
  input: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CustomCreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
  } | null,
};

export type CustomUpdateGroupMutationVariables = {
  input: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CustomUpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
  } | null,
};

export type CustomDeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CustomDeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
  } | null,
};

export type CustomCreateUserGroupMutationVariables = {
  input: CreateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CustomCreateUserGroupMutation = {
  createUserGroup?:  {
    __typename: "UserGroup",
    id: string,
  } | null,
};

export type CustomUpdateUserGroupMutationVariables = {
  input: UpdateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CustomUpdateUserGroupMutation = {
  updateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
  } | null,
};

export type CustomDeleteUserGroupMutationVariables = {
  input: DeleteUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CustomDeleteUserGroupMutation = {
  deleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
  } | null,
};

export type CustomCreateWatcherTaskMutationVariables = {
  input: CreateWatcherTaskInput,
  condition?: ModelWatcherTaskConditionInput | null,
};

export type CustomCreateWatcherTaskMutation = {
  createWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
  } | null,
};

export type CustomUpdateWatcherTaskMutationVariables = {
  input: UpdateWatcherTaskInput,
  condition?: ModelWatcherTaskConditionInput | null,
};

export type CustomUpdateWatcherTaskMutation = {
  updateWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
  } | null,
};

export type CustomDeleteWatcherTaskMutationVariables = {
  input: DeleteWatcherTaskInput,
  condition?: ModelWatcherTaskConditionInput | null,
};

export type CustomDeleteWatcherTaskMutation = {
  deleteWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
  } | null,
};

export type CustomCreateTaskMutationVariables = {
  input: CreateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CustomCreateTaskMutation = {
  createTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    salesForceTicketId?: string | null,
    requestedByUserId: string,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ActiveStatusType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    reviewedByUserId?: string | null,
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ActiveStatusType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    status: TaskStatusType,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          status?: ActiveStatusType | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        parentCommentId?: string | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        taskId?: string | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          productDocId?: string | null,
          createdAt: string,
          updatedAt: string,
          organizationId?: string | null,
          severity?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          type?: string | null,
          ticketRef?: string | null,
          closureReason?: string | null,
          linkedTickets?: Array< string | null > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedTickets?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    hasSensitiveInfo?: boolean | null,
    isSupportEngineerIdentityHidden?: boolean | null,
  } | null,
};

export type CustomUpdateTaskMutationVariables = {
  input: UpdateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CustomUpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    salesForceTicketId?: string | null,
    requestForInformation?: string | null,
    requestedByUserId: string,
    issueType?: string | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ActiveStatusType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    reviewedByUserId?: string | null,
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ActiveStatusType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    status: TaskStatusType,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        requestForInformation?: boolean | null,
        commentedByUserId: string,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          status?: ActiveStatusType | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        parentCommentId?: string | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        taskId?: string | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          productDocId?: string | null,
          createdAt: string,
          updatedAt: string,
          organizationId?: string | null,
          severity?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          type?: string | null,
          ticketRef?: string | null,
          closureReason?: string | null,
          linkedTickets?: Array< string | null > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedTickets?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    hasSensitiveInfo?: boolean | null,
    isSupportEngineerIdentityHidden?: boolean | null,
  } | null,
};

export type CustomDeleteTaskMutationVariables = {
  input: DeleteTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CustomDeleteTaskMutation = {
  deleteTask?:  {
    __typename: "Task",
    id: string,
  } | null,
};

export type CustomCreateChapterMutationVariables = {
  input: CreateChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type CustomCreateChapterMutation = {
  createChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
  } | null,
};

export type CustomUpdateChapterMutationVariables = {
  input: UpdateChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type CustomUpdateChapterMutation = {
  updateChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
  } | null,
};

export type CustomDeleteChapterMutationVariables = {
  input: DeleteChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type CustomDeleteChapterMutation = {
  deleteChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
  } | null,
};

export type CustomCreateDocMutationVariables = {
  input: CreateDocInput,
  condition?: ModelDocConditionInput | null,
};

export type CustomCreateDocMutation = {
  createDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
  } | null,
};

export type CustomUpdateDocMutationVariables = {
  input: UpdateDocInput,
  condition?: ModelDocConditionInput | null,
};

export type CustomUpdateDocMutation = {
  updateDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
  } | null,
};

export type CustomDeleteDocMutationVariables = {
  input: DeleteDocInput,
  condition?: ModelDocConditionInput | null,
};

export type CustomDeleteDocMutation = {
  deleteDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
  } | null,
};

export type CustomCreateDocItemMutationVariables = {
  input: CreateDocItemInput,
  condition?: ModelDocItemConditionInput | null,
};

export type CustomCreateDocItemMutation = {
  createDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    orderFloat?: string | null,
    createdByUserId: string,
    tenantId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    createdAt?: string | null,
    docId: string,
    itemType: DocItemType,
    shouldBeDeleted?: boolean | null,
  } | null,
};

export type CustomUpdateDocItemMutationVariables = {
  input: UpdateDocItemInput,
  condition?: ModelDocItemConditionInput | null,
};

export type CustomUpdateDocItemMutation = {
  updateDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
  } | null,
};

export type CustomDeleteDocItemMutationVariables = {
  input: DeleteDocItemInput,
  condition?: ModelDocItemConditionInput | null,
};

export type CustomDeleteDocItemMutation = {
  deleteDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
  } | null,
};

export type CustomCreateProductDocMutationVariables = {
  input: CreateProductDocInput,
  condition?: ModelProductDocConditionInput | null,
};

export type CustomCreateProductDocMutation = {
  createProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
  } | null,
};

export type CustomUpdateProductDocMutationVariables = {
  input: UpdateProductDocInput,
  condition?: ModelProductDocConditionInput | null,
};

export type CustomUpdateProductDocMutation = {
  updateProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
  } | null,
};

export type CustomDeleteProductDocMutationVariables = {
  input: DeleteProductDocInput,
  condition?: ModelProductDocConditionInput | null,
};

export type CustomDeleteProductDocMutation = {
  deleteProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
  } | null,
};

export type CustomCreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CustomCreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
  } | null,
};

export type CustomUpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CustomUpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
  } | null,
};

export type CustomDeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CustomDeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
  } | null,
};

export type CustomCreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CustomCreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
  } | null,
};

export type CustomUpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CustomUpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
  } | null,
};

export type CustomDeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CustomDeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
  } | null,
};

export type CustomCreateLeadMutationVariables = {
  input: CreateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type CustomCreateLeadMutation = {
  createLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        type?: CommentType | null,
        createdAt: string,
        updatedAt: string,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CustomUpdateLeadMutationVariables = {
  input: UpdateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type CustomUpdateLeadMutation = {
  updateLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        type?: CommentType | null,
        createdAt: string,
        updatedAt: string,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CustomDeleteLeadMutationVariables = {
  input: DeleteLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type CustomDeleteLeadMutation = {
  deleteLead?:  {
    __typename: "Lead",
    id: string,
  } | null,
};

export type CustomCreateWatcherLeadMutationVariables = {
  input: CreateWatcherLeadInput,
  condition?: ModelWatcherLeadConditionInput | null,
};

export type CustomCreateWatcherLeadMutation = {
  createWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
  } | null,
};

export type CustomUpdateWatcherLeadMutationVariables = {
  input: UpdateWatcherLeadInput,
  condition?: ModelWatcherLeadConditionInput | null,
};

export type CustomUpdateWatcherLeadMutation = {
  updateWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
  } | null,
};

export type CustomDeleteWatcherLeadMutationVariables = {
  input: DeleteWatcherLeadInput,
  condition?: ModelWatcherLeadConditionInput | null,
};

export type CustomDeleteWatcherLeadMutation = {
  deleteWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
  } | null,
};

export type CustomCreateCurrencyMutationVariables = {
  input: CreateCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type CustomCreateCurrencyMutation = {
  createCurrency?:  {
    __typename: "Currency",
    id: string,
  } | null,
};

export type CustomUpdateCurrencyMutationVariables = {
  input: UpdateCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type CustomUpdateCurrencyMutation = {
  updateCurrency?:  {
    __typename: "Currency",
    id: string,
  } | null,
};

export type CustomDeleteCurrencyMutationVariables = {
  input: DeleteCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type CustomDeleteCurrencyMutation = {
  deleteCurrency?:  {
    __typename: "Currency",
    id: string,
  } | null,
};

export type CustomCreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CustomCreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
  } | null,
};

export type CustomUpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CustomUpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
  } | null,
};

export type CustomDeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CustomDeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
  } | null,
};

export type CustomAssignTicketMutationVariables = {
  input?: AssignTicketInput | null,
};

export type CustomAssignTicketMutation = {
  assignTicket?:  {
    __typename: "AssignTicketResult",
    data?:  {
      __typename: "AssignTicketResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomCreateTicketRuleMutationVariables = {
  input: CreateTicketRuleInput,
  condition?: ModelTicketRuleConditionInput | null,
};

export type CustomCreateTicketRuleMutation = {
  createTicketRule?:  {
    __typename: "TicketRule",
    id: string,
  } | null,
};

export type CustomUpdateTicketRuleMutationVariables = {
  input: UpdateTicketRuleInput,
  condition?: ModelTicketRuleConditionInput | null,
};

export type CustomUpdateTicketRuleMutation = {
  updateTicketRule?:  {
    __typename: "TicketRule",
    id: string,
  } | null,
};

export type CustomDeleteTicketRuleMutationVariables = {
  input: DeleteTicketRuleInput,
  condition?: ModelTicketRuleConditionInput | null,
};

export type CustomDeleteTicketRuleMutation = {
  deleteTicketRule?:  {
    __typename: "TicketRule",
    id: string,
  } | null,
};

export type CustomCreateTicketAssignmentRuleMutationVariables = {
  input?: CreateTicketAssignmentRuleInput | null,
};

export type CustomCreateTicketAssignmentRuleMutation = {
  createTicketAssignmentRule?:  {
    __typename: "CreateTicketAssignmentRuleResult",
    data?:  {
      __typename: "CreateTicketAssignmentRuleResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomAssignUsersToGroupMutationVariables = {
  input?: AssignUsersToGroupInput | null,
};

export type CustomAssignUsersToGroupMutation = {
  assignUsersToGroup?:  {
    __typename: "AssignUsersToGroupResult",
    data?:  {
      __typename: "AssignUsersToGroupResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomAssignGroupsToUserMutationVariables = {
  input?: AssignGroupsToUserInput | null,
};

export type CustomAssignGroupsToUserMutation = {
  assignGroupsToUser?:  {
    __typename: "AssignGroupsToUserResult",
    data?:  {
      __typename: "AssignGroupsToUserResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomApproveDocumentsMutationVariables = {
  input?: ApproveDocumentsInput | null,
};

export type CustomApproveDocumentsMutation = {
  approveDocuments?:  {
    __typename: "ApproveDocumentsResult",
    data?:  {
      __typename: "ApproveDocumentsResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomDiscardDocumentsMutationVariables = {
  input?: DiscardDocumentsInput | null,
};

export type CustomDiscardDocumentsMutation = {
  discardDocuments?:  {
    __typename: "DiscardDocumentsResult",
    data?:  {
      __typename: "DiscardDocumentsResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomVerifyCompanyDetailsMutationVariables = {
  input: CompanyVerificationInput,
};

export type CustomVerifyCompanyDetailsMutation = {
  verifyCompanyDetails?:  {
    __typename: "CompanyVerificationResult",
    statusCode?: number | null,
    message?: string | null,
  } | null,
};

export type CustomImportRootChapterFromExportedFileMutationVariables = {
  input: ImportDocumentInput,
};

export type CustomImportRootChapterFromExportedFileMutation = {
  importRootChapterFromExportedFile?:  {
    __typename: "ImportRootChapterFromExportedFileResult",
    data?:  {
      __typename: "ImportRootChapterFromExportedFileData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomSendMailNotificationMutationVariables = {
  input?: SendMailNotificationInput | null,
};

export type CustomSendMailNotificationMutation = {
  sendMailNotification?:  {
    __typename: "SendMailNotificationResult",
    data?:  {
      __typename: "SendMailNotificationResultdata",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CustomListSFAccountsQueryVariables = {
  filter?: ModelSFAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFAccountsQuery = {
  listSFAccounts?:  {
    __typename: "ModelSFAccountConnection",
    items:  Array< {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFContractsQueryVariables = {
  filter?: ModelSFContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFContractsQuery = {
  listSFContracts?:  {
    __typename: "ModelSFContractConnection",
    items:  Array< {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      invoiceDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFContractLineItemsQueryVariables = {
  filter?: ModelSFContractLineItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFContractLineItemsQuery = {
  listSFContractLineItems?:  {
    __typename: "ModelSFContractLineItemConnection",
    items:  Array< {
      __typename: "SFContractLineItem",
      id: string,
      contractName?: string | null,
      itemNo?: string | null,
      productId?: string | null,
      quantity?: string | null,
      contractId?: string | null,
      productListPrice?: string | null,
      discount?: string | null,
      amount?: string | null,
      version?: string | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      contract?:  {
        __typename: "SFContract",
        id: string,
        contractNumber?: string | null,
        accountId?: string | null,
        contractTerm?: string | null,
        status?: string | null,
        ownerExpirationNotice?: string | null,
        totalContractValue?: string | null,
        contractType?: string | null,
        poNumber?: string | null,
        poDate?: string | null,
        referenceNumber?: string | null,
        partnerAccountId?: string | null,
        distributorAccountId?: string | null,
        paymentTerms?: string | null,
        poIssuedById?: string | null,
        contactId?: string | null,
        salesRepresentativeNameId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        customerAddressId?: string | null,
        designationId?: string | null,
        emailId?: string | null,
        mobileId?: string | null,
        year1MaintenanceEndDate?: string | null,
        year1MaintenanceStartDate?: string | null,
        year2MaintenanceEndDate?: string | null,
        year2MaintenanceStartDate?: string | null,
        year3MaintenanceEndDate?: string | null,
        year3MaintenanceStartDate?: string | null,
        value?: string | null,
        billingAddress?: string | null,
        description?: string | null,
        specialTerms?: string | null,
        signedById?: string | null,
        companySignedId?: string | null,
        activatedById?: string | null,
        activatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        partnerAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        distributorAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        poIssuedBy?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerAddress?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        contact?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        salesRepresentativeName?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        designation?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        email?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        mobile?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        signedBy?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      product?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFProductsQueryVariables = {
  filter?: ModelSFProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFProductsQuery = {
  listSFProducts?:  {
    __typename: "ModelSFProductConnection",
    items:  Array< {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFProductSubCategoriesQueryVariables = {
  filter?: ModelSFProductSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFProductSubCategoriesQuery = {
  listSFProductSubCategories?:  {
    __typename: "ModelSFProductSubCategoryConnection",
    items:  Array< {
      __typename: "SFProductSubCategory",
      id: string,
      name?: string | null,
      sfProductId?: string | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFProductVersionsQueryVariables = {
  filter?: ModelSFProductVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFProductVersionsQuery = {
  listSFProductVersions?:  {
    __typename: "ModelSFProductVersionConnection",
    items:  Array< {
      __typename: "SFProductVersion",
      id: string,
      name?: string | null,
      version?: string | null,
      sfProductId?: string | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetGroupQueryVariables = {
  id: string,
};

export type CustomGetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            domain?: string | null,
            accountId?: string | null,
            recordId?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          status?: ActiveStatusType | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        groupId: string,
        group?:  {
          __typename: "Group",
          name: string,
          description?: string | null,
        } | null,
        productDocs?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tenantId: string,
  } | null,
};

export type CustomListGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          users:  {
            __typename: "User",
            id: string,
            userType: UserType,
            firstName: string,
            lastName: string,
            email: string,
            organizationId?: string | null,
            salesForceId?: string | null,
            role?: RoleType | null,
            secondaryEmail: string,
            organization?:  {
              __typename: "Organization",
              id: string,
              name?: string | null,
              domain?: string | null,
              accountId?: string | null,
              recordId?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            status?: ActiveStatusType | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          application?:  {
            __typename: "Application",
            id: string,
            name: string,
            description?: string | null,
            defaultRoles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          groupId: string,
          group?:  {
            __typename: "Group",
            name: string,
            description?: string | null,
          } | null,
          productDocs?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tenantId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetDocQueryVariables = {
  id: string,
  version: string,
};

export type CustomGetDocQuery = {
  getDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    orderFloat?: string | null,
    createdByUserId: string,
    referenceDocId?: string | null,
    tenantId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        orderFloat?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        shouldBeDeleted?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    shouldBeDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
  } | null,
};

export type CustomGetTaskQueryVariables = {
  id: string,
};

export type CustomGetTaskQuery = {
  getTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    salesForceTicketId?: string | null,
    tenantId: string,
    requestedByUserId: string,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ActiveStatusType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    reviewedByUserId?: string | null,
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ActiveStatusType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    status: TaskStatusType,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          status?: ActiveStatusType | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        parentCommentId?: string | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        taskId?: string | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          productDocId?: string | null,
          createdAt: string,
          updatedAt: string,
          organizationId?: string | null,
          severity?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          type?: string | null,
          ticketRef?: string | null,
          closureReason?: string | null,
          linkedTickets?: Array< string | null > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedTickets?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    hasSensitiveInfo?: boolean | null,
  } | null,
};

export type CustomGetTicketQueryVariables = {
  id: string,
};

export type CustomGetTicketQuery = {
  getTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestForInformation?: string | null,
    tenantId: string,
    requestedByUserId: string,
    issueType?: string | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
      } | null,
      secondaryEmail: string,
      status?: ActiveStatusType | null,
    },
    status: TaskStatusType,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
          } | null,
          secondaryEmail: string,
          status?: ActiveStatusType | null,
        } | null,
        taskId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocId?: string | null,
    usersToNotify?: Array< string | null > | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        users:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          secondaryEmail: string,
          status?: ActiveStatusType | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        users:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          secondaryEmail: string,
          status?: ActiveStatusType | null,
        },
        createdByUserId?: string | null,
        createdByUser?:  {
          __typename: "User",
          firstName: string,
          lastName: string,
          email: string,
          secondaryEmail: string,
        } | null,
        updatedByUserId?: string | null,
        updatedByUser?:  {
          __typename: "User",
          firstName: string,
          lastName: string,
          email: string,
          secondaryEmail: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          users?:  {
            __typename: "ModelUserGroupConnection",
            items:  Array< {
              __typename: "UserGroup",
              id: string,
              userId: string,
              users:  {
                __typename: "User",
                id: string,
                firstName: string,
                lastName: string,
                email: string,
                organizationId?: string | null,
                salesForceId?: string | null,
                secondaryEmail: string,
                status?: ActiveStatusType | null,
              },
            } | null >,
          } | null,
        },
        createdByUserId?: string | null,
        createdByUser?:  {
          __typename: "User",
          firstName: string,
          lastName: string,
          email: string,
          secondaryEmail: string,
        } | null,
        updatedByUserId?: string | null,
        updatedByUser?:  {
          __typename: "User",
          firstName: string,
          lastName: string,
          email: string,
          secondaryEmail: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedTickets?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    hasSensitiveInfo?: boolean | null,
    isSupportEngineerIdentityHidden?: boolean | null,
  } | null,
};

export type CustomListTicketsQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListTicketsQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListTasksQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      salesForceTicketId?: string | null,
      requestForInformation?: string | null,
      tenantId: string,
      issueType?: string | null,
      requestedByUserId: string,
      requestedByUser:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        organizationId?: string | null,
      },
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          commentedByUserId: string,
          commentedByUser?:  {
            __typename: "User",
            id: string,
            firstName: string,
            lastName: string,
            organizationId?: string | null,
          } | null,
          taskId?: string | null,
          createdAt: string,
          updatedAt: string,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          users:  {
            __typename: "User",
            id: string,
            userType: UserType,
            firstName: string,
            lastName: string,
            displayName?: string | null,
            jobTitle?: string | null,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
            country?: string | null,
            email: string,
            organizationId?: string | null,
            salesForceId?: string | null,
            role?: RoleType | null,
            secondaryEmail: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            status?: ActiveStatusType | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          groups:  {
            __typename: "Group",
            id: string,
            name: string,
            description?: string | null,
            status?: ActiveStatusType | null,
            organizationId?: string | null,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserGroupConnection",
              items:  Array< {
                __typename: "UserGroup",
                id: string,
                userId: string,
              } | null >,
            } | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedTickets?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      hasSensitiveInfo?: boolean | null,
      isSupportEngineerIdentityHidden?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetUserQueryVariables = {
  id: string,
};

export type CustomGetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    tenantId: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    email: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    organizationId?: string | null,
    salesForceId?: string | null,
    preferredCurrency?: string | null,
    role?: RoleType | null,
    status?: ActiveStatusType | null,
    secondaryEmail: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          organizationId?: string | null,
          status?: ActiveStatusType | null,
          createdAt: string,
          updatedAt: string,
          applicationRoles?:  {
            __typename: "ModelApplicationRoleConnection",
            items:  Array< {
              __typename: "ApplicationRole",
              id: string,
              roleId: string,
              applicationId: string,
              application?:  {
                __typename: "Application",
                id: string,
                name: string,
                description?: string | null,
                defaultRoles?: Array< string | null > | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              groupId: string,
              group?:  {
                __typename: "Group",
                id: string,
                name: string,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
                status?: ActiveStatusType | null,
              } | null,
              productDocs?: Array< string | null > | null,
              locations?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CustomListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      tenantId: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      email: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          users:  {
            __typename: "User",
            id: string,
            userType: UserType,
            firstName: string,
            lastName: string,
            email: string,
            organizationId?: string | null,
            salesForceId?: string | null,
            role?: RoleType | null,
            secondaryEmail: string,
            createdAt?: string | null,
            updatedAt?: string | null,
          },
          groups:  {
            __typename: "Group",
            id: string,
            name: string,
            description?: string | null,
            status?: ActiveStatusType | null,
            createdAt: string,
            updatedAt: string,
            applicationRoles?:  {
              __typename: "ModelApplicationRoleConnection",
              items:  Array< {
                __typename: "ApplicationRole",
                id: string,
                roleId: string,
                applicationId: string,
                application?:  {
                  __typename: "Application",
                  id: string,
                  name: string,
                  description?: string | null,
                  defaultRoles?: Array< string | null > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
                groupId: string,
                group?:  {
                  __typename: "Group",
                  id: string,
                  name: string,
                  description?: string | null,
                  createdAt: string,
                  updatedAt: string,
                  status?: ActiveStatusType | null,
                } | null,
                productDocs?: Array< string | null > | null,
                locations?: Array< string | null > | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListApplicationsQueryVariables = {
  filter?: ModelApplicationFilterInput | null,
  productDocFilter?: ModelProductDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListApplicationsQuery = {
  listApplications?:  {
    __typename: "ModelApplicationConnection",
    items:  Array< {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetProductDocQueryVariables = {
  id: string,
  version: string,
};

export type CustomGetProductDocQuery = {
  getProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    tenantId: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        orderFloat?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapterId?: string | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        shouldBeDeleted?: boolean | null,
        isRejected?: boolean | null,
        currentTaskId?: string | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        tenantId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationId: string,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CustomGetChapterQueryVariables = {
  id: string,
  version: string,
};

export type CustomGetChapterQuery = {
  getChapter?:  {
    __typename: "Chapter",
    id: string,
    tenantId: string,
    version: string,
    status: string,
    name: string,
    orderFloat?: string | null,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        orderFloat?: string | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          items:  Array< {
            __typename: "DocItem",
            id: string,
            version: string,
            status: string,
            itemType: DocItemType,
            title: string,
            description?: string | null,
            rootChapterId: string,
            docId: string,
            shouldBeDeleted?: boolean | null,
            createdAt?: string | null,
            updatedAt: string,
            orderFloat?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        shouldBeDeleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapterId?: string | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        orderFloat?: string | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapterId?: string | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        shouldBeDeleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    shouldBeDeleted?: boolean | null,
    isRejected?: boolean | null,
    currentTaskId?: string | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type AllChaptersUnderRootChapterForImportExportQueryVariables = {
  rootChapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllChaptersUnderRootChapterForImportExportQuery = {
  allChaptersUnderRootChapter?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      name: string,
      status: string,
      createdAt: string,
      updatedAt: string,
      createdByUserId: string,
      lastModifiedByUserId: string,
      description?: string | null,
      parentChapterId?: string | null,
      parentProductDocId?: string | null,
      tenantId: string,
      rootChapterId: string,
      shouldBeDeleted?: boolean | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      orderFloat?: string | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AllDocsUnderRootChapterForImportExportQueryVariables = {
  rootChapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllDocsUnderRootChapterForImportExportQuery = {
  allDocsUnderRootChapter?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      title: string,
      status: string,
      createdAt: string,
      updatedAt: string,
      createdByUserId: string,
      lastModifiedByUserId: string,
      description?: string | null,
      rootChapterId: string,
      chapterId: string,
      orderFloat?: string | null,
      tenantId: string,
      referenceDocId?: string | null,
      shouldBeDeleted?: boolean | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AllDocItemsUnderRootChapterForImportExportQueryVariables = {
  rootChapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllDocItemsUnderRootChapterForImportExportQuery = {
  allDocItemsUnderRootChapter?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      title: string,
      status: string,
      createdAt?: string | null,
      updatedAt: string,
      createdByUserId: string,
      lastModifiedByUserId: string,
      description?: string | null,
      itemType: DocItemType,
      orderFloat?: string | null,
      tenantId: string,
      rootChapterId: string,
      shouldBeDeleted?: boolean | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      docId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNestedSubChaptersQueryVariables = {
  id: string,
  version: string,
  subChapterLevelFilter?: ModelChapterFilterInput | null,
  docLevelFilter?: ModelDocFilterInput | null,
  docItemLevelFilter?: ModelDocItemFilterInput | null,
  subChapterLevelLimit?: number | null,
  docLevelLimit?: number | null,
  docItemLevelLimit?: number | null,
};

export type GetNestedSubChaptersQuery = {
  getChapter?:  {
    __typename: "Chapter",
    id: string,
    tenantId: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    orderFloat?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        orderFloat?: string | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
        referenceDocId?: string | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          items:  Array< {
            __typename: "DocItem",
            id: string,
            version: string,
            status: string,
            itemType: DocItemType,
            title: string,
            description?: string | null,
            rootChapterId: string,
            docId: string,
            shouldBeDeleted?: boolean | null,
            createdAt?: string | null,
            updatedAt: string,
            orderFloat?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapterId?: string | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        rootChapterId: string,
        orderFloat?: string | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
        docs?:  {
          __typename: "ModelDocConnection",
          items:  Array< {
            __typename: "Doc",
            id: string,
            version: string,
            status: string,
            title: string,
            description?: string | null,
            orderFloat?: string | null,
            pdfFile?:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            } | null,
            rootChapterId: string,
            shouldBeDeleted?: boolean | null,
            chapterId: string,
            createdAt: string,
            updatedAt: string,
            referenceDocId?: string | null,
            docItems?:  {
              __typename: "ModelDocItemConnection",
              items:  Array< {
                __typename: "DocItem",
                id: string,
                version: string,
                status: string,
                itemType: DocItemType,
                title: string,
                description?: string | null,
                rootChapterId: string,
                docId: string,
                shouldBeDeleted?: boolean | null,
                createdAt?: string | null,
                updatedAt: string,
                orderFloat?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          items:  Array< {
            __typename: "Chapter",
            id: string,
            version: string,
            status: string,
            name: string,
            description?: string | null,
            rootChapterId: string,
            orderFloat?: string | null,
            pdfFile?:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            } | null,
            parentProductDocId?: string | null,
            parentChapterId?: string | null,
            shouldBeDeleted?: boolean | null,
            createdAt: string,
            updatedAt: string,
            docs?:  {
              __typename: "ModelDocConnection",
              items:  Array< {
                __typename: "Doc",
                id: string,
                version: string,
                status: string,
                title: string,
                description?: string | null,
                orderFloat?: string | null,
                pdfFile?:  {
                  __typename: "S3Object",
                  bucket: string,
                  region: string,
                  key: string,
                } | null,
                rootChapterId: string,
                chapterId: string,
                shouldBeDeleted?: boolean | null,
                createdAt: string,
                updatedAt: string,
                referenceDocId?: string | null,
                docItems?:  {
                  __typename: "ModelDocItemConnection",
                  items:  Array< {
                    __typename: "DocItem",
                    id: string,
                    version: string,
                    status: string,
                    itemType: DocItemType,
                    title: string,
                    description?: string | null,
                    rootChapterId: string,
                    docId: string,
                    shouldBeDeleted?: boolean | null,
                    createdAt?: string | null,
                    updatedAt: string,
                    orderFloat?: string | null,
                  } | null >,
                  nextToken?: string | null,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            subChapters?:  {
              __typename: "ModelChapterConnection",
              items:  Array< {
                __typename: "Chapter",
                id: string,
                version: string,
                status: string,
                name: string,
                description?: string | null,
                rootChapterId: string,
                orderFloat?: string | null,
                pdfFile?:  {
                  __typename: "S3Object",
                  bucket: string,
                  region: string,
                  key: string,
                } | null,
                parentProductDocId?: string | null,
                parentChapterId?: string | null,
                shouldBeDeleted?: boolean | null,
                createdAt: string,
                updatedAt: string,
                docs?:  {
                  __typename: "ModelDocConnection",
                  items:  Array< {
                    __typename: "Doc",
                    id: string,
                    version: string,
                    status: string,
                    title: string,
                    description?: string | null,
                    orderFloat?: string | null,
                    pdfFile?:  {
                      __typename: "S3Object",
                      bucket: string,
                      region: string,
                      key: string,
                    } | null,
                    rootChapterId: string,
                    chapterId: string,
                    shouldBeDeleted?: boolean | null,
                    createdAt: string,
                    updatedAt: string,
                    referenceDocId?: string | null,
                    docItems?:  {
                      __typename: "ModelDocItemConnection",
                      items:  Array< {
                        __typename: "DocItem",
                        id: string,
                        version: string,
                        status: string,
                        itemType: DocItemType,
                        title: string,
                        description?: string | null,
                        rootChapterId: string,
                        docId: string,
                        shouldBeDeleted?: boolean | null,
                        createdAt?: string | null,
                        updatedAt: string,
                        orderFloat?: string | null,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                subChapters?:  {
                  __typename: "ModelChapterConnection",
                  items:  Array< {
                    __typename: "Chapter",
                    id: string,
                    version: string,
                    status: string,
                    name: string,
                    description?: string | null,
                    orderFloat?: string | null,
                    pdfFile?:  {
                      __typename: "S3Object",
                      bucket: string,
                      region: string,
                      key: string,
                    } | null,
                    rootChapterId: string,
                    parentProductDocId?: string | null,
                    parentChapterId?: string | null,
                    shouldBeDeleted?: boolean | null,
                    createdAt: string,
                    updatedAt: string,
                    docs?:  {
                      __typename: "ModelDocConnection",
                      items:  Array< {
                        __typename: "Doc",
                        id: string,
                        version: string,
                        status: string,
                        title: string,
                        description?: string | null,
                        orderFloat?: string | null,
                        pdfFile?:  {
                          __typename: "S3Object",
                          bucket: string,
                          region: string,
                          key: string,
                        } | null,
                        rootChapterId: string,
                        chapterId: string,
                        shouldBeDeleted?: boolean | null,
                        createdAt: string,
                        updatedAt: string,
                        referenceDocId?: string | null,
                        docItems?:  {
                          __typename: "ModelDocItemConnection",
                          items:  Array< {
                            __typename: "DocItem",
                            id: string,
                            version: string,
                            status: string,
                            itemType: DocItemType,
                            title: string,
                            description?: string | null,
                            rootChapterId: string,
                            docId: string,
                            shouldBeDeleted?: boolean | null,
                            createdAt?: string | null,
                            updatedAt: string,
                            orderFloat?: string | null,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    subChapters?:  {
                      __typename: "ModelChapterConnection",
                      items:  Array< {
                        __typename: "Chapter",
                        id: string,
                        version: string,
                        status: string,
                        name: string,
                        description?: string | null,
                        orderFloat?: string | null,
                        pdfFile?:  {
                          __typename: "S3Object",
                          bucket: string,
                          region: string,
                          key: string,
                        } | null,
                        rootChapterId: string,
                        parentProductDocId?: string | null,
                        parentChapterId?: string | null,
                        shouldBeDeleted?: boolean | null,
                        createdAt: string,
                        updatedAt: string,
                        docs?:  {
                          __typename: "ModelDocConnection",
                          items:  Array< {
                            __typename: "Doc",
                            id: string,
                            version: string,
                            status: string,
                            title: string,
                            description?: string | null,
                            orderFloat?: string | null,
                            pdfFile?:  {
                              __typename: "S3Object",
                              bucket: string,
                              region: string,
                              key: string,
                            } | null,
                            rootChapterId: string,
                            chapterId: string,
                            shouldBeDeleted?: boolean | null,
                            createdAt: string,
                            updatedAt: string,
                            referenceDocId?: string | null,
                            docItems?:  {
                              __typename: "ModelDocItemConnection",
                              items:  Array< {
                                __typename: "DocItem",
                                id: string,
                                version: string,
                                status: string,
                                itemType: DocItemType,
                                title: string,
                                description?: string | null,
                                rootChapterId: string,
                                docId: string,
                                shouldBeDeleted?: boolean | null,
                                createdAt?: string | null,
                                updatedAt: string,
                                orderFloat?: string | null,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                  } | null >,
                  nextToken?: string | null,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shouldBeDeleted?: boolean | null,
    isRejected?: boolean | null,
    currentTaskId?: string | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetNestedSubChaptersUnderRootChapterQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type GetNestedSubChaptersUnderRootChapterQuery = {
  listChapters?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      tenantId: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      orderFloat?: string | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      isRejected?: boolean | null,
      currentTaskId?: string | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
  } | null,
};

export type CustomSearchTasksQueryVariables = {
  filter?: SearchableTaskFilterInput | null,
  sort?: SearchableTaskSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type CustomSearchTasksQuery = {
  searchTasks?:  {
    __typename: "SearchableTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      salesForceTicketId?: string | null,
      requestForInformation?: string | null,
      tenantId: string,
      issueType?: string | null,
      requestedByUserId: string,
      requestedByUser:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        organizationId?: string | null,
      },
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          commentedByUser?:  {
            __typename: "User",
            id: string,
            firstName: string,
            lastName: string,
            organizationId?: string | null,
          } | null,
          taskId?: string | null,
          createdAt: string,
          updatedAt: string,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          users:  {
            __typename: "User",
            id: string,
            userType: UserType,
            firstName: string,
            lastName: string,
            displayName?: string | null,
            jobTitle?: string | null,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
            country?: string | null,
            email: string,
            organizationId?: string | null,
            salesForceId?: string | null,
            role?: RoleType | null,
            secondaryEmail: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            status?: ActiveStatusType | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          groups:  {
            __typename: "Group",
            id: string,
            name: string,
            description?: string | null,
            status?: ActiveStatusType | null,
            organizationId?: string | null,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserGroupConnection",
              items:  Array< {
                __typename: "UserGroup",
                id: string,
                userId: string,
              } | null >,
            } | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedTickets?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      hasSensitiveInfo?: boolean | null,
      isSupportEngineerIdentityHidden?: boolean | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type CustomSearchLeadsQueryVariables = {
  filter?: SearchableLeadFilterInput | null,
  sort?: SearchableLeadSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type CustomSearchLeadsQuery = {
  searchLeads?:  {
    __typename: "SearchableLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      tenantId: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        organizationId?: string | null,
        organization?:  {
          __typename: "Organization",
          name?: string | null,
        } | null,
      } | null,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      createdAt: string,
      updatedAt: string,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          type?: CommentType | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type CustomGetLeadQueryVariables = {
  id: string,
};

export type CustomGetLeadQuery = {
  getLead?:  {
    __typename: "Lead",
    id: string,
    tenantId: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        type?: CommentType | null,
        createdAt: string,
        updatedAt: string,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CustomListLeadsQueryVariables = {
  filter?: ModelLeadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListLeadsQuery = {
  listLeads?:  {
    __typename: "ModelLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      tenantId: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        organizationId?: string | null,
        organization?:  {
          __typename: "Organization",
          name?: string | null,
        } | null,
      } | null,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      createdAt: string,
      updatedAt: string,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          attachments?:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null > | null,
          hasSensitiveInfo?: boolean | null,
          type?: CommentType | null,
          createdAt: string,
          updatedAt: string,
          commentedByUser?:  {
            __typename: "User",
            id: string,
            userType: UserType,
            firstName: string,
            lastName: string,
            displayName?: string | null,
            jobTitle?: string | null,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
            country?: string | null,
            email: string,
            organizationId?: string | null,
            salesForceId?: string | null,
            role?: RoleType | null,
            secondaryEmail: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            status?: ActiveStatusType | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          taskId?: string | null,
          leadId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
          users:  {
            __typename: "User",
            id: string,
            userType: UserType,
            firstName: string,
            lastName: string,
            displayName?: string | null,
            jobTitle?: string | null,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
            country?: string | null,
            email: string,
            organizationId?: string | null,
            salesForceId?: string | null,
            role?: RoleType | null,
            secondaryEmail: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            status?: ActiveStatusType | null,
          },
          leads:  {
            __typename: "Lead",
            id: string,
            leadReferenceNumber?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
            role?: string | null,
            jobTitle?: string | null,
            companyName?: string | null,
            companyAddress?: string | null,
            industry?: string | null,
            companyUrl?: string | null,
            leadName?: string | null,
            description?: string | null,
            timeline?: string | null,
            estimatedBudget?: string | null,
            estimatedNumberOfUsers?: number | null,
            solution?: string | null,
            incumbentProduct?: string | null,
            competitors?: string | null,
            submissionDate?: string | null,
            remarks?: string | null,
            createdByUserId: string,
            lastModifiedByUserId?: string | null,
            qualifiedByUserId?: string | null,
            disqualifiedByUserId?: string | null,
            approvedByUserId?: string | null,
            rejectedByUserId?: string | null,
            usersToNotify?: Array< string | null > | null,
            overallStatus?: LeadOverallStatusType | null,
            qualifiedStatus?: LeadQualifiedStatusType | null,
            approvedStatus?: LeadApprovedStatusType | null,
            qualifiedDate?: string | null,
            approvedDate?: string | null,
            qualifiedRemarks?: string | null,
            approvedRemarks?: string | null,
            salesForceLeadId?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetCurrencyQueryVariables = {
  id: string,
};

export type CustomGetCurrencyQuery = {
  getCurrency?:  {
    __typename: "Currency",
    id: string,
    tenantId: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CustomListCurrenciesQueryVariables = {
  filter?: ModelCurrencyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListCurrenciesQuery = {
  listCurrencies?:  {
    __typename: "ModelCurrencyConnection",
    items:  Array< {
      __typename: "Currency",
      id: string,
      tenantId: string,
      name?: string | null,
      symbol?: string | null,
      isDefaultCurrency?: boolean | null,
      code?: string | null,
      country?: string | null,
      exchangeRate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomSearchCurrenciesQueryVariables = {
  filter?: SearchableCurrencyFilterInput | null,
  sort?: SearchableCurrencySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type CustomSearchCurrenciesQuery = {
  searchCurrencies?:  {
    __typename: "SearchableCurrencyConnection",
    items:  Array< {
      __typename: "Currency",
      id: string,
      tenantId: string,
      name?: string | null,
      code?: string | null,
      country?: string | null,
      exchangeRate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type CustomListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      organizationId?: string | null,
      createdAt: string,
      updatedAt: string,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          tenantId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetTenantQueryVariables = {
  id: string,
};

export type CustomGetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    organizationId?: string | null,
    createdAt: string,
    updatedAt: string,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        tenantId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CustomListSFCaseSeveritiesQueryVariables = {
  filter?: ModelSFCaseSeverityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFCaseSeveritiesQuery = {
  listSFCaseSeverities?:  {
    __typename: "ModelSFCaseSeverityConnection",
    items:  Array< {
      __typename: "SFCaseSeverity",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFClosureReasonsQueryVariables = {
  filter?: ModelSFClosureReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFClosureReasonsQuery = {
  listSFClosureReasons?:  {
    __typename: "ModelSFClosureReasonConnection",
    items:  Array< {
      __typename: "SFClosureReason",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFContactsQueryVariables = {
  filter?: ModelSFContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFContactsQuery = {
  listSFContacts?:  {
    __typename: "ModelSFContactConnection",
    items:  Array< {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFCountriesQueryVariables = {
  filter?: ModelSFCountryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFCountriesQuery = {
  listSFCountries?:  {
    __typename: "ModelSFCountryConnection",
    items:  Array< {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFIndustriesQueryVariables = {
  filter?: ModelSFIndustryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFIndustriesQuery = {
  listSFIndustries?:  {
    __typename: "ModelSFIndustryConnection",
    items:  Array< {
      __typename: "SFIndustry",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFIssueCategoriesQueryVariables = {
  filter?: ModelSFIssueCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFIssueCategoriesQuery = {
  listSFIssueCategories?:  {
    __typename: "ModelSFIssueCategoryConnection",
    items:  Array< {
      __typename: "SFIssueCategory",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFRolesQueryVariables = {
  filter?: ModelSFRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFRolesQuery = {
  listSFRoles?:  {
    __typename: "ModelSFRoleConnection",
    items:  Array< {
      __typename: "SFRole",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFSolutionsQueryVariables = {
  filter?: ModelSFSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFSolutionsQuery = {
  listSFSolutions?:  {
    __typename: "ModelSFSolutionConnection",
    items:  Array< {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          tenantId: string,
          allSFData?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListSFCompetitorsQueryVariables = {
  filter?: ModelSFCompetitorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListSFCompetitorsQuery = {
  listSFCompetitors?:  {
    __typename: "ModelSFCompetitorConnection",
    items:  Array< {
      __typename: "SFCompetitor",
      id: string,
      name?: string | null,
      domain?: string | null,
      competitors?: string | null,
      solutionId?: string | null,
      tenantId: string,
      allSFData?: string | null,
      createdAt: string,
      updatedAt: string,
      solution?:  {
        __typename: "SFSolution",
        id: string,
        name?: string | null,
        leadId?: string | null,
        tenantId: string,
        allSFData?: string | null,
        createdAt: string,
        updatedAt: string,
        competitors?:  {
          __typename: "ModelSFCompetitorConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type customListApplicationRolesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  filter?: ModelApplicationRoleFilterInput | null,
};

export type customListApplicationRolesQuery = {
  listApplicationRoles?:  {
    __typename: "ModelApplicationRoleConnection",
    items:  Array< {
      __typename: "ApplicationRole",
      id: string,
      productDocs?: Array< string | null > | null,
      roleId: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        users?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userId: string,
            users:  {
              __typename: "User",
              id: string,
              firstName: string,
              lastName: string,
              email: string,
              secondaryEmail: string,
              status?: ActiveStatusType | null,
              organizationId?: string | null,
              organization?:  {
                __typename: "Organization",
                name?: string | null,
              } | null,
            },
          } | null >,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetDocVersionQueryVariables = {
  id: string,
  version: string,
  sortDirection?: ModelSortDirection | null,
  docItemLimit?: number | null,
  docItemFilter?: ModelDocItemFilterInput | null,
  docItemNextToken?: string | null,
};

export type CustomGetDocVersionQuery = {
  getDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    orderFloat?: string | null,
    createdByUserId: string,
    referenceDocId?: string | null,
    tenantId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        orderFloat?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        shouldBeDeleted?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CustomListDocVersionsQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  docItemLevelLimit?: number | null,
  docItemLevelFilter?: ModelDocItemFilterInput | null,
};

export type CustomListDocVersionsQuery = {
  listDocs?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      orderFloat?: string | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      referenceDocId?: string | null,
      tenantId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          orderFloat?: string | null,
          itemType: DocItemType,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          shouldBeDeleted?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListProductDocsQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelProductDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomListProductDocsQuery = {
  listProductDocs?:  {
    __typename: "ModelProductDocConnection",
    items:  Array< {
      __typename: "ProductDoc",
      id: string,
      version: string,
      tenantId: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          pdfFile?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationId: string,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderFloat?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListApplicationRolesQueryVariables = {
  filter?: ModelApplicationRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListApplicationRolesQuery = {
  listApplicationRoles?:  {
    __typename: "ModelApplicationRoleConnection",
    items:  Array< {
      __typename: "ApplicationRole",
      id: string,
      roleId: string,
      applicationId: string,
      groupId: string,
      productDocs?: Array< string | null > | null,
      locations?: Array< string | null > | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomByGroupByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomByGroupByOrganizationQuery = {
  byGroupByOrganization?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomSearchableListChaptersQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomSearchableListChaptersQuery = {
  listChapters?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      orderFloat?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      parentProductDocId?: string | null,
      rootChapterId: string,
      parentChapterId?: string | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetTicketRuleQueryVariables = {
  id: string,
};

export type CustomGetTicketRuleQuery = {
  getTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdAt: string,
    updatedAt: string,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CustomListTicketRulesQueryVariables = {
  filter?: ModelTicketRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListTicketRulesQuery = {
  listTicketRules?:  {
    __typename: "ModelTicketRuleConnection",
    items:  Array< {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdAt: string,
      updatedAt: string,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
          sfCountries:  {
            __typename: "SFCountry",
            id: string,
            name?: string | null,
            countryId?: string | null,
            region?: string | null,
            allSFData?: string | null,
            tenantId: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
          sfAccounts:  {
            __typename: "SFAccount",
            id: string,
            name?: string | null,
            accountName?: string | null,
            countryId?: string | null,
            parentAccountId?: string | null,
            emailDomain?: string | null,
            industry?: string | null,
            organizationId?: string | null,
            billingAddress?: string | null,
            shippingAddress?: string | null,
            copyBillingAddressToShippingAddress?: boolean | null,
            type?: string | null,
            phone?: string | null,
            website?: string | null,
            createdBy?: string | null,
            createdDate?: string | null,
            updatedBy?: string | null,
            updatedDate?: string | null,
            allSFData?: string | null,
            tenantId: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
          users:  {
            __typename: "User",
            id: string,
            userType: UserType,
            firstName: string,
            lastName: string,
            displayName?: string | null,
            jobTitle?: string | null,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
            country?: string | null,
            email: string,
            organizationId?: string | null,
            salesForceId?: string | null,
            role?: RoleType | null,
            secondaryEmail: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            status?: ActiveStatusType | null,
            isMigrated?: boolean | null,
            isTenantAdmin?: boolean | null,
            ticketsToReviewOrder?: Array< string | null > | null,
            supportPortalDashBoardChartsOrder?: Array< number | null > | null,
            tenantId: string,
            preferredCurrency?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
          groups:  {
            __typename: "Group",
            id: string,
            name: string,
            description?: string | null,
            status?: ActiveStatusType | null,
            organizationId?: string | null,
            tenantId: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type customOnUpdateLeadSubscriptionVariables = {
};

export type customOnUpdateLeadSubscription = {
  onUpdateLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    leadName?: string | null,
  } | null,
};

export type ImportRootChapterFromExportedFileMutationVariables = {
  input: ImportDocumentInput,
};

export type ImportRootChapterFromExportedFileMutation = {
  importRootChapterFromExportedFile?:  {
    __typename: "ImportRootChapterFromExportedFileResult",
    data?:  {
      __typename: "ImportRootChapterFromExportedFileData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type VerifyCompanyDetailsMutationVariables = {
  input: CompanyVerificationInput,
};

export type VerifyCompanyDetailsMutation = {
  verifyCompanyDetails?:  {
    __typename: "CompanyVerificationResult",
    statusCode?: number | null,
    message?: string | null,
  } | null,
};

export type AssignUsersToGroupMutationVariables = {
  input?: AssignUsersToGroupInput | null,
};

export type AssignUsersToGroupMutation = {
  assignUsersToGroup?:  {
    __typename: "AssignUsersToGroupResult",
    data?:  {
      __typename: "AssignUsersToGroupResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type AssignGroupsToUserMutationVariables = {
  input?: AssignGroupsToUserInput | null,
};

export type AssignGroupsToUserMutation = {
  assignGroupsToUser?:  {
    __typename: "AssignGroupsToUserResult",
    data?:  {
      __typename: "AssignGroupsToUserResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type SendMailNotificationMutationVariables = {
  input?: SendMailNotificationInput | null,
};

export type SendMailNotificationMutation = {
  sendMailNotification?:  {
    __typename: "SendMailNotificationResult",
    data?:  {
      __typename: "SendMailNotificationResultdata",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type ApproveDocumentsMutationVariables = {
  input?: ApproveDocumentsInput | null,
};

export type ApproveDocumentsMutation = {
  approveDocuments?:  {
    __typename: "ApproveDocumentsResult",
    data?:  {
      __typename: "ApproveDocumentsResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type DiscardDocumentsMutationVariables = {
  input?: DiscardDocumentsInput | null,
};

export type DiscardDocumentsMutation = {
  discardDocuments?:  {
    __typename: "DiscardDocumentsResult",
    data?:  {
      __typename: "DiscardDocumentsResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type AssignTicketMutationVariables = {
  input?: AssignTicketInput | null,
};

export type AssignTicketMutation = {
  assignTicket?:  {
    __typename: "AssignTicketResult",
    data?:  {
      __typename: "AssignTicketResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CreateTicketAssignmentRuleMutationVariables = {
  input?: CreateTicketAssignmentRuleInput | null,
};

export type CreateTicketAssignmentRuleMutation = {
  createTicketAssignmentRule?:  {
    __typename: "CreateTicketAssignmentRuleResult",
    data?:  {
      __typename: "CreateTicketAssignmentRuleResultData",
      statusCode?: number | null,
      message?: string | null,
    } | null,
    error?: string | null,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    domain?: string | null,
    organizationId?: string | null,
    tenantCustomizationId?: string | null,
    sidebarLinkId?: string | null,
    usedSpaceByDoc?: string | null,
    totalSpaceForDoc?: string | null,
    createdAt: string,
    updatedAt: string,
    sidebarLink?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tenantCustomization?:  {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    fileLists?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currencies?:  {
      __typename: "ModelCurrencyConnection",
      items:  Array< {
        __typename: "Currency",
        id: string,
        name?: string | null,
        symbol?: string | null,
        isDefaultCurrency?: boolean | null,
        code?: string | null,
        country?: string | null,
        exchangeRate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    domain?: string | null,
    organizationId?: string | null,
    tenantCustomizationId?: string | null,
    sidebarLinkId?: string | null,
    usedSpaceByDoc?: string | null,
    totalSpaceForDoc?: string | null,
    createdAt: string,
    updatedAt: string,
    sidebarLink?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tenantCustomization?:  {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    fileLists?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currencies?:  {
      __typename: "ModelCurrencyConnection",
      items:  Array< {
        __typename: "Currency",
        id: string,
        name?: string | null,
        symbol?: string | null,
        isDefaultCurrency?: boolean | null,
        code?: string | null,
        country?: string | null,
        exchangeRate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    domain?: string | null,
    organizationId?: string | null,
    tenantCustomizationId?: string | null,
    sidebarLinkId?: string | null,
    usedSpaceByDoc?: string | null,
    totalSpaceForDoc?: string | null,
    createdAt: string,
    updatedAt: string,
    sidebarLink?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tenantCustomization?:  {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    fileLists?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currencies?:  {
      __typename: "ModelCurrencyConnection",
      items:  Array< {
        __typename: "Currency",
        id: string,
        name?: string | null,
        symbol?: string | null,
        isDefaultCurrency?: boolean | null,
        code?: string | null,
        country?: string | null,
        exchangeRate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateSidebarLinkMutationVariables = {
  input: CreateSidebarLinkInput,
  condition?: ModelSidebarLinkConditionInput | null,
};

export type CreateSidebarLinkMutation = {
  createSidebarLink?:  {
    __typename: "SidebarLink",
    id: string,
    name?: string | null,
    url?: string | null,
    parentLinkId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    parentLink?:  {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    subLinks?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSidebarLinkMutationVariables = {
  input: UpdateSidebarLinkInput,
  condition?: ModelSidebarLinkConditionInput | null,
};

export type UpdateSidebarLinkMutation = {
  updateSidebarLink?:  {
    __typename: "SidebarLink",
    id: string,
    name?: string | null,
    url?: string | null,
    parentLinkId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    parentLink?:  {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    subLinks?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSidebarLinkMutationVariables = {
  input: DeleteSidebarLinkInput,
  condition?: ModelSidebarLinkConditionInput | null,
};

export type DeleteSidebarLinkMutation = {
  deleteSidebarLink?:  {
    __typename: "SidebarLink",
    id: string,
    name?: string | null,
    url?: string | null,
    parentLinkId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    parentLink?:  {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    subLinks?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTenantCustomizationMutationVariables = {
  input: CreateTenantCustomizationInput,
  condition?: ModelTenantCustomizationConditionInput | null,
};

export type CreateTenantCustomizationMutation = {
  createTenantCustomization?:  {
    __typename: "TenantCustomization",
    id: string,
    color?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTenantCustomizationMutationVariables = {
  input: UpdateTenantCustomizationInput,
  condition?: ModelTenantCustomizationConditionInput | null,
};

export type UpdateTenantCustomizationMutation = {
  updateTenantCustomization?:  {
    __typename: "TenantCustomization",
    id: string,
    color?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTenantCustomizationMutationVariables = {
  input: DeleteTenantCustomizationInput,
  condition?: ModelTenantCustomizationConditionInput | null,
};

export type DeleteTenantCustomizationMutation = {
  deleteTenantCustomization?:  {
    __typename: "TenantCustomization",
    id: string,
    color?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateApplicationMutationVariables = {
  input: CreateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type CreateApplicationMutation = {
  createApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    description?: string | null,
    defaultRoles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    tenants?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateApplicationMutationVariables = {
  input: UpdateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type UpdateApplicationMutation = {
  updateApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    description?: string | null,
    defaultRoles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    tenants?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteApplicationMutationVariables = {
  input: DeleteApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type DeleteApplicationMutation = {
  deleteApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    description?: string | null,
    defaultRoles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    tenants?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTenantApplicationMutationVariables = {
  input: CreateTenantApplicationInput,
  condition?: ModelTenantApplicationConditionInput | null,
};

export type CreateTenantApplicationMutation = {
  createTenantApplication?:  {
    __typename: "TenantApplication",
    id: string,
    tenantId: string,
    applicationId: string,
    createdAt: string,
    updatedAt: string,
    tenants:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    applications:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTenantApplicationMutationVariables = {
  input: UpdateTenantApplicationInput,
  condition?: ModelTenantApplicationConditionInput | null,
};

export type UpdateTenantApplicationMutation = {
  updateTenantApplication?:  {
    __typename: "TenantApplication",
    id: string,
    tenantId: string,
    applicationId: string,
    createdAt: string,
    updatedAt: string,
    tenants:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    applications:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTenantApplicationMutationVariables = {
  input: DeleteTenantApplicationInput,
  condition?: ModelTenantApplicationConditionInput | null,
};

export type DeleteTenantApplicationMutation = {
  deleteTenantApplication?:  {
    __typename: "TenantApplication",
    id: string,
    tenantId: string,
    applicationId: string,
    createdAt: string,
    updatedAt: string,
    tenants:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    applications:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateProductDocMutationVariables = {
  input: CreateProductDocInput,
  condition?: ModelProductDocConditionInput | null,
};

export type CreateProductDocMutation = {
  createProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    applicationId: string,
    orderFloat?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProductDocMutationVariables = {
  input: UpdateProductDocInput,
  condition?: ModelProductDocConditionInput | null,
};

export type UpdateProductDocMutation = {
  updateProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    applicationId: string,
    orderFloat?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteProductDocMutationVariables = {
  input: DeleteProductDocInput,
  condition?: ModelProductDocConditionInput | null,
};

export type DeleteProductDocMutation = {
  deleteProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    applicationId: string,
    orderFloat?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateChapterMutationVariables = {
  input: CreateChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type CreateChapterMutation = {
  createChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    parentChapterId?: string | null,
    shouldBeDeleted?: boolean | null,
    currentTaskId?: string | null,
    isRejected?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateChapterMutationVariables = {
  input: UpdateChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type UpdateChapterMutation = {
  updateChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    parentChapterId?: string | null,
    shouldBeDeleted?: boolean | null,
    currentTaskId?: string | null,
    isRejected?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteChapterMutationVariables = {
  input: DeleteChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type DeleteChapterMutation = {
  deleteChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    parentChapterId?: string | null,
    shouldBeDeleted?: boolean | null,
    currentTaskId?: string | null,
    isRejected?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateDocMutationVariables = {
  input: CreateDocInput,
  condition?: ModelDocConditionInput | null,
};

export type CreateDocMutation = {
  createDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    createdByUserId: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    referenceDocId?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateDocMutationVariables = {
  input: UpdateDocInput,
  condition?: ModelDocConditionInput | null,
};

export type UpdateDocMutation = {
  updateDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    createdByUserId: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    referenceDocId?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteDocMutationVariables = {
  input: DeleteDocInput,
  condition?: ModelDocConditionInput | null,
};

export type DeleteDocMutation = {
  deleteDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    createdByUserId: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    referenceDocId?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateDocItemMutationVariables = {
  input: CreateDocItemInput,
  condition?: ModelDocItemConditionInput | null,
};

export type CreateDocItemMutation = {
  createDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    itemType: DocItemType,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    docId: string,
    createdAt?: string | null,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    doc?:  {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateDocItemMutationVariables = {
  input: UpdateDocItemInput,
  condition?: ModelDocItemConditionInput | null,
};

export type UpdateDocItemMutation = {
  updateDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    itemType: DocItemType,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    docId: string,
    createdAt?: string | null,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    doc?:  {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteDocItemMutationVariables = {
  input: DeleteDocItemInput,
  condition?: ModelDocItemConditionInput | null,
};

export type DeleteDocItemMutation = {
  deleteDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    itemType: DocItemType,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    docId: string,
    createdAt?: string | null,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    doc?:  {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    email: string,
    organizationId?: string | null,
    salesForceId?: string | null,
    role?: RoleType | null,
    secondaryEmail: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: ActiveStatusType | null,
    isMigrated?: boolean | null,
    isTenantAdmin?: boolean | null,
    ticketsToReviewOrder?: Array< string | null > | null,
    supportPortalDashBoardChartsOrder?: Array< number | null > | null,
    tenantId: string,
    preferredCurrency?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewedTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingTickets?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    qualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    disqualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    rejectedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingLeads?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    email: string,
    organizationId?: string | null,
    salesForceId?: string | null,
    role?: RoleType | null,
    secondaryEmail: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: ActiveStatusType | null,
    isMigrated?: boolean | null,
    isTenantAdmin?: boolean | null,
    ticketsToReviewOrder?: Array< string | null > | null,
    supportPortalDashBoardChartsOrder?: Array< number | null > | null,
    tenantId: string,
    preferredCurrency?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewedTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingTickets?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    qualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    disqualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    rejectedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingLeads?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    email: string,
    organizationId?: string | null,
    salesForceId?: string | null,
    role?: RoleType | null,
    secondaryEmail: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: ActiveStatusType | null,
    isMigrated?: boolean | null,
    isTenantAdmin?: boolean | null,
    ticketsToReviewOrder?: Array< string | null > | null,
    supportPortalDashBoardChartsOrder?: Array< number | null > | null,
    tenantId: string,
    preferredCurrency?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewedTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingTickets?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    qualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    disqualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    rejectedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingLeads?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    comment: string,
    commentedByUserId: string,
    parentCommentId?: string | null,
    taskId?: string | null,
    leadId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: boolean | null,
    type?: CommentType | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    commentedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentComment?:  {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lead?:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    comment: string,
    commentedByUserId: string,
    parentCommentId?: string | null,
    taskId?: string | null,
    leadId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: boolean | null,
    type?: CommentType | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    commentedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentComment?:  {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lead?:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    comment: string,
    commentedByUserId: string,
    parentCommentId?: string | null,
    taskId?: string | null,
    leadId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: boolean | null,
    type?: CommentType | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    commentedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentComment?:  {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lead?:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateGroupMutationVariables = {
  input: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserGroupMutationVariables = {
  input: CreateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CreateUserGroupMutation = {
  createUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userId: string,
    groupId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserGroupMutationVariables = {
  input: UpdateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupMutation = {
  updateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userId: string,
    groupId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserGroupMutationVariables = {
  input: DeleteUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type DeleteUserGroupMutation = {
  deleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userId: string,
    groupId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTicketRuleMutationVariables = {
  input: CreateTicketRuleInput,
  condition?: ModelTicketRuleConditionInput | null,
};

export type CreateTicketRuleMutation = {
  createTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTicketRuleMutationVariables = {
  input: UpdateTicketRuleInput,
  condition?: ModelTicketRuleConditionInput | null,
};

export type UpdateTicketRuleMutation = {
  updateTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTicketRuleMutationVariables = {
  input: DeleteTicketRuleInput,
  condition?: ModelTicketRuleConditionInput | null,
};

export type DeleteTicketRuleMutation = {
  deleteTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTicketRuleSFCountryMutationVariables = {
  input: CreateTicketRuleSFCountryInput,
  condition?: ModelTicketRuleSFCountryConditionInput | null,
};

export type CreateTicketRuleSFCountryMutation = {
  createTicketRuleSFCountry?:  {
    __typename: "TicketRuleSFCountry",
    id: string,
    ticketRuleId: string,
    sfCountryId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfCountries:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTicketRuleSFCountryMutationVariables = {
  input: UpdateTicketRuleSFCountryInput,
  condition?: ModelTicketRuleSFCountryConditionInput | null,
};

export type UpdateTicketRuleSFCountryMutation = {
  updateTicketRuleSFCountry?:  {
    __typename: "TicketRuleSFCountry",
    id: string,
    ticketRuleId: string,
    sfCountryId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfCountries:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTicketRuleSFCountryMutationVariables = {
  input: DeleteTicketRuleSFCountryInput,
  condition?: ModelTicketRuleSFCountryConditionInput | null,
};

export type DeleteTicketRuleSFCountryMutation = {
  deleteTicketRuleSFCountry?:  {
    __typename: "TicketRuleSFCountry",
    id: string,
    ticketRuleId: string,
    sfCountryId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfCountries:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTicketRuleSFAccountMutationVariables = {
  input: CreateTicketRuleSFAccountInput,
  condition?: ModelTicketRuleSFAccountConditionInput | null,
};

export type CreateTicketRuleSFAccountMutation = {
  createTicketRuleSFAccount?:  {
    __typename: "TicketRuleSFAccount",
    id: string,
    ticketRuleId: string,
    sfAccountId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfAccounts:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type UpdateTicketRuleSFAccountMutationVariables = {
  input: UpdateTicketRuleSFAccountInput,
  condition?: ModelTicketRuleSFAccountConditionInput | null,
};

export type UpdateTicketRuleSFAccountMutation = {
  updateTicketRuleSFAccount?:  {
    __typename: "TicketRuleSFAccount",
    id: string,
    ticketRuleId: string,
    sfAccountId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfAccounts:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type DeleteTicketRuleSFAccountMutationVariables = {
  input: DeleteTicketRuleSFAccountInput,
  condition?: ModelTicketRuleSFAccountConditionInput | null,
};

export type DeleteTicketRuleSFAccountMutation = {
  deleteTicketRuleSFAccount?:  {
    __typename: "TicketRuleSFAccount",
    id: string,
    ticketRuleId: string,
    sfAccountId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfAccounts:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type CreateTicketRuleUserMutationVariables = {
  input: CreateTicketRuleUserInput,
  condition?: ModelTicketRuleUserConditionInput | null,
};

export type CreateTicketRuleUserMutation = {
  createTicketRuleUser?:  {
    __typename: "TicketRuleUser",
    id: string,
    ticketRuleId: string,
    userId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTicketRuleUserMutationVariables = {
  input: UpdateTicketRuleUserInput,
  condition?: ModelTicketRuleUserConditionInput | null,
};

export type UpdateTicketRuleUserMutation = {
  updateTicketRuleUser?:  {
    __typename: "TicketRuleUser",
    id: string,
    ticketRuleId: string,
    userId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTicketRuleUserMutationVariables = {
  input: DeleteTicketRuleUserInput,
  condition?: ModelTicketRuleUserConditionInput | null,
};

export type DeleteTicketRuleUserMutation = {
  deleteTicketRuleUser?:  {
    __typename: "TicketRuleUser",
    id: string,
    ticketRuleId: string,
    userId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTicketRuleGroupMutationVariables = {
  input: CreateTicketRuleGroupInput,
  condition?: ModelTicketRuleGroupConditionInput | null,
};

export type CreateTicketRuleGroupMutation = {
  createTicketRuleGroup?:  {
    __typename: "TicketRuleGroup",
    id: string,
    ticketRuleId: string,
    groupId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTicketRuleGroupMutationVariables = {
  input: UpdateTicketRuleGroupInput,
  condition?: ModelTicketRuleGroupConditionInput | null,
};

export type UpdateTicketRuleGroupMutation = {
  updateTicketRuleGroup?:  {
    __typename: "TicketRuleGroup",
    id: string,
    ticketRuleId: string,
    groupId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTicketRuleGroupMutationVariables = {
  input: DeleteTicketRuleGroupInput,
  condition?: ModelTicketRuleGroupConditionInput | null,
};

export type DeleteTicketRuleGroupMutation = {
  deleteTicketRuleGroup?:  {
    __typename: "TicketRuleGroup",
    id: string,
    ticketRuleId: string,
    groupId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateApplicationRoleMutationVariables = {
  input: CreateApplicationRoleInput,
  condition?: ModelApplicationRoleConditionInput | null,
};

export type CreateApplicationRoleMutation = {
  createApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
    roleId: string,
    applicationId: string,
    groupId: string,
    productDocs?: Array< string | null > | null,
    locations?: Array< string | null > | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateApplicationRoleMutationVariables = {
  input: UpdateApplicationRoleInput,
  condition?: ModelApplicationRoleConditionInput | null,
};

export type UpdateApplicationRoleMutation = {
  updateApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
    roleId: string,
    applicationId: string,
    groupId: string,
    productDocs?: Array< string | null > | null,
    locations?: Array< string | null > | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteApplicationRoleMutationVariables = {
  input: DeleteApplicationRoleInput,
  condition?: ModelApplicationRoleConditionInput | null,
};

export type DeleteApplicationRoleMutation = {
  deleteApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
    roleId: string,
    applicationId: string,
    groupId: string,
    productDocs?: Array< string | null > | null,
    locations?: Array< string | null > | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    domain?: string | null,
    accountId?: string | null,
    recordId?: string | null,
    type?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    domain?: string | null,
    accountId?: string | null,
    recordId?: string | null,
    type?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    domain?: string | null,
    accountId?: string | null,
    recordId?: string | null,
    type?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateFileListMutationVariables = {
  input: CreateFileListInput,
  condition?: ModelFileListConditionInput | null,
};

export type CreateFileListMutation = {
  createFileList?:  {
    __typename: "FileList",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    taskId?: string | null,
    leadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateFileListMutationVariables = {
  input: UpdateFileListInput,
  condition?: ModelFileListConditionInput | null,
};

export type UpdateFileListMutation = {
  updateFileList?:  {
    __typename: "FileList",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    taskId?: string | null,
    leadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteFileListMutationVariables = {
  input: DeleteFileListInput,
  condition?: ModelFileListConditionInput | null,
};

export type DeleteFileListMutation = {
  deleteFileList?:  {
    __typename: "FileList",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    taskId?: string | null,
    leadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTaskFeedMutationVariables = {
  input: CreateTaskFeedInput,
  condition?: ModelTaskFeedConditionInput | null,
};

export type CreateTaskFeedMutation = {
  createTaskFeed?:  {
    __typename: "TaskFeed",
    id: string,
    tenantId: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTaskFeedMutationVariables = {
  input: UpdateTaskFeedInput,
  condition?: ModelTaskFeedConditionInput | null,
};

export type UpdateTaskFeedMutation = {
  updateTaskFeed?:  {
    __typename: "TaskFeed",
    id: string,
    tenantId: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTaskFeedMutationVariables = {
  input: DeleteTaskFeedInput,
  condition?: ModelTaskFeedConditionInput | null,
};

export type DeleteTaskFeedMutation = {
  deleteTaskFeed?:  {
    __typename: "TaskFeed",
    id: string,
    tenantId: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CreateTaskMutation = {
  createTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestedByUserId: string,
    reviewedByUserId?: string | null,
    status: TaskStatusType,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    linkedTickets?: Array< string | null > | null,
    salesForceTicketId?: string | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: string | null,
    isSupportEngineerIdentityHidden?: boolean | null,
    tenantId: string,
    issueType?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rootChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestedByUserId: string,
    reviewedByUserId?: string | null,
    status: TaskStatusType,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    linkedTickets?: Array< string | null > | null,
    salesForceTicketId?: string | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: string | null,
    isSupportEngineerIdentityHidden?: boolean | null,
    tenantId: string,
    issueType?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rootChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type DeleteTaskMutation = {
  deleteTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestedByUserId: string,
    reviewedByUserId?: string | null,
    status: TaskStatusType,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    linkedTickets?: Array< string | null > | null,
    salesForceTicketId?: string | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: string | null,
    isSupportEngineerIdentityHidden?: boolean | null,
    tenantId: string,
    issueType?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rootChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateWatcherTaskMutationVariables = {
  input: CreateWatcherTaskInput,
  condition?: ModelWatcherTaskConditionInput | null,
};

export type CreateWatcherTaskMutation = {
  createWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateWatcherTaskMutationVariables = {
  input: UpdateWatcherTaskInput,
  condition?: ModelWatcherTaskConditionInput | null,
};

export type UpdateWatcherTaskMutation = {
  updateWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteWatcherTaskMutationVariables = {
  input: DeleteWatcherTaskInput,
  condition?: ModelWatcherTaskConditionInput | null,
};

export type DeleteWatcherTaskMutation = {
  deleteWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateUserTaskMutationVariables = {
  input: CreateUserTaskInput,
  condition?: ModelUserTaskConditionInput | null,
};

export type CreateUserTaskMutation = {
  createUserTask?:  {
    __typename: "UserTask",
    id: string,
    userId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserTaskMutationVariables = {
  input: UpdateUserTaskInput,
  condition?: ModelUserTaskConditionInput | null,
};

export type UpdateUserTaskMutation = {
  updateUserTask?:  {
    __typename: "UserTask",
    id: string,
    userId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserTaskMutationVariables = {
  input: DeleteUserTaskInput,
  condition?: ModelUserTaskConditionInput | null,
};

export type DeleteUserTaskMutation = {
  deleteUserTask?:  {
    __typename: "UserTask",
    id: string,
    userId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateGroupTaskMutationVariables = {
  input: CreateGroupTaskInput,
  condition?: ModelGroupTaskConditionInput | null,
};

export type CreateGroupTaskMutation = {
  createGroupTask?:  {
    __typename: "GroupTask",
    id: string,
    groupId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateGroupTaskMutationVariables = {
  input: UpdateGroupTaskInput,
  condition?: ModelGroupTaskConditionInput | null,
};

export type UpdateGroupTaskMutation = {
  updateGroupTask?:  {
    __typename: "GroupTask",
    id: string,
    groupId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteGroupTaskMutationVariables = {
  input: DeleteGroupTaskInput,
  condition?: ModelGroupTaskConditionInput | null,
};

export type DeleteGroupTaskMutation = {
  deleteGroupTask?:  {
    __typename: "GroupTask",
    id: string,
    groupId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateLeadMutationVariables = {
  input: CreateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type CreateLeadMutation = {
  createLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateLeadMutationVariables = {
  input: UpdateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type UpdateLeadMutation = {
  updateLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteLeadMutationVariables = {
  input: DeleteLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type DeleteLeadMutation = {
  deleteLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateWatcherLeadMutationVariables = {
  input: CreateWatcherLeadInput,
  condition?: ModelWatcherLeadConditionInput | null,
};

export type CreateWatcherLeadMutation = {
  createWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
    userId: string,
    leadId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    leads:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateWatcherLeadMutationVariables = {
  input: UpdateWatcherLeadInput,
  condition?: ModelWatcherLeadConditionInput | null,
};

export type UpdateWatcherLeadMutation = {
  updateWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
    userId: string,
    leadId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    leads:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteWatcherLeadMutationVariables = {
  input: DeleteWatcherLeadInput,
  condition?: ModelWatcherLeadConditionInput | null,
};

export type DeleteWatcherLeadMutation = {
  deleteWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
    userId: string,
    leadId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    leads:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTempTokenMutationVariables = {
  input: CreateTempTokenInput,
  condition?: ModelTempTokenConditionInput | null,
};

export type CreateTempTokenMutation = {
  createTempToken?:  {
    __typename: "TempToken",
    id: string,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTempTokenMutationVariables = {
  input: UpdateTempTokenInput,
  condition?: ModelTempTokenConditionInput | null,
};

export type UpdateTempTokenMutation = {
  updateTempToken?:  {
    __typename: "TempToken",
    id: string,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTempTokenMutationVariables = {
  input: DeleteTempTokenInput,
  condition?: ModelTempTokenConditionInput | null,
};

export type DeleteTempTokenMutation = {
  deleteTempToken?:  {
    __typename: "TempToken",
    id: string,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCurrencyMutationVariables = {
  input: CreateCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type CreateCurrencyMutation = {
  createCurrency?:  {
    __typename: "Currency",
    id: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCurrencyMutationVariables = {
  input: UpdateCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type UpdateCurrencyMutation = {
  updateCurrency?:  {
    __typename: "Currency",
    id: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCurrencyMutationVariables = {
  input: DeleteCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type DeleteCurrencyMutation = {
  deleteCurrency?:  {
    __typename: "Currency",
    id: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFAccountMutationVariables = {
  input: CreateSFAccountInput,
  condition?: ModelSFAccountConditionInput | null,
};

export type CreateSFAccountMutation = {
  createSFAccount?:  {
    __typename: "SFAccount",
    id: string,
    name?: string | null,
    accountName?: string | null,
    countryId?: string | null,
    parentAccountId?: string | null,
    emailDomain?: string | null,
    industry?: string | null,
    organizationId?: string | null,
    billingAddress?: string | null,
    shippingAddress?: string | null,
    copyBillingAddressToShippingAddress?: boolean | null,
    type?: string | null,
    phone?: string | null,
    website?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    parentAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProducts?:  {
      __typename: "ModelSFProductConnection",
      items:  Array< {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFAccountMutationVariables = {
  input: UpdateSFAccountInput,
  condition?: ModelSFAccountConditionInput | null,
};

export type UpdateSFAccountMutation = {
  updateSFAccount?:  {
    __typename: "SFAccount",
    id: string,
    name?: string | null,
    accountName?: string | null,
    countryId?: string | null,
    parentAccountId?: string | null,
    emailDomain?: string | null,
    industry?: string | null,
    organizationId?: string | null,
    billingAddress?: string | null,
    shippingAddress?: string | null,
    copyBillingAddressToShippingAddress?: boolean | null,
    type?: string | null,
    phone?: string | null,
    website?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    parentAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProducts?:  {
      __typename: "ModelSFProductConnection",
      items:  Array< {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFAccountMutationVariables = {
  input: DeleteSFAccountInput,
  condition?: ModelSFAccountConditionInput | null,
};

export type DeleteSFAccountMutation = {
  deleteSFAccount?:  {
    __typename: "SFAccount",
    id: string,
    name?: string | null,
    accountName?: string | null,
    countryId?: string | null,
    parentAccountId?: string | null,
    emailDomain?: string | null,
    industry?: string | null,
    organizationId?: string | null,
    billingAddress?: string | null,
    shippingAddress?: string | null,
    copyBillingAddressToShippingAddress?: boolean | null,
    type?: string | null,
    phone?: string | null,
    website?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    parentAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProducts?:  {
      __typename: "ModelSFProductConnection",
      items:  Array< {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFContactMutationVariables = {
  input: CreateSFContactInput,
  condition?: ModelSFContactConditionInput | null,
};

export type CreateSFContactMutation = {
  createSFContact?:  {
    __typename: "SFContact",
    id: string,
    name?: string | null,
    phone?: string | null,
    accountId?: string | null,
    email?: string | null,
    title?: string | null,
    role?: string | null,
    countryId?: string | null,
    superVisorId?: string | null,
    region?: string | null,
    mailingAddress?: string | null,
    otherAddress?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    superVisor?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFContactMutationVariables = {
  input: UpdateSFContactInput,
  condition?: ModelSFContactConditionInput | null,
};

export type UpdateSFContactMutation = {
  updateSFContact?:  {
    __typename: "SFContact",
    id: string,
    name?: string | null,
    phone?: string | null,
    accountId?: string | null,
    email?: string | null,
    title?: string | null,
    role?: string | null,
    countryId?: string | null,
    superVisorId?: string | null,
    region?: string | null,
    mailingAddress?: string | null,
    otherAddress?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    superVisor?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFContactMutationVariables = {
  input: DeleteSFContactInput,
  condition?: ModelSFContactConditionInput | null,
};

export type DeleteSFContactMutation = {
  deleteSFContact?:  {
    __typename: "SFContact",
    id: string,
    name?: string | null,
    phone?: string | null,
    accountId?: string | null,
    email?: string | null,
    title?: string | null,
    role?: string | null,
    countryId?: string | null,
    superVisorId?: string | null,
    region?: string | null,
    mailingAddress?: string | null,
    otherAddress?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    superVisor?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFContractMutationVariables = {
  input: CreateSFContractInput,
  condition?: ModelSFContractConditionInput | null,
};

export type CreateSFContractMutation = {
  createSFContract?:  {
    __typename: "SFContract",
    id: string,
    contractNumber?: string | null,
    accountId?: string | null,
    contractTerm?: string | null,
    status?: string | null,
    ownerExpirationNotice?: string | null,
    totalContractValue?: string | null,
    contractType?: string | null,
    poNumber?: string | null,
    poDate?: string | null,
    referenceNumber?: string | null,
    partnerAccountId?: string | null,
    distributorAccountId?: string | null,
    paymentTerms?: string | null,
    poIssuedById?: string | null,
    contactId?: string | null,
    salesRepresentativeNameId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    customerAddressId?: string | null,
    designationId?: string | null,
    emailId?: string | null,
    mobileId?: string | null,
    year1MaintenanceEndDate?: string | null,
    year1MaintenanceStartDate?: string | null,
    year2MaintenanceEndDate?: string | null,
    year2MaintenanceStartDate?: string | null,
    year3MaintenanceEndDate?: string | null,
    year3MaintenanceStartDate?: string | null,
    value?: string | null,
    billingAddress?: string | null,
    description?: string | null,
    specialTerms?: string | null,
    signedById?: string | null,
    companySignedId?: string | null,
    activatedById?: string | null,
    activatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    invoiceDate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    companySigned?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    activatedBy?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    partnerAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    distributorAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    poIssuedBy?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    customerAddress?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    contact?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    salesRepresentativeName?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    designation?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    email?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    mobile?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    signedBy?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFContractMutationVariables = {
  input: UpdateSFContractInput,
  condition?: ModelSFContractConditionInput | null,
};

export type UpdateSFContractMutation = {
  updateSFContract?:  {
    __typename: "SFContract",
    id: string,
    contractNumber?: string | null,
    accountId?: string | null,
    contractTerm?: string | null,
    status?: string | null,
    ownerExpirationNotice?: string | null,
    totalContractValue?: string | null,
    contractType?: string | null,
    poNumber?: string | null,
    poDate?: string | null,
    referenceNumber?: string | null,
    partnerAccountId?: string | null,
    distributorAccountId?: string | null,
    paymentTerms?: string | null,
    poIssuedById?: string | null,
    contactId?: string | null,
    salesRepresentativeNameId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    customerAddressId?: string | null,
    designationId?: string | null,
    emailId?: string | null,
    mobileId?: string | null,
    year1MaintenanceEndDate?: string | null,
    year1MaintenanceStartDate?: string | null,
    year2MaintenanceEndDate?: string | null,
    year2MaintenanceStartDate?: string | null,
    year3MaintenanceEndDate?: string | null,
    year3MaintenanceStartDate?: string | null,
    value?: string | null,
    billingAddress?: string | null,
    description?: string | null,
    specialTerms?: string | null,
    signedById?: string | null,
    companySignedId?: string | null,
    activatedById?: string | null,
    activatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    invoiceDate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    companySigned?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    activatedBy?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    partnerAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    distributorAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    poIssuedBy?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    customerAddress?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    contact?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    salesRepresentativeName?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    designation?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    email?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    mobile?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    signedBy?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFContractMutationVariables = {
  input: DeleteSFContractInput,
  condition?: ModelSFContractConditionInput | null,
};

export type DeleteSFContractMutation = {
  deleteSFContract?:  {
    __typename: "SFContract",
    id: string,
    contractNumber?: string | null,
    accountId?: string | null,
    contractTerm?: string | null,
    status?: string | null,
    ownerExpirationNotice?: string | null,
    totalContractValue?: string | null,
    contractType?: string | null,
    poNumber?: string | null,
    poDate?: string | null,
    referenceNumber?: string | null,
    partnerAccountId?: string | null,
    distributorAccountId?: string | null,
    paymentTerms?: string | null,
    poIssuedById?: string | null,
    contactId?: string | null,
    salesRepresentativeNameId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    customerAddressId?: string | null,
    designationId?: string | null,
    emailId?: string | null,
    mobileId?: string | null,
    year1MaintenanceEndDate?: string | null,
    year1MaintenanceStartDate?: string | null,
    year2MaintenanceEndDate?: string | null,
    year2MaintenanceStartDate?: string | null,
    year3MaintenanceEndDate?: string | null,
    year3MaintenanceStartDate?: string | null,
    value?: string | null,
    billingAddress?: string | null,
    description?: string | null,
    specialTerms?: string | null,
    signedById?: string | null,
    companySignedId?: string | null,
    activatedById?: string | null,
    activatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    invoiceDate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    companySigned?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    activatedBy?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    partnerAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    distributorAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    poIssuedBy?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    customerAddress?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    contact?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    salesRepresentativeName?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    designation?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    email?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    mobile?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    signedBy?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFContractLineItemMutationVariables = {
  input: CreateSFContractLineItemInput,
  condition?: ModelSFContractLineItemConditionInput | null,
};

export type CreateSFContractLineItemMutation = {
  createSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
    contractName?: string | null,
    itemNo?: string | null,
    productId?: string | null,
    quantity?: string | null,
    contractId?: string | null,
    productListPrice?: string | null,
    discount?: string | null,
    amount?: string | null,
    version?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contract?:  {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    product?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFContractLineItemMutationVariables = {
  input: UpdateSFContractLineItemInput,
  condition?: ModelSFContractLineItemConditionInput | null,
};

export type UpdateSFContractLineItemMutation = {
  updateSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
    contractName?: string | null,
    itemNo?: string | null,
    productId?: string | null,
    quantity?: string | null,
    contractId?: string | null,
    productListPrice?: string | null,
    discount?: string | null,
    amount?: string | null,
    version?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contract?:  {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    product?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFContractLineItemMutationVariables = {
  input: DeleteSFContractLineItemInput,
  condition?: ModelSFContractLineItemConditionInput | null,
};

export type DeleteSFContractLineItemMutation = {
  deleteSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
    contractName?: string | null,
    itemNo?: string | null,
    productId?: string | null,
    quantity?: string | null,
    contractId?: string | null,
    productListPrice?: string | null,
    discount?: string | null,
    amount?: string | null,
    version?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contract?:  {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    product?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFProductMutationVariables = {
  input: CreateSFProductInput,
  condition?: ModelSFProductConditionInput | null,
};

export type CreateSFProductMutation = {
  createSFProduct?:  {
    __typename: "SFProduct",
    id: string,
    productName?: string | null,
    productVersion?: string | null,
    productMetric?: string | null,
    productDescription?: string | null,
    sfAccountId?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    productCode?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProductSubCategories?:  {
      __typename: "ModelSFProductSubCategoryConnection",
      items:  Array< {
        __typename: "SFProductSubCategory",
        id: string,
        name?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    sfProductVersions?:  {
      __typename: "ModelSFProductVersionConnection",
      items:  Array< {
        __typename: "SFProductVersion",
        id: string,
        name?: string | null,
        version?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSFProductMutationVariables = {
  input: UpdateSFProductInput,
  condition?: ModelSFProductConditionInput | null,
};

export type UpdateSFProductMutation = {
  updateSFProduct?:  {
    __typename: "SFProduct",
    id: string,
    productName?: string | null,
    productVersion?: string | null,
    productMetric?: string | null,
    productDescription?: string | null,
    sfAccountId?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    productCode?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProductSubCategories?:  {
      __typename: "ModelSFProductSubCategoryConnection",
      items:  Array< {
        __typename: "SFProductSubCategory",
        id: string,
        name?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    sfProductVersions?:  {
      __typename: "ModelSFProductVersionConnection",
      items:  Array< {
        __typename: "SFProductVersion",
        id: string,
        name?: string | null,
        version?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSFProductMutationVariables = {
  input: DeleteSFProductInput,
  condition?: ModelSFProductConditionInput | null,
};

export type DeleteSFProductMutation = {
  deleteSFProduct?:  {
    __typename: "SFProduct",
    id: string,
    productName?: string | null,
    productVersion?: string | null,
    productMetric?: string | null,
    productDescription?: string | null,
    sfAccountId?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    productCode?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProductSubCategories?:  {
      __typename: "ModelSFProductSubCategoryConnection",
      items:  Array< {
        __typename: "SFProductSubCategory",
        id: string,
        name?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    sfProductVersions?:  {
      __typename: "ModelSFProductVersionConnection",
      items:  Array< {
        __typename: "SFProductVersion",
        id: string,
        name?: string | null,
        version?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateSFProductSubCategoryMutationVariables = {
  input: CreateSFProductSubCategoryInput,
  condition?: ModelSFProductSubCategoryConditionInput | null,
};

export type CreateSFProductSubCategoryMutation = {
  createSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
    name?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFProductSubCategoryMutationVariables = {
  input: UpdateSFProductSubCategoryInput,
  condition?: ModelSFProductSubCategoryConditionInput | null,
};

export type UpdateSFProductSubCategoryMutation = {
  updateSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
    name?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFProductSubCategoryMutationVariables = {
  input: DeleteSFProductSubCategoryInput,
  condition?: ModelSFProductSubCategoryConditionInput | null,
};

export type DeleteSFProductSubCategoryMutation = {
  deleteSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
    name?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFProductVersionMutationVariables = {
  input: CreateSFProductVersionInput,
  condition?: ModelSFProductVersionConditionInput | null,
};

export type CreateSFProductVersionMutation = {
  createSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
    name?: string | null,
    version?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFProductVersionMutationVariables = {
  input: UpdateSFProductVersionInput,
  condition?: ModelSFProductVersionConditionInput | null,
};

export type UpdateSFProductVersionMutation = {
  updateSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
    name?: string | null,
    version?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFProductVersionMutationVariables = {
  input: DeleteSFProductVersionInput,
  condition?: ModelSFProductVersionConditionInput | null,
};

export type DeleteSFProductVersionMutation = {
  deleteSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
    name?: string | null,
    version?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFPriceBookMutationVariables = {
  input: CreateSFPriceBookInput,
  condition?: ModelSFPriceBookConditionInput | null,
};

export type CreateSFPriceBookMutation = {
  createSFPriceBook?:  {
    __typename: "SFPriceBook",
    id: string,
    priceBookName?: string | null,
    active?: boolean | null,
    description?: string | null,
    isStandardPriceBook?: boolean | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFPriceBookMutationVariables = {
  input: UpdateSFPriceBookInput,
  condition?: ModelSFPriceBookConditionInput | null,
};

export type UpdateSFPriceBookMutation = {
  updateSFPriceBook?:  {
    __typename: "SFPriceBook",
    id: string,
    priceBookName?: string | null,
    active?: boolean | null,
    description?: string | null,
    isStandardPriceBook?: boolean | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFPriceBookMutationVariables = {
  input: DeleteSFPriceBookInput,
  condition?: ModelSFPriceBookConditionInput | null,
};

export type DeleteSFPriceBookMutation = {
  deleteSFPriceBook?:  {
    __typename: "SFPriceBook",
    id: string,
    priceBookName?: string | null,
    active?: boolean | null,
    description?: string | null,
    isStandardPriceBook?: boolean | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFIssueCategoryMutationVariables = {
  input: CreateSFIssueCategoryInput,
  condition?: ModelSFIssueCategoryConditionInput | null,
};

export type CreateSFIssueCategoryMutation = {
  createSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFIssueCategoryMutationVariables = {
  input: UpdateSFIssueCategoryInput,
  condition?: ModelSFIssueCategoryConditionInput | null,
};

export type UpdateSFIssueCategoryMutation = {
  updateSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFIssueCategoryMutationVariables = {
  input: DeleteSFIssueCategoryInput,
  condition?: ModelSFIssueCategoryConditionInput | null,
};

export type DeleteSFIssueCategoryMutation = {
  deleteSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFCaseSeverityMutationVariables = {
  input: CreateSFCaseSeverityInput,
  condition?: ModelSFCaseSeverityConditionInput | null,
};

export type CreateSFCaseSeverityMutation = {
  createSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFCaseSeverityMutationVariables = {
  input: UpdateSFCaseSeverityInput,
  condition?: ModelSFCaseSeverityConditionInput | null,
};

export type UpdateSFCaseSeverityMutation = {
  updateSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFCaseSeverityMutationVariables = {
  input: DeleteSFCaseSeverityInput,
  condition?: ModelSFCaseSeverityConditionInput | null,
};

export type DeleteSFCaseSeverityMutation = {
  deleteSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFClosureReasonMutationVariables = {
  input: CreateSFClosureReasonInput,
  condition?: ModelSFClosureReasonConditionInput | null,
};

export type CreateSFClosureReasonMutation = {
  createSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFClosureReasonMutationVariables = {
  input: UpdateSFClosureReasonInput,
  condition?: ModelSFClosureReasonConditionInput | null,
};

export type UpdateSFClosureReasonMutation = {
  updateSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFClosureReasonMutationVariables = {
  input: DeleteSFClosureReasonInput,
  condition?: ModelSFClosureReasonConditionInput | null,
};

export type DeleteSFClosureReasonMutation = {
  deleteSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFRoleMutationVariables = {
  input: CreateSFRoleInput,
  condition?: ModelSFRoleConditionInput | null,
};

export type CreateSFRoleMutation = {
  createSFRole?:  {
    __typename: "SFRole",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFRoleMutationVariables = {
  input: UpdateSFRoleInput,
  condition?: ModelSFRoleConditionInput | null,
};

export type UpdateSFRoleMutation = {
  updateSFRole?:  {
    __typename: "SFRole",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFRoleMutationVariables = {
  input: DeleteSFRoleInput,
  condition?: ModelSFRoleConditionInput | null,
};

export type DeleteSFRoleMutation = {
  deleteSFRole?:  {
    __typename: "SFRole",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFIndustryMutationVariables = {
  input: CreateSFIndustryInput,
  condition?: ModelSFIndustryConditionInput | null,
};

export type CreateSFIndustryMutation = {
  createSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFIndustryMutationVariables = {
  input: UpdateSFIndustryInput,
  condition?: ModelSFIndustryConditionInput | null,
};

export type UpdateSFIndustryMutation = {
  updateSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFIndustryMutationVariables = {
  input: DeleteSFIndustryInput,
  condition?: ModelSFIndustryConditionInput | null,
};

export type DeleteSFIndustryMutation = {
  deleteSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFSolutionMutationVariables = {
  input: CreateSFSolutionInput,
  condition?: ModelSFSolutionConditionInput | null,
};

export type CreateSFSolutionMutation = {
  createSFSolution?:  {
    __typename: "SFSolution",
    id: string,
    name?: string | null,
    leadId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    competitors?:  {
      __typename: "ModelSFCompetitorConnection",
      items:  Array< {
        __typename: "SFCompetitor",
        id: string,
        name?: string | null,
        domain?: string | null,
        competitors?: string | null,
        solutionId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solution?:  {
          __typename: "SFSolution",
          id: string,
          name?: string | null,
          leadId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSFSolutionMutationVariables = {
  input: UpdateSFSolutionInput,
  condition?: ModelSFSolutionConditionInput | null,
};

export type UpdateSFSolutionMutation = {
  updateSFSolution?:  {
    __typename: "SFSolution",
    id: string,
    name?: string | null,
    leadId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    competitors?:  {
      __typename: "ModelSFCompetitorConnection",
      items:  Array< {
        __typename: "SFCompetitor",
        id: string,
        name?: string | null,
        domain?: string | null,
        competitors?: string | null,
        solutionId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solution?:  {
          __typename: "SFSolution",
          id: string,
          name?: string | null,
          leadId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSFSolutionMutationVariables = {
  input: DeleteSFSolutionInput,
  condition?: ModelSFSolutionConditionInput | null,
};

export type DeleteSFSolutionMutation = {
  deleteSFSolution?:  {
    __typename: "SFSolution",
    id: string,
    name?: string | null,
    leadId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    competitors?:  {
      __typename: "ModelSFCompetitorConnection",
      items:  Array< {
        __typename: "SFCompetitor",
        id: string,
        name?: string | null,
        domain?: string | null,
        competitors?: string | null,
        solutionId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solution?:  {
          __typename: "SFSolution",
          id: string,
          name?: string | null,
          leadId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateSFCompetitorMutationVariables = {
  input: CreateSFCompetitorInput,
  condition?: ModelSFCompetitorConditionInput | null,
};

export type CreateSFCompetitorMutation = {
  createSFCompetitor?:  {
    __typename: "SFCompetitor",
    id: string,
    name?: string | null,
    domain?: string | null,
    competitors?: string | null,
    solutionId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    solution?:  {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSFCompetitorMutationVariables = {
  input: UpdateSFCompetitorInput,
  condition?: ModelSFCompetitorConditionInput | null,
};

export type UpdateSFCompetitorMutation = {
  updateSFCompetitor?:  {
    __typename: "SFCompetitor",
    id: string,
    name?: string | null,
    domain?: string | null,
    competitors?: string | null,
    solutionId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    solution?:  {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSFCompetitorMutationVariables = {
  input: DeleteSFCompetitorInput,
  condition?: ModelSFCompetitorConditionInput | null,
};

export type DeleteSFCompetitorMutation = {
  deleteSFCompetitor?:  {
    __typename: "SFCompetitor",
    id: string,
    name?: string | null,
    domain?: string | null,
    competitors?: string | null,
    solutionId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    solution?:  {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSFCountryMutationVariables = {
  input: CreateSFCountryInput,
  condition?: ModelSFCountryConditionInput | null,
};

export type CreateSFCountryMutation = {
  createSFCountry?:  {
    __typename: "SFCountry",
    id: string,
    name?: string | null,
    countryId?: string | null,
    region?: string | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    accounts?:  {
      __typename: "ModelSFAccountConnection",
      items:  Array< {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelSFContactConnection",
      items:  Array< {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSFCountryMutationVariables = {
  input: UpdateSFCountryInput,
  condition?: ModelSFCountryConditionInput | null,
};

export type UpdateSFCountryMutation = {
  updateSFCountry?:  {
    __typename: "SFCountry",
    id: string,
    name?: string | null,
    countryId?: string | null,
    region?: string | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    accounts?:  {
      __typename: "ModelSFAccountConnection",
      items:  Array< {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelSFContactConnection",
      items:  Array< {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSFCountryMutationVariables = {
  input: DeleteSFCountryInput,
  condition?: ModelSFCountryConditionInput | null,
};

export type DeleteSFCountryMutation = {
  deleteSFCountry?:  {
    __typename: "SFCountry",
    id: string,
    name?: string | null,
    countryId?: string | null,
    region?: string | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    accounts?:  {
      __typename: "ModelSFAccountConnection",
      items:  Array< {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelSFContactConnection",
      items:  Array< {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    domain?: string | null,
    organizationId?: string | null,
    tenantCustomizationId?: string | null,
    sidebarLinkId?: string | null,
    usedSpaceByDoc?: string | null,
    totalSpaceForDoc?: string | null,
    createdAt: string,
    updatedAt: string,
    sidebarLink?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tenantCustomization?:  {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    fileLists?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currencies?:  {
      __typename: "ModelCurrencyConnection",
      items:  Array< {
        __typename: "Currency",
        id: string,
        name?: string | null,
        symbol?: string | null,
        isDefaultCurrency?: boolean | null,
        code?: string | null,
        country?: string | null,
        exchangeRate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTenantNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTenantNameQuery = {
  byTenantName?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TenantByOrganizationIdQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TenantByOrganizationIdQuery = {
  tenantByOrganizationId?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTenantDomainQueryVariables = {
  domain?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTenantDomainQuery = {
  byTenantDomain?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TenantByTenantCustomizationIdQueryVariables = {
  tenantCustomizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TenantByTenantCustomizationIdQuery = {
  tenantByTenantCustomizationId?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSidebarLinkQueryVariables = {
  id: string,
};

export type GetSidebarLinkQuery = {
  getSidebarLink?:  {
    __typename: "SidebarLink",
    id: string,
    name?: string | null,
    url?: string | null,
    parentLinkId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    parentLink?:  {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    subLinks?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSidebarLinksQueryVariables = {
  filter?: ModelSidebarLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSidebarLinksQuery = {
  listSidebarLinks?:  {
    __typename: "ModelSidebarLinkConnection",
    items:  Array< {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySidebarLinkByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSidebarLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySidebarLinkByTenantIdQuery = {
  bySidebarLinkByTenantId?:  {
    __typename: "ModelSidebarLinkConnection",
    items:  Array< {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchSidebarLinksQueryVariables = {
  filter?: SearchableSidebarLinkFilterInput | null,
  sort?: SearchableSidebarLinkSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchSidebarLinksQuery = {
  searchSidebarLinks?:  {
    __typename: "SearchableSidebarLinkConnection",
    items:  Array< {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetTenantCustomizationQueryVariables = {
  id: string,
};

export type GetTenantCustomizationQuery = {
  getTenantCustomization?:  {
    __typename: "TenantCustomization",
    id: string,
    color?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListTenantCustomizationsQueryVariables = {
  filter?: ModelTenantCustomizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantCustomizationsQuery = {
  listTenantCustomizations?:  {
    __typename: "ModelTenantCustomizationConnection",
    items:  Array< {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTenantCustomizationByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantCustomizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTenantCustomizationByTenantIdQuery = {
  byTenantCustomizationByTenantId?:  {
    __typename: "ModelTenantCustomizationConnection",
    items:  Array< {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetApplicationQueryVariables = {
  id: string,
};

export type GetApplicationQuery = {
  getApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    description?: string | null,
    defaultRoles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    tenants?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListApplicationsQueryVariables = {
  filter?: ModelApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListApplicationsQuery = {
  listApplications?:  {
    __typename: "ModelApplicationConnection",
    items:  Array< {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByApplicationNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByApplicationNameQuery = {
  byApplicationName?:  {
    __typename: "ModelApplicationConnection",
    items:  Array< {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductDocQueryVariables = {
  id: string,
  version: string,
};

export type GetProductDocQuery = {
  getProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    applicationId: string,
    orderFloat?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListProductDocsQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelProductDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductDocsQuery = {
  listProductDocs?:  {
    __typename: "ModelProductDocConnection",
    items:  Array< {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByProductDocByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByProductDocByStatusQuery = {
  byProductDocByStatus?:  {
    __typename: "ModelProductDocConnection",
    items:  Array< {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByProductDocByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByProductDocByTenantIdQuery = {
  byProductDocByTenantId?:  {
    __typename: "ModelProductDocConnection",
    items:  Array< {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByProductDocByProductDocIdQueryVariables = {
  id?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByProductDocByProductDocIdQuery = {
  byProductDocByProductDocId?:  {
    __typename: "ModelProductDocConnection",
    items:  Array< {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChapterQueryVariables = {
  id: string,
  version: string,
};

export type GetChapterQuery = {
  getChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    parentChapterId?: string | null,
    shouldBeDeleted?: boolean | null,
    currentTaskId?: string | null,
    isRejected?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListChaptersQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListChaptersQuery = {
  listChapters?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByChapterByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByChapterByStatusQuery = {
  byChapterByStatus?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AllSubChaptersUnderChapterQueryVariables = {
  parentChapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllSubChaptersUnderChapterQuery = {
  allSubChaptersUnderChapter?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AllChaptersUnderRootChapterQueryVariables = {
  rootChapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllChaptersUnderRootChapterQuery = {
  allChaptersUnderRootChapter?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByChapterByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByChapterByTenantIdQuery = {
  byChapterByTenantId?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByChapterByArchivedByOrphanRemovalScriptQueryVariables = {
  archivedByOrphanRemovalScript?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByChapterByArchivedByOrphanRemovalScriptQuery = {
  byChapterByArchivedByOrphanRemovalScript?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByChapterByArchivedByIrrelevantContentRemovalScriptQueryVariables = {
  archivedByIrrelevantContentRemovalScript?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByChapterByArchivedByIrrelevantContentRemovalScriptQuery = {
  byChapterByArchivedByIrrelevantContentRemovalScript?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByChapterByChapterIdQueryVariables = {
  id?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByChapterByChapterIdQuery = {
  byChapterByChapterId?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocQueryVariables = {
  id: string,
  version: string,
};

export type GetDocQuery = {
  getDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    createdByUserId: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    referenceDocId?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListDocsQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDocsQuery = {
  listDocs?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocByStatusQuery = {
  byDocByStatus?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AllDocsUnderChapterQueryVariables = {
  chapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllDocsUnderChapterQuery = {
  allDocsUnderChapter?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AllDocsUnderRootChapterQueryVariables = {
  rootChapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllDocsUnderRootChapterQuery = {
  allDocsUnderRootChapter?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocByTenantIdQuery = {
  byDocByTenantId?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocByArchivedByOrphanRemovalScriptQueryVariables = {
  archivedByOrphanRemovalScript?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocByArchivedByOrphanRemovalScriptQuery = {
  byDocByArchivedByOrphanRemovalScript?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocByArchivedByIrrelevantContentRemovalScriptQueryVariables = {
  archivedByIrrelevantContentRemovalScript?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocByArchivedByIrrelevantContentRemovalScriptQuery = {
  byDocByArchivedByIrrelevantContentRemovalScript?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocByDocIdQueryVariables = {
  id?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocByDocIdQuery = {
  byDocByDocId?:  {
    __typename: "ModelDocConnection",
    items:  Array< {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocItemQueryVariables = {
  id: string,
  version: string,
};

export type GetDocItemQuery = {
  getDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    itemType: DocItemType,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    docId: string,
    createdAt?: string | null,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    doc?:  {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListDocItemsQueryVariables = {
  id?: string | null,
  version?: ModelStringKeyConditionInput | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDocItemsQuery = {
  listDocItems?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      itemType: DocItemType,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      docId: string,
      createdAt?: string | null,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      doc?:  {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null,
      parentDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocItemByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocItemByStatusQuery = {
  byDocItemByStatus?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      itemType: DocItemType,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      docId: string,
      createdAt?: string | null,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      doc?:  {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null,
      parentDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AllDocItemsUnderRootChapterQueryVariables = {
  rootChapterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllDocItemsUnderRootChapterQuery = {
  allDocItemsUnderRootChapter?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      itemType: DocItemType,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      docId: string,
      createdAt?: string | null,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      doc?:  {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null,
      parentDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocItemByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocItemByTenantIdQuery = {
  byDocItemByTenantId?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      itemType: DocItemType,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      docId: string,
      createdAt?: string | null,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      doc?:  {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null,
      parentDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocItemByArchivedByOrphanRemovalScriptQueryVariables = {
  archivedByOrphanRemovalScript?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocItemByArchivedByOrphanRemovalScriptQuery = {
  byDocItemByArchivedByOrphanRemovalScript?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      itemType: DocItemType,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      docId: string,
      createdAt?: string | null,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      doc?:  {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null,
      parentDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocItemByArchivedByIrrelevantContentRemovalScriptQueryVariables = {
  archivedByIrrelevantContentRemovalScript?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocItemByArchivedByIrrelevantContentRemovalScriptQuery = {
  byDocItemByArchivedByIrrelevantContentRemovalScript?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      itemType: DocItemType,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      docId: string,
      createdAt?: string | null,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      doc?:  {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null,
      parentDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByDocItemByDocItemIdQueryVariables = {
  id?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByDocItemByDocItemIdQuery = {
  byDocItemByDocItemId?:  {
    __typename: "ModelDocItemConnection",
    items:  Array< {
      __typename: "DocItem",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      itemType: DocItemType,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      docId: string,
      createdAt?: string | null,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      doc?:  {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null,
      parentDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    email: string,
    organizationId?: string | null,
    salesForceId?: string | null,
    role?: RoleType | null,
    secondaryEmail: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: ActiveStatusType | null,
    isMigrated?: boolean | null,
    isTenantAdmin?: boolean | null,
    ticketsToReviewOrder?: Array< string | null > | null,
    supportPortalDashBoardChartsOrder?: Array< number | null > | null,
    tenantId: string,
    preferredCurrency?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewedTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingTickets?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    qualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    disqualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    rejectedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingLeads?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByUserBySecondaryEmailQueryVariables = {
  secondaryEmail?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByUserBySecondaryEmailQuery = {
  byUserBySecondaryEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByOrganizationIdQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByOrganizationIdQuery = {
  usersByOrganizationId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByUserByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByUserByTenantIdQuery = {
  byUserByTenantId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    comment: string,
    commentedByUserId: string,
    parentCommentId?: string | null,
    taskId?: string | null,
    leadId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: boolean | null,
    type?: CommentType | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    commentedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentComment?:  {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lead?:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByCommentsByTypeQueryVariables = {
  type?: CommentType | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByCommentsByTypeQuery = {
  byCommentsByType?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByCommentByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByCommentByTenantIdQuery = {
  byCommentByTenantId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupQueryVariables = {
  id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByGroupNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByGroupNameQuery = {
  byGroupName?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByGroupByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByGroupByOrganizationQuery = {
  byGroupByOrganization?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByGroupByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByGroupByTenantIdQuery = {
  byGroupByTenantId?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTicketRuleQueryVariables = {
  id: string,
};

export type GetTicketRuleQuery = {
  getTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTicketRulesQueryVariables = {
  filter?: ModelTicketRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketRulesQuery = {
  listTicketRules?:  {
    __typename: "ModelTicketRuleConnection",
    items:  Array< {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTicketRuleByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTicketRuleByStatusQuery = {
  byTicketRuleByStatus?:  {
    __typename: "ModelTicketRuleConnection",
    items:  Array< {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTicketRuleByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTicketRuleByTenantIdQuery = {
  byTicketRuleByTenantId?:  {
    __typename: "ModelTicketRuleConnection",
    items:  Array< {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetApplicationRoleQueryVariables = {
  id: string,
};

export type GetApplicationRoleQuery = {
  getApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
    roleId: string,
    applicationId: string,
    groupId: string,
    productDocs?: Array< string | null > | null,
    locations?: Array< string | null > | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListApplicationRolesQueryVariables = {
  filter?: ModelApplicationRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListApplicationRolesQuery = {
  listApplicationRoles?:  {
    __typename: "ModelApplicationRoleConnection",
    items:  Array< {
      __typename: "ApplicationRole",
      id: string,
      roleId: string,
      applicationId: string,
      groupId: string,
      productDocs?: Array< string | null > | null,
      locations?: Array< string | null > | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByApplicationRoleByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelApplicationRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByApplicationRoleByTenantIdQuery = {
  byApplicationRoleByTenantId?:  {
    __typename: "ModelApplicationRoleConnection",
    items:  Array< {
      __typename: "ApplicationRole",
      id: string,
      roleId: string,
      applicationId: string,
      groupId: string,
      productDocs?: Array< string | null > | null,
      locations?: Array< string | null > | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    domain?: string | null,
    accountId?: string | null,
    recordId?: string | null,
    type?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByOrganizationByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByOrganizationByTenantIdQuery = {
  byOrganizationByTenantId?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFileListQueryVariables = {
  id: string,
};

export type GetFileListQuery = {
  getFileList?:  {
    __typename: "FileList",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    taskId?: string | null,
    leadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListFileListsQueryVariables = {
  filter?: ModelFileListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFileListsQuery = {
  listFileLists?:  {
    __typename: "ModelFileListConnection",
    items:  Array< {
      __typename: "FileList",
      id: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      taskId?: string | null,
      leadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByFileListByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFileListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByFileListByTenantIdQuery = {
  byFileListByTenantId?:  {
    __typename: "ModelFileListConnection",
    items:  Array< {
      __typename: "FileList",
      id: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      taskId?: string | null,
      leadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaskFeedQueryVariables = {
  id: string,
  tenantId: string,
};

export type GetTaskFeedQuery = {
  getTaskFeed?:  {
    __typename: "TaskFeed",
    id: string,
    tenantId: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListTaskFeedsQueryVariables = {
  id?: string | null,
  tenantId?: ModelIDKeyConditionInput | null,
  filter?: ModelTaskFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTaskFeedsQuery = {
  listTaskFeeds?:  {
    __typename: "ModelTaskFeedConnection",
    items:  Array< {
      __typename: "TaskFeed",
      id: string,
      tenantId: string,
      userId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      task:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskFeedByTaskIdQueryVariables = {
  taskId?: string | null,
  tenantId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskFeedByTaskIdQuery = {
  byTaskFeedByTaskId?:  {
    __typename: "ModelTaskFeedConnection",
    items:  Array< {
      __typename: "TaskFeed",
      id: string,
      tenantId: string,
      userId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      task:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskFeedByUserIdQueryVariables = {
  userId?: string | null,
  tenantId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskFeedByUserIdQuery = {
  byTaskFeedByUserId?:  {
    __typename: "ModelTaskFeedConnection",
    items:  Array< {
      __typename: "TaskFeed",
      id: string,
      tenantId: string,
      userId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      task:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskFeedByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskFeedByTenantIdQuery = {
  byTaskFeedByTenantId?:  {
    __typename: "ModelTaskFeedConnection",
    items:  Array< {
      __typename: "TaskFeed",
      id: string,
      tenantId: string,
      userId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      task:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaskQueryVariables = {
  id: string,
};

export type GetTaskQuery = {
  getTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestedByUserId: string,
    reviewedByUserId?: string | null,
    status: TaskStatusType,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    linkedTickets?: Array< string | null > | null,
    salesForceTicketId?: string | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: string | null,
    isSupportEngineerIdentityHidden?: boolean | null,
    tenantId: string,
    issueType?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rootChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TasksByStatusQueryVariables = {
  status?: TaskStatusType | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TasksByStatusQuery = {
  tasksByStatus?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskByOrganizationQuery = {
  byTaskByOrganization?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskBySeverityQueryVariables = {
  severity?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskBySeverityQuery = {
  byTaskBySeverity?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskBySeverityUpdatedAtQueryVariables = {
  severityUpdatedAt?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskBySeverityUpdatedAtQuery = {
  byTaskBySeverityUpdatedAt?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskByIssueCategoryQueryVariables = {
  issueCategory?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskByIssueCategoryQuery = {
  byTaskByIssueCategory?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskBySubCategoryQueryVariables = {
  subCategory?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskBySubCategoryQuery = {
  byTaskBySubCategory?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskByClosureReasonQueryVariables = {
  closureReason?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskByClosureReasonQuery = {
  byTaskByClosureReason?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskByTypeQueryVariables = {
  type?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskByTypeQuery = {
  byTaskByType?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskByTicketRefQueryVariables = {
  ticketRef?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskByTicketRefQuery = {
  byTaskByTicketRef?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTasksByIssueTypeQueryVariables = {
  issueType?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTasksByIssueTypeQuery = {
  byTasksByIssueType?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskByRequestForInformationQueryVariables = {
  requestForInformation?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskByRequestForInformationQuery = {
  byTaskByRequestForInformation?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByTaskByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTaskByTenantIdQuery = {
  byTaskByTenantId?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTasksQueryVariables = {
  filter?: SearchableTaskFilterInput | null,
  sort?: SearchableTaskSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchTasksQuery = {
  searchTasks?:  {
    __typename: "SearchableTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetLeadQueryVariables = {
  id: string,
};

export type GetLeadQuery = {
  getLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListLeadsQueryVariables = {
  filter?: ModelLeadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeadsQuery = {
  listLeads?:  {
    __typename: "ModelLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByLeadByOverallStatusQueryVariables = {
  overallStatus?: LeadOverallStatusType | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLeadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByLeadByOverallStatusQuery = {
  byLeadByOverallStatus?:  {
    __typename: "ModelLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByLeadByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLeadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByLeadByTenantIdQuery = {
  byLeadByTenantId?:  {
    __typename: "ModelLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchLeadsQueryVariables = {
  filter?: SearchableLeadFilterInput | null,
  sort?: SearchableLeadSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchLeadsQuery = {
  searchLeads?:  {
    __typename: "SearchableLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetTempTokenQueryVariables = {
  id: string,
};

export type GetTempTokenQuery = {
  getTempToken?:  {
    __typename: "TempToken",
    id: string,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTempTokensQueryVariables = {
  filter?: ModelTempTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTempTokensQuery = {
  listTempTokens?:  {
    __typename: "ModelTempTokenConnection",
    items:  Array< {
      __typename: "TempToken",
      id: string,
      value?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCurrencyQueryVariables = {
  id: string,
};

export type GetCurrencyQuery = {
  getCurrency?:  {
    __typename: "Currency",
    id: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCurrenciesQueryVariables = {
  filter?: ModelCurrencyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCurrenciesQuery = {
  listCurrencies?:  {
    __typename: "ModelCurrencyConnection",
    items:  Array< {
      __typename: "Currency",
      id: string,
      name?: string | null,
      symbol?: string | null,
      isDefaultCurrency?: boolean | null,
      code?: string | null,
      country?: string | null,
      exchangeRate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByCurrencyByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCurrencyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByCurrencyByTenantIdQuery = {
  byCurrencyByTenantId?:  {
    __typename: "ModelCurrencyConnection",
    items:  Array< {
      __typename: "Currency",
      id: string,
      name?: string | null,
      symbol?: string | null,
      isDefaultCurrency?: boolean | null,
      code?: string | null,
      country?: string | null,
      exchangeRate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCurrenciesQueryVariables = {
  filter?: SearchableCurrencyFilterInput | null,
  sort?: SearchableCurrencySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCurrenciesQuery = {
  searchCurrencies?:  {
    __typename: "SearchableCurrencyConnection",
    items:  Array< {
      __typename: "Currency",
      id: string,
      name?: string | null,
      symbol?: string | null,
      isDefaultCurrency?: boolean | null,
      code?: string | null,
      country?: string | null,
      exchangeRate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetSFAccountQueryVariables = {
  id: string,
};

export type GetSFAccountQuery = {
  getSFAccount?:  {
    __typename: "SFAccount",
    id: string,
    name?: string | null,
    accountName?: string | null,
    countryId?: string | null,
    parentAccountId?: string | null,
    emailDomain?: string | null,
    industry?: string | null,
    organizationId?: string | null,
    billingAddress?: string | null,
    shippingAddress?: string | null,
    copyBillingAddressToShippingAddress?: boolean | null,
    type?: string | null,
    phone?: string | null,
    website?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    parentAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProducts?:  {
      __typename: "ModelSFProductConnection",
      items:  Array< {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFAccountsQueryVariables = {
  filter?: ModelSFAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFAccountsQuery = {
  listSFAccounts?:  {
    __typename: "ModelSFAccountConnection",
    items:  Array< {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFAccountsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFAccountsByTenantIdQuery = {
  bySFAccountsByTenantId?:  {
    __typename: "ModelSFAccountConnection",
    items:  Array< {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFAccountsByCountryIdQueryVariables = {
  countryId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFAccountsByCountryIdQuery = {
  bySFAccountsByCountryId?:  {
    __typename: "ModelSFAccountConnection",
    items:  Array< {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFContactQueryVariables = {
  id: string,
};

export type GetSFContactQuery = {
  getSFContact?:  {
    __typename: "SFContact",
    id: string,
    name?: string | null,
    phone?: string | null,
    accountId?: string | null,
    email?: string | null,
    title?: string | null,
    role?: string | null,
    countryId?: string | null,
    superVisorId?: string | null,
    region?: string | null,
    mailingAddress?: string | null,
    otherAddress?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    superVisor?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFContactsQueryVariables = {
  filter?: ModelSFContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFContactsQuery = {
  listSFContacts?:  {
    __typename: "ModelSFContactConnection",
    items:  Array< {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFContactsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFContactsByTenantIdQuery = {
  bySFContactsByTenantId?:  {
    __typename: "ModelSFContactConnection",
    items:  Array< {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFContactsByCountryIdQueryVariables = {
  countryId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFContactsByCountryIdQuery = {
  bySFContactsByCountryId?:  {
    __typename: "ModelSFContactConnection",
    items:  Array< {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFContractQueryVariables = {
  id: string,
};

export type GetSFContractQuery = {
  getSFContract?:  {
    __typename: "SFContract",
    id: string,
    contractNumber?: string | null,
    accountId?: string | null,
    contractTerm?: string | null,
    status?: string | null,
    ownerExpirationNotice?: string | null,
    totalContractValue?: string | null,
    contractType?: string | null,
    poNumber?: string | null,
    poDate?: string | null,
    referenceNumber?: string | null,
    partnerAccountId?: string | null,
    distributorAccountId?: string | null,
    paymentTerms?: string | null,
    poIssuedById?: string | null,
    contactId?: string | null,
    salesRepresentativeNameId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    customerAddressId?: string | null,
    designationId?: string | null,
    emailId?: string | null,
    mobileId?: string | null,
    year1MaintenanceEndDate?: string | null,
    year1MaintenanceStartDate?: string | null,
    year2MaintenanceEndDate?: string | null,
    year2MaintenanceStartDate?: string | null,
    year3MaintenanceEndDate?: string | null,
    year3MaintenanceStartDate?: string | null,
    value?: string | null,
    billingAddress?: string | null,
    description?: string | null,
    specialTerms?: string | null,
    signedById?: string | null,
    companySignedId?: string | null,
    activatedById?: string | null,
    activatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    invoiceDate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    companySigned?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    activatedBy?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    partnerAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    distributorAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    poIssuedBy?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    customerAddress?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    contact?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    salesRepresentativeName?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    designation?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    email?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    mobile?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    signedBy?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFContractsQueryVariables = {
  filter?: ModelSFContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFContractsQuery = {
  listSFContracts?:  {
    __typename: "ModelSFContractConnection",
    items:  Array< {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFContractsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFContractsByTenantIdQuery = {
  bySFContractsByTenantId?:  {
    __typename: "ModelSFContractConnection",
    items:  Array< {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFContractLineItemQueryVariables = {
  id: string,
};

export type GetSFContractLineItemQuery = {
  getSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
    contractName?: string | null,
    itemNo?: string | null,
    productId?: string | null,
    quantity?: string | null,
    contractId?: string | null,
    productListPrice?: string | null,
    discount?: string | null,
    amount?: string | null,
    version?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contract?:  {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    product?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFContractLineItemsQueryVariables = {
  filter?: ModelSFContractLineItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFContractLineItemsQuery = {
  listSFContractLineItems?:  {
    __typename: "ModelSFContractLineItemConnection",
    items:  Array< {
      __typename: "SFContractLineItem",
      id: string,
      contractName?: string | null,
      itemNo?: string | null,
      productId?: string | null,
      quantity?: string | null,
      contractId?: string | null,
      productListPrice?: string | null,
      discount?: string | null,
      amount?: string | null,
      version?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contract?:  {
        __typename: "SFContract",
        id: string,
        contractNumber?: string | null,
        accountId?: string | null,
        contractTerm?: string | null,
        status?: string | null,
        ownerExpirationNotice?: string | null,
        totalContractValue?: string | null,
        contractType?: string | null,
        poNumber?: string | null,
        poDate?: string | null,
        referenceNumber?: string | null,
        partnerAccountId?: string | null,
        distributorAccountId?: string | null,
        paymentTerms?: string | null,
        poIssuedById?: string | null,
        contactId?: string | null,
        salesRepresentativeNameId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        customerAddressId?: string | null,
        designationId?: string | null,
        emailId?: string | null,
        mobileId?: string | null,
        year1MaintenanceEndDate?: string | null,
        year1MaintenanceStartDate?: string | null,
        year2MaintenanceEndDate?: string | null,
        year2MaintenanceStartDate?: string | null,
        year3MaintenanceEndDate?: string | null,
        year3MaintenanceStartDate?: string | null,
        value?: string | null,
        billingAddress?: string | null,
        description?: string | null,
        specialTerms?: string | null,
        signedById?: string | null,
        companySignedId?: string | null,
        activatedById?: string | null,
        activatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        invoiceDate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        companySigned?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        activatedBy?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        partnerAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        distributorAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        poIssuedBy?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerAddress?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        contact?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        salesRepresentativeName?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        designation?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        email?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        mobile?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        signedBy?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      product?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFContractLineItemByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFContractLineItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFContractLineItemByTenantIdQuery = {
  bySFContractLineItemByTenantId?:  {
    __typename: "ModelSFContractLineItemConnection",
    items:  Array< {
      __typename: "SFContractLineItem",
      id: string,
      contractName?: string | null,
      itemNo?: string | null,
      productId?: string | null,
      quantity?: string | null,
      contractId?: string | null,
      productListPrice?: string | null,
      discount?: string | null,
      amount?: string | null,
      version?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contract?:  {
        __typename: "SFContract",
        id: string,
        contractNumber?: string | null,
        accountId?: string | null,
        contractTerm?: string | null,
        status?: string | null,
        ownerExpirationNotice?: string | null,
        totalContractValue?: string | null,
        contractType?: string | null,
        poNumber?: string | null,
        poDate?: string | null,
        referenceNumber?: string | null,
        partnerAccountId?: string | null,
        distributorAccountId?: string | null,
        paymentTerms?: string | null,
        poIssuedById?: string | null,
        contactId?: string | null,
        salesRepresentativeNameId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        customerAddressId?: string | null,
        designationId?: string | null,
        emailId?: string | null,
        mobileId?: string | null,
        year1MaintenanceEndDate?: string | null,
        year1MaintenanceStartDate?: string | null,
        year2MaintenanceEndDate?: string | null,
        year2MaintenanceStartDate?: string | null,
        year3MaintenanceEndDate?: string | null,
        year3MaintenanceStartDate?: string | null,
        value?: string | null,
        billingAddress?: string | null,
        description?: string | null,
        specialTerms?: string | null,
        signedById?: string | null,
        companySignedId?: string | null,
        activatedById?: string | null,
        activatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        invoiceDate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        companySigned?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        activatedBy?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        partnerAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        distributorAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        poIssuedBy?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerAddress?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        contact?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        salesRepresentativeName?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        designation?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        email?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        mobile?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        signedBy?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      product?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFProductQueryVariables = {
  id: string,
};

export type GetSFProductQuery = {
  getSFProduct?:  {
    __typename: "SFProduct",
    id: string,
    productName?: string | null,
    productVersion?: string | null,
    productMetric?: string | null,
    productDescription?: string | null,
    sfAccountId?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    productCode?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProductSubCategories?:  {
      __typename: "ModelSFProductSubCategoryConnection",
      items:  Array< {
        __typename: "SFProductSubCategory",
        id: string,
        name?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    sfProductVersions?:  {
      __typename: "ModelSFProductVersionConnection",
      items:  Array< {
        __typename: "SFProductVersion",
        id: string,
        name?: string | null,
        version?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSFProductsQueryVariables = {
  filter?: ModelSFProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFProductsQuery = {
  listSFProducts?:  {
    __typename: "ModelSFProductConnection",
    items:  Array< {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFProductsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFProductsByTenantIdQuery = {
  bySFProductsByTenantId?:  {
    __typename: "ModelSFProductConnection",
    items:  Array< {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFProductSubCategoryQueryVariables = {
  id: string,
};

export type GetSFProductSubCategoryQuery = {
  getSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
    name?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFProductSubCategoriesQueryVariables = {
  filter?: ModelSFProductSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFProductSubCategoriesQuery = {
  listSFProductSubCategories?:  {
    __typename: "ModelSFProductSubCategoryConnection",
    items:  Array< {
      __typename: "SFProductSubCategory",
      id: string,
      name?: string | null,
      sfProductId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFProductSubCategoriesByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFProductSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFProductSubCategoriesByTenantIdQuery = {
  bySFProductSubCategoriesByTenantId?:  {
    __typename: "ModelSFProductSubCategoryConnection",
    items:  Array< {
      __typename: "SFProductSubCategory",
      id: string,
      name?: string | null,
      sfProductId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFProductVersionQueryVariables = {
  id: string,
};

export type GetSFProductVersionQuery = {
  getSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
    name?: string | null,
    version?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFProductVersionsQueryVariables = {
  filter?: ModelSFProductVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFProductVersionsQuery = {
  listSFProductVersions?:  {
    __typename: "ModelSFProductVersionConnection",
    items:  Array< {
      __typename: "SFProductVersion",
      id: string,
      name?: string | null,
      version?: string | null,
      sfProductId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFProductVersionsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFProductVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFProductVersionsByTenantIdQuery = {
  bySFProductVersionsByTenantId?:  {
    __typename: "ModelSFProductVersionConnection",
    items:  Array< {
      __typename: "SFProductVersion",
      id: string,
      name?: string | null,
      version?: string | null,
      sfProductId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFPriceBookQueryVariables = {
  id: string,
};

export type GetSFPriceBookQuery = {
  getSFPriceBook?:  {
    __typename: "SFPriceBook",
    id: string,
    priceBookName?: string | null,
    active?: boolean | null,
    description?: string | null,
    isStandardPriceBook?: boolean | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFPriceBooksQueryVariables = {
  filter?: ModelSFPriceBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFPriceBooksQuery = {
  listSFPriceBooks?:  {
    __typename: "ModelSFPriceBookConnection",
    items:  Array< {
      __typename: "SFPriceBook",
      id: string,
      priceBookName?: string | null,
      active?: boolean | null,
      description?: string | null,
      isStandardPriceBook?: boolean | null,
      sfProductId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFPriceBooksByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFPriceBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFPriceBooksByTenantIdQuery = {
  bySFPriceBooksByTenantId?:  {
    __typename: "ModelSFPriceBookConnection",
    items:  Array< {
      __typename: "SFPriceBook",
      id: string,
      priceBookName?: string | null,
      active?: boolean | null,
      description?: string | null,
      isStandardPriceBook?: boolean | null,
      sfProductId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfProduct?:  {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFIssueCategoryQueryVariables = {
  id: string,
};

export type GetSFIssueCategoryQuery = {
  getSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFIssueCategoriesQueryVariables = {
  filter?: ModelSFIssueCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFIssueCategoriesQuery = {
  listSFIssueCategories?:  {
    __typename: "ModelSFIssueCategoryConnection",
    items:  Array< {
      __typename: "SFIssueCategory",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFIssueCategoriesByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFIssueCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFIssueCategoriesByTenantIdQuery = {
  bySFIssueCategoriesByTenantId?:  {
    __typename: "ModelSFIssueCategoryConnection",
    items:  Array< {
      __typename: "SFIssueCategory",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFCaseSeverityQueryVariables = {
  id: string,
};

export type GetSFCaseSeverityQuery = {
  getSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFCaseSeveritiesQueryVariables = {
  filter?: ModelSFCaseSeverityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFCaseSeveritiesQuery = {
  listSFCaseSeverities?:  {
    __typename: "ModelSFCaseSeverityConnection",
    items:  Array< {
      __typename: "SFCaseSeverity",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFCaseSeveritiesByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFCaseSeverityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFCaseSeveritiesByTenantIdQuery = {
  bySFCaseSeveritiesByTenantId?:  {
    __typename: "ModelSFCaseSeverityConnection",
    items:  Array< {
      __typename: "SFCaseSeverity",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFClosureReasonQueryVariables = {
  id: string,
};

export type GetSFClosureReasonQuery = {
  getSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFClosureReasonsQueryVariables = {
  filter?: ModelSFClosureReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFClosureReasonsQuery = {
  listSFClosureReasons?:  {
    __typename: "ModelSFClosureReasonConnection",
    items:  Array< {
      __typename: "SFClosureReason",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFClosureReasonsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFClosureReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFClosureReasonsByTenantIdQuery = {
  bySFClosureReasonsByTenantId?:  {
    __typename: "ModelSFClosureReasonConnection",
    items:  Array< {
      __typename: "SFClosureReason",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFRoleQueryVariables = {
  id: string,
};

export type GetSFRoleQuery = {
  getSFRole?:  {
    __typename: "SFRole",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFRolesQueryVariables = {
  filter?: ModelSFRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFRolesQuery = {
  listSFRoles?:  {
    __typename: "ModelSFRoleConnection",
    items:  Array< {
      __typename: "SFRole",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFRolesByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFRolesByTenantIdQuery = {
  bySFRolesByTenantId?:  {
    __typename: "ModelSFRoleConnection",
    items:  Array< {
      __typename: "SFRole",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFIndustryQueryVariables = {
  id: string,
};

export type GetSFIndustryQuery = {
  getSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFIndustriesQueryVariables = {
  filter?: ModelSFIndustryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFIndustriesQuery = {
  listSFIndustries?:  {
    __typename: "ModelSFIndustryConnection",
    items:  Array< {
      __typename: "SFIndustry",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFIndustriesByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFIndustryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFIndustriesByTenantIdQuery = {
  bySFIndustriesByTenantId?:  {
    __typename: "ModelSFIndustryConnection",
    items:  Array< {
      __typename: "SFIndustry",
      id: string,
      label?: string | null,
      value?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFSolutionQueryVariables = {
  id: string,
};

export type GetSFSolutionQuery = {
  getSFSolution?:  {
    __typename: "SFSolution",
    id: string,
    name?: string | null,
    leadId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    competitors?:  {
      __typename: "ModelSFCompetitorConnection",
      items:  Array< {
        __typename: "SFCompetitor",
        id: string,
        name?: string | null,
        domain?: string | null,
        competitors?: string | null,
        solutionId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solution?:  {
          __typename: "SFSolution",
          id: string,
          name?: string | null,
          leadId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSFSolutionsQueryVariables = {
  filter?: ModelSFSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFSolutionsQuery = {
  listSFSolutions?:  {
    __typename: "ModelSFSolutionConnection",
    items:  Array< {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFSolutionsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFSolutionsByTenantIdQuery = {
  bySFSolutionsByTenantId?:  {
    __typename: "ModelSFSolutionConnection",
    items:  Array< {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFCompetitorQueryVariables = {
  id: string,
};

export type GetSFCompetitorQuery = {
  getSFCompetitor?:  {
    __typename: "SFCompetitor",
    id: string,
    name?: string | null,
    domain?: string | null,
    competitors?: string | null,
    solutionId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    solution?:  {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSFCompetitorsQueryVariables = {
  filter?: ModelSFCompetitorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFCompetitorsQuery = {
  listSFCompetitors?:  {
    __typename: "ModelSFCompetitorConnection",
    items:  Array< {
      __typename: "SFCompetitor",
      id: string,
      name?: string | null,
      domain?: string | null,
      competitors?: string | null,
      solutionId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      solution?:  {
        __typename: "SFSolution",
        id: string,
        name?: string | null,
        leadId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitors?:  {
          __typename: "ModelSFCompetitorConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFCompetitorsByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFCompetitorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFCompetitorsByTenantIdQuery = {
  bySFCompetitorsByTenantId?:  {
    __typename: "ModelSFCompetitorConnection",
    items:  Array< {
      __typename: "SFCompetitor",
      id: string,
      name?: string | null,
      domain?: string | null,
      competitors?: string | null,
      solutionId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      solution?:  {
        __typename: "SFSolution",
        id: string,
        name?: string | null,
        leadId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        competitors?:  {
          __typename: "ModelSFCompetitorConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSFCountryQueryVariables = {
  id: string,
};

export type GetSFCountryQuery = {
  getSFCountry?:  {
    __typename: "SFCountry",
    id: string,
    name?: string | null,
    countryId?: string | null,
    region?: string | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    accounts?:  {
      __typename: "ModelSFAccountConnection",
      items:  Array< {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelSFContactConnection",
      items:  Array< {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSFCountriesQueryVariables = {
  filter?: ModelSFCountryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFCountriesQuery = {
  listSFCountries?:  {
    __typename: "ModelSFCountryConnection",
    items:  Array< {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BySFCountriesByTenantIdQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFCountryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySFCountriesByTenantIdQuery = {
  bySFCountriesByTenantId?:  {
    __typename: "ModelSFCountryConnection",
    items:  Array< {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateTenantSubscriptionVariables = {
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    domain?: string | null,
    organizationId?: string | null,
    tenantCustomizationId?: string | null,
    sidebarLinkId?: string | null,
    usedSpaceByDoc?: string | null,
    totalSpaceForDoc?: string | null,
    createdAt: string,
    updatedAt: string,
    sidebarLink?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tenantCustomization?:  {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    fileLists?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currencies?:  {
      __typename: "ModelCurrencyConnection",
      items:  Array< {
        __typename: "Currency",
        id: string,
        name?: string | null,
        symbol?: string | null,
        isDefaultCurrency?: boolean | null,
        code?: string | null,
        country?: string | null,
        exchangeRate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTenantSubscriptionVariables = {
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    domain?: string | null,
    organizationId?: string | null,
    tenantCustomizationId?: string | null,
    sidebarLinkId?: string | null,
    usedSpaceByDoc?: string | null,
    totalSpaceForDoc?: string | null,
    createdAt: string,
    updatedAt: string,
    sidebarLink?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tenantCustomization?:  {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    fileLists?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currencies?:  {
      __typename: "ModelCurrencyConnection",
      items:  Array< {
        __typename: "Currency",
        id: string,
        name?: string | null,
        symbol?: string | null,
        isDefaultCurrency?: boolean | null,
        code?: string | null,
        country?: string | null,
        exchangeRate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTenantSubscriptionVariables = {
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    subscribedServices?: string | null,
    domain?: string | null,
    organizationId?: string | null,
    tenantCustomizationId?: string | null,
    sidebarLinkId?: string | null,
    usedSpaceByDoc?: string | null,
    totalSpaceForDoc?: string | null,
    createdAt: string,
    updatedAt: string,
    sidebarLink?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tenantCustomization?:  {
      __typename: "TenantCustomization",
      id: string,
      color?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    applications?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        description?: string | null,
        status?: ActiveStatusType | null,
        organizationId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        users?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    fileLists?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currencies?:  {
      __typename: "ModelCurrencyConnection",
      items:  Array< {
        __typename: "Currency",
        id: string,
        name?: string | null,
        symbol?: string | null,
        isDefaultCurrency?: boolean | null,
        code?: string | null,
        country?: string | null,
        exchangeRate?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateSidebarLinkSubscriptionVariables = {
};

export type OnCreateSidebarLinkSubscription = {
  onCreateSidebarLink?:  {
    __typename: "SidebarLink",
    id: string,
    name?: string | null,
    url?: string | null,
    parentLinkId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    parentLink?:  {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    subLinks?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSidebarLinkSubscriptionVariables = {
};

export type OnUpdateSidebarLinkSubscription = {
  onUpdateSidebarLink?:  {
    __typename: "SidebarLink",
    id: string,
    name?: string | null,
    url?: string | null,
    parentLinkId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    parentLink?:  {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    subLinks?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSidebarLinkSubscriptionVariables = {
};

export type OnDeleteSidebarLinkSubscription = {
  onDeleteSidebarLink?:  {
    __typename: "SidebarLink",
    id: string,
    name?: string | null,
    url?: string | null,
    parentLinkId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    parentLink?:  {
      __typename: "SidebarLink",
      id: string,
      name?: string | null,
      url?: string | null,
      parentLinkId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      },
      parentLink?:  {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      subLinks?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    subLinks?:  {
      __typename: "ModelSidebarLinkConnection",
      items:  Array< {
        __typename: "SidebarLink",
        id: string,
        name?: string | null,
        url?: string | null,
        parentLinkId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        parentLink?:  {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        subLinks?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateTenantCustomizationSubscriptionVariables = {
};

export type OnCreateTenantCustomizationSubscription = {
  onCreateTenantCustomization?:  {
    __typename: "TenantCustomization",
    id: string,
    color?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTenantCustomizationSubscriptionVariables = {
};

export type OnUpdateTenantCustomizationSubscription = {
  onUpdateTenantCustomization?:  {
    __typename: "TenantCustomization",
    id: string,
    color?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTenantCustomizationSubscriptionVariables = {
};

export type OnDeleteTenantCustomizationSubscription = {
  onDeleteTenantCustomization?:  {
    __typename: "TenantCustomization",
    id: string,
    color?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateApplicationSubscriptionVariables = {
};

export type OnCreateApplicationSubscription = {
  onCreateApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    description?: string | null,
    defaultRoles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    tenants?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateApplicationSubscriptionVariables = {
};

export type OnUpdateApplicationSubscription = {
  onUpdateApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    description?: string | null,
    defaultRoles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    tenants?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteApplicationSubscriptionVariables = {
};

export type OnDeleteApplicationSubscription = {
  onDeleteApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    description?: string | null,
    defaultRoles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    tenants?:  {
      __typename: "ModelTenantApplicationConnection",
      items:  Array< {
        __typename: "TenantApplication",
        id: string,
        tenantId: string,
        applicationId: string,
        createdAt: string,
        updatedAt: string,
        tenants:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        applications:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    productDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateTenantApplicationSubscriptionVariables = {
};

export type OnCreateTenantApplicationSubscription = {
  onCreateTenantApplication?:  {
    __typename: "TenantApplication",
    id: string,
    tenantId: string,
    applicationId: string,
    createdAt: string,
    updatedAt: string,
    tenants:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    applications:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateTenantApplicationSubscriptionVariables = {
};

export type OnUpdateTenantApplicationSubscription = {
  onUpdateTenantApplication?:  {
    __typename: "TenantApplication",
    id: string,
    tenantId: string,
    applicationId: string,
    createdAt: string,
    updatedAt: string,
    tenants:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    applications:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteTenantApplicationSubscriptionVariables = {
};

export type OnDeleteTenantApplicationSubscription = {
  onDeleteTenantApplication?:  {
    __typename: "TenantApplication",
    id: string,
    tenantId: string,
    applicationId: string,
    createdAt: string,
    updatedAt: string,
    tenants:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    applications:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateProductDocSubscriptionVariables = {
};

export type OnCreateProductDocSubscription = {
  onCreateProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    applicationId: string,
    orderFloat?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateProductDocSubscriptionVariables = {
};

export type OnUpdateProductDocSubscription = {
  onUpdateProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    applicationId: string,
    orderFloat?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteProductDocSubscriptionVariables = {
};

export type OnDeleteProductDocSubscription = {
  onDeleteProductDoc?:  {
    __typename: "ProductDoc",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    applicationId: string,
    orderFloat?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateChapterSubscriptionVariables = {
};

export type OnCreateChapterSubscription = {
  onCreateChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    parentChapterId?: string | null,
    shouldBeDeleted?: boolean | null,
    currentTaskId?: string | null,
    isRejected?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateChapterSubscriptionVariables = {
};

export type OnUpdateChapterSubscription = {
  onUpdateChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    parentChapterId?: string | null,
    shouldBeDeleted?: boolean | null,
    currentTaskId?: string | null,
    isRejected?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteChapterSubscriptionVariables = {
};

export type OnDeleteChapterSubscription = {
  onDeleteChapter?:  {
    __typename: "Chapter",
    id: string,
    version: string,
    status: string,
    name: string,
    description?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    parentProductDocId?: string | null,
    parentChapterId?: string | null,
    shouldBeDeleted?: boolean | null,
    currentTaskId?: string | null,
    isRejected?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parentChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentTask?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateDocSubscriptionVariables = {
};

export type OnCreateDocSubscription = {
  onCreateDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    createdByUserId: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    referenceDocId?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateDocSubscriptionVariables = {
};

export type OnUpdateDocSubscription = {
  onUpdateDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    createdByUserId: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    referenceDocId?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteDocSubscriptionVariables = {
};

export type OnDeleteDocSubscription = {
  onDeleteDoc?:  {
    __typename: "Doc",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    createdByUserId: string,
    pdfFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    isPdfBeingGenerated?: boolean | null,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    chapterId: string,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    referenceDocId?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    docItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateDocItemSubscriptionVariables = {
};

export type OnCreateDocItemSubscription = {
  onCreateDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    itemType: DocItemType,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    docId: string,
    createdAt?: string | null,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    doc?:  {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateDocItemSubscriptionVariables = {
};

export type OnUpdateDocItemSubscription = {
  onUpdateDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    itemType: DocItemType,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    docId: string,
    createdAt?: string | null,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    doc?:  {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteDocItemSubscriptionVariables = {
};

export type OnDeleteDocItemSubscription = {
  onDeleteDocItem?:  {
    __typename: "DocItem",
    id: string,
    version: string,
    status: string,
    title: string,
    description?: string | null,
    itemType: DocItemType,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    createdByUserId: string,
    lastModifiedByUserId: string,
    approvedByUserId?: string | null,
    rootChapterId: string,
    docId: string,
    createdAt?: string | null,
    shouldBeDeleted?: boolean | null,
    orderFloat?: string | null,
    tenantId: string,
    isArchivedByOrphanRemovalScript?: boolean | null,
    archivedByOrphanRemovalScript?: string | null,
    archivedByIrrelevantContentRemovalScript?: string | null,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    doc?:  {
      __typename: "Doc",
      id: string,
      version: string,
      status: string,
      title: string,
      description?: string | null,
      createdByUserId: string,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      chapterId: string,
      shouldBeDeleted?: boolean | null,
      orderFloat?: string | null,
      referenceDocId?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    parentDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    email: string,
    organizationId?: string | null,
    salesForceId?: string | null,
    role?: RoleType | null,
    secondaryEmail: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: ActiveStatusType | null,
    isMigrated?: boolean | null,
    isTenantAdmin?: boolean | null,
    ticketsToReviewOrder?: Array< string | null > | null,
    supportPortalDashBoardChartsOrder?: Array< number | null > | null,
    tenantId: string,
    preferredCurrency?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewedTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingTickets?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    qualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    disqualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    rejectedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingLeads?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    email: string,
    organizationId?: string | null,
    salesForceId?: string | null,
    role?: RoleType | null,
    secondaryEmail: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: ActiveStatusType | null,
    isMigrated?: boolean | null,
    isTenantAdmin?: boolean | null,
    ticketsToReviewOrder?: Array< string | null > | null,
    supportPortalDashBoardChartsOrder?: Array< number | null > | null,
    tenantId: string,
    preferredCurrency?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewedTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingTickets?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    qualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    disqualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    rejectedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingLeads?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    displayName?: string | null,
    jobTitle?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    email: string,
    organizationId?: string | null,
    salesForceId?: string | null,
    role?: RoleType | null,
    secondaryEmail: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    status?: ActiveStatusType | null,
    isMigrated?: boolean | null,
    isTenantAdmin?: boolean | null,
    ticketsToReviewOrder?: Array< string | null > | null,
    supportPortalDashBoardChartsOrder?: Array< number | null > | null,
    tenantId: string,
    preferredCurrency?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedProductDocs?:  {
      __typename: "ModelProductDocConnection",
      items:  Array< {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedChapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocs?:  {
      __typename: "ModelDocConnection",
      items:  Array< {
        __typename: "Doc",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        createdByUserId: string,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        chapterId: string,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        referenceDocId?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedDocItems?:  {
      __typename: "ModelDocItemConnection",
      items:  Array< {
        __typename: "DocItem",
        id: string,
        version: string,
        status: string,
        title: string,
        description?: string | null,
        itemType: DocItemType,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        docId: string,
        createdAt?: string | null,
        shouldBeDeleted?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        doc?:  {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      domain?: string | null,
      accountId?: string | null,
      recordId?: string | null,
      type?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewedTasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingTickets?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    qualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    disqualifiedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    rejectedLeads?:  {
      __typename: "ModelLeadConnection",
      items:  Array< {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    watchingLeads?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    comment: string,
    commentedByUserId: string,
    parentCommentId?: string | null,
    taskId?: string | null,
    leadId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: boolean | null,
    type?: CommentType | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    commentedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentComment?:  {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lead?:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    comment: string,
    commentedByUserId: string,
    parentCommentId?: string | null,
    taskId?: string | null,
    leadId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: boolean | null,
    type?: CommentType | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    commentedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentComment?:  {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lead?:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    comment: string,
    commentedByUserId: string,
    parentCommentId?: string | null,
    taskId?: string | null,
    leadId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: boolean | null,
    type?: CommentType | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    commentedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    parentComment?:  {
      __typename: "Comment",
      id: string,
      comment: string,
      commentedByUserId: string,
      parentCommentId?: string | null,
      taskId?: string | null,
      leadId?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: boolean | null,
      type?: CommentType | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      commentedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentComment?:  {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      task?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lead?:  {
        __typename: "Lead",
        id: string,
        leadReferenceNumber?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        role?: string | null,
        jobTitle?: string | null,
        companyName?: string | null,
        companyAddress?: string | null,
        industry?: string | null,
        companyUrl?: string | null,
        leadName?: string | null,
        description?: string | null,
        timeline?: string | null,
        estimatedBudget?: string | null,
        estimatedNumberOfUsers?: number | null,
        solution?: string | null,
        incumbentProduct?: string | null,
        competitors?: string | null,
        submissionDate?: string | null,
        remarks?: string | null,
        createdByUserId: string,
        lastModifiedByUserId?: string | null,
        qualifiedByUserId?: string | null,
        disqualifiedByUserId?: string | null,
        approvedByUserId?: string | null,
        rejectedByUserId?: string | null,
        usersToNotify?: Array< string | null > | null,
        overallStatus?: LeadOverallStatusType | null,
        qualifiedStatus?: LeadQualifiedStatusType | null,
        approvedStatus?: LeadApprovedStatusType | null,
        qualifiedDate?: string | null,
        approvedDate?: string | null,
        qualifiedRemarks?: string | null,
        approvedRemarks?: string | null,
        salesForceLeadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        qualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        disqualifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        rejectedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        attchments?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        leadWatchers?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lead?:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateGroupSubscriptionVariables = {
};

export type OnCreateGroupSubscription = {
  onCreateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateGroupSubscriptionVariables = {
};

export type OnUpdateGroupSubscription = {
  onUpdateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteGroupSubscriptionVariables = {
};

export type OnDeleteGroupSubscription = {
  onDeleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    description?: string | null,
    status?: ActiveStatusType | null,
    organizationId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userId: string,
        groupId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    applicationRoles?:  {
      __typename: "ModelApplicationRoleConnection",
      items:  Array< {
        __typename: "ApplicationRole",
        id: string,
        roleId: string,
        applicationId: string,
        groupId: string,
        productDocs?: Array< string | null > | null,
        locations?: Array< string | null > | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assignedTickets?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserGroupSubscriptionVariables = {
};

export type OnCreateUserGroupSubscription = {
  onCreateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userId: string,
    groupId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateUserGroupSubscriptionVariables = {
};

export type OnUpdateUserGroupSubscription = {
  onUpdateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userId: string,
    groupId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteUserGroupSubscriptionVariables = {
};

export type OnDeleteUserGroupSubscription = {
  onDeleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userId: string,
    groupId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateTicketRuleSubscriptionVariables = {
};

export type OnCreateTicketRuleSubscription = {
  onCreateTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTicketRuleSubscriptionVariables = {
};

export type OnUpdateTicketRuleSubscription = {
  onUpdateTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTicketRuleSubscriptionVariables = {
};

export type OnDeleteTicketRuleSubscription = {
  onDeleteTicketRule?:  {
    __typename: "TicketRule",
    id: string,
    name: string,
    description?: string | null,
    status: string,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfCountries?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    sfAccounts?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelTicketRuleUserConnection",
      items:  Array< {
        __typename: "TicketRuleUser",
        id: string,
        ticketRuleId: string,
        userId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    groups?:  {
      __typename: "ModelTicketRuleGroupConnection",
      items:  Array< {
        __typename: "TicketRuleGroup",
        id: string,
        ticketRuleId: string,
        groupId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateTicketRuleSFCountrySubscriptionVariables = {
};

export type OnCreateTicketRuleSFCountrySubscription = {
  onCreateTicketRuleSFCountry?:  {
    __typename: "TicketRuleSFCountry",
    id: string,
    ticketRuleId: string,
    sfCountryId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfCountries:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateTicketRuleSFCountrySubscriptionVariables = {
};

export type OnUpdateTicketRuleSFCountrySubscription = {
  onUpdateTicketRuleSFCountry?:  {
    __typename: "TicketRuleSFCountry",
    id: string,
    ticketRuleId: string,
    sfCountryId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfCountries:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteTicketRuleSFCountrySubscriptionVariables = {
};

export type OnDeleteTicketRuleSFCountrySubscription = {
  onDeleteTicketRuleSFCountry?:  {
    __typename: "TicketRuleSFCountry",
    id: string,
    ticketRuleId: string,
    sfCountryId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfCountries:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateTicketRuleSFAccountSubscriptionVariables = {
};

export type OnCreateTicketRuleSFAccountSubscription = {
  onCreateTicketRuleSFAccount?:  {
    __typename: "TicketRuleSFAccount",
    id: string,
    ticketRuleId: string,
    sfAccountId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfAccounts:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type OnUpdateTicketRuleSFAccountSubscriptionVariables = {
};

export type OnUpdateTicketRuleSFAccountSubscription = {
  onUpdateTicketRuleSFAccount?:  {
    __typename: "TicketRuleSFAccount",
    id: string,
    ticketRuleId: string,
    sfAccountId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfAccounts:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type OnDeleteTicketRuleSFAccountSubscriptionVariables = {
};

export type OnDeleteTicketRuleSFAccountSubscription = {
  onDeleteTicketRuleSFAccount?:  {
    __typename: "TicketRuleSFAccount",
    id: string,
    ticketRuleId: string,
    sfAccountId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    sfAccounts:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type OnCreateTicketRuleUserSubscriptionVariables = {
};

export type OnCreateTicketRuleUserSubscription = {
  onCreateTicketRuleUser?:  {
    __typename: "TicketRuleUser",
    id: string,
    ticketRuleId: string,
    userId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateTicketRuleUserSubscriptionVariables = {
};

export type OnUpdateTicketRuleUserSubscription = {
  onUpdateTicketRuleUser?:  {
    __typename: "TicketRuleUser",
    id: string,
    ticketRuleId: string,
    userId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteTicketRuleUserSubscriptionVariables = {
};

export type OnDeleteTicketRuleUserSubscription = {
  onDeleteTicketRuleUser?:  {
    __typename: "TicketRuleUser",
    id: string,
    ticketRuleId: string,
    userId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateTicketRuleGroupSubscriptionVariables = {
};

export type OnCreateTicketRuleGroupSubscription = {
  onCreateTicketRuleGroup?:  {
    __typename: "TicketRuleGroup",
    id: string,
    ticketRuleId: string,
    groupId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateTicketRuleGroupSubscriptionVariables = {
};

export type OnUpdateTicketRuleGroupSubscription = {
  onUpdateTicketRuleGroup?:  {
    __typename: "TicketRuleGroup",
    id: string,
    ticketRuleId: string,
    groupId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteTicketRuleGroupSubscriptionVariables = {
};

export type OnDeleteTicketRuleGroupSubscription = {
  onDeleteTicketRuleGroup?:  {
    __typename: "TicketRuleGroup",
    id: string,
    ticketRuleId: string,
    groupId: string,
    status: TicketRuleJoinTableStatusType,
    createdByUserId?: string | null,
    lastModifiedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    lastModifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    ticketRules:  {
      __typename: "TicketRule",
      id: string,
      name: string,
      description?: string | null,
      status: string,
      createdByUserId?: string | null,
      lastModifiedByUserId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      lastModifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfCountries?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfAccounts?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateApplicationRoleSubscriptionVariables = {
};

export type OnCreateApplicationRoleSubscription = {
  onCreateApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
    roleId: string,
    applicationId: string,
    groupId: string,
    productDocs?: Array< string | null > | null,
    locations?: Array< string | null > | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateApplicationRoleSubscriptionVariables = {
};

export type OnUpdateApplicationRoleSubscription = {
  onUpdateApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
    roleId: string,
    applicationId: string,
    groupId: string,
    productDocs?: Array< string | null > | null,
    locations?: Array< string | null > | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteApplicationRoleSubscriptionVariables = {
};

export type OnDeleteApplicationRoleSubscription = {
  onDeleteApplicationRole?:  {
    __typename: "ApplicationRole",
    id: string,
    roleId: string,
    applicationId: string,
    groupId: string,
    productDocs?: Array< string | null > | null,
    locations?: Array< string | null > | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    application?:  {
      __typename: "Application",
      id: string,
      name: string,
      description?: string | null,
      defaultRoles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      tenants?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    domain?: string | null,
    accountId?: string | null,
    recordId?: string | null,
    type?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    domain?: string | null,
    accountId?: string | null,
    recordId?: string | null,
    type?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    domain?: string | null,
    accountId?: string | null,
    recordId?: string | null,
    type?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateFileListSubscriptionVariables = {
};

export type OnCreateFileListSubscription = {
  onCreateFileList?:  {
    __typename: "FileList",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    taskId?: string | null,
    leadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateFileListSubscriptionVariables = {
};

export type OnUpdateFileListSubscription = {
  onUpdateFileList?:  {
    __typename: "FileList",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    taskId?: string | null,
    leadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteFileListSubscriptionVariables = {
};

export type OnDeleteFileListSubscription = {
  onDeleteFileList?:  {
    __typename: "FileList",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    taskId?: string | null,
    leadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateTaskFeedSubscriptionVariables = {
};

export type OnCreateTaskFeedSubscription = {
  onCreateTaskFeed?:  {
    __typename: "TaskFeed",
    id: string,
    tenantId: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateTaskFeedSubscriptionVariables = {
};

export type OnUpdateTaskFeedSubscription = {
  onUpdateTaskFeed?:  {
    __typename: "TaskFeed",
    id: string,
    tenantId: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteTaskFeedSubscriptionVariables = {
};

export type OnDeleteTaskFeedSubscription = {
  onDeleteTaskFeed?:  {
    __typename: "TaskFeed",
    id: string,
    tenantId: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateTaskSubscriptionVariables = {
};

export type OnCreateTaskSubscription = {
  onCreateTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestedByUserId: string,
    reviewedByUserId?: string | null,
    status: TaskStatusType,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    linkedTickets?: Array< string | null > | null,
    salesForceTicketId?: string | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: string | null,
    isSupportEngineerIdentityHidden?: boolean | null,
    tenantId: string,
    issueType?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rootChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTaskSubscriptionVariables = {
};

export type OnUpdateTaskSubscription = {
  onUpdateTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestedByUserId: string,
    reviewedByUserId?: string | null,
    status: TaskStatusType,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    linkedTickets?: Array< string | null > | null,
    salesForceTicketId?: string | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: string | null,
    isSupportEngineerIdentityHidden?: boolean | null,
    tenantId: string,
    issueType?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rootChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTaskSubscriptionVariables = {
};

export type OnDeleteTaskSubscription = {
  onDeleteTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    description?: string | null,
    requestedByUserId: string,
    reviewedByUserId?: string | null,
    status: TaskStatusType,
    rootChapterId?: string | null,
    organizationId?: string | null,
    ticketRef?: string | null,
    severity?: string | null,
    severityUpdatedAt?: string | null,
    issueCategory?: string | null,
    subCategory?: string | null,
    productVersion?: string | null,
    closureReason?: string | null,
    type?: string | null,
    rootChapterVersion?: string | null,
    productDocId?: string | null,
    isRejected?: boolean | null,
    usersToNotify?: Array< string | null > | null,
    linkedTickets?: Array< string | null > | null,
    salesForceTicketId?: string | null,
    hasSensitiveInfo?: boolean | null,
    requestForInformation?: string | null,
    isSupportEngineerIdentityHidden?: boolean | null,
    tenantId: string,
    issueType?: string | null,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productDoc?:  {
      __typename: "ProductDoc",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      applicationId: string,
      orderFloat?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      application?:  {
        __typename: "Application",
        id: string,
        name: string,
        description?: string | null,
        defaultRoles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        tenants?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rootChapter?:  {
      __typename: "Chapter",
      id: string,
      version: string,
      status: string,
      name: string,
      description?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      pdfFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      isPdfBeingGenerated?: boolean | null,
      createdByUserId: string,
      lastModifiedByUserId: string,
      approvedByUserId?: string | null,
      rootChapterId: string,
      parentProductDocId?: string | null,
      parentChapterId?: string | null,
      shouldBeDeleted?: boolean | null,
      currentTaskId?: string | null,
      isRejected?: boolean | null,
      orderFloat?: string | null,
      tenantId: string,
      isArchivedByOrphanRemovalScript?: boolean | null,
      archivedByOrphanRemovalScript?: string | null,
      archivedByIrrelevantContentRemovalScript?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      parentProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      parentChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentTask?:  {
        __typename: "Task",
        id: string,
        name: string,
        description?: string | null,
        requestedByUserId: string,
        reviewedByUserId?: string | null,
        status: TaskStatusType,
        rootChapterId?: string | null,
        organizationId?: string | null,
        ticketRef?: string | null,
        severity?: string | null,
        severityUpdatedAt?: string | null,
        issueCategory?: string | null,
        subCategory?: string | null,
        productVersion?: string | null,
        closureReason?: string | null,
        type?: string | null,
        rootChapterVersion?: string | null,
        productDocId?: string | null,
        isRejected?: boolean | null,
        usersToNotify?: Array< string | null > | null,
        linkedTickets?: Array< string | null > | null,
        salesForceTicketId?: string | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: string | null,
        isSupportEngineerIdentityHidden?: boolean | null,
        tenantId: string,
        issueType?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        productDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        rootChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        requestedByUser:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        reviewedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        file?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        ticketWatchers?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedUsers?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        ticketAssignedGroups?:  {
          __typename: "ModelGroupTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    requestedByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reviewedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketWatchers?:  {
      __typename: "ModelWatcherTaskConnection",
      items:  Array< {
        __typename: "WatcherTask",
        id: string,
        userId: string,
        taskId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedUsers?:  {
      __typename: "ModelUserTaskConnection",
      items:  Array< {
        __typename: "UserTask",
        id: string,
        userId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketAssignedGroups?:  {
      __typename: "ModelGroupTaskConnection",
      items:  Array< {
        __typename: "GroupTask",
        id: string,
        groupId: string,
        taskId: string,
        status: TaskUserGroupStatusType,
        createdByUserId?: string | null,
        updatedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        updatedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        groups:  {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        tasks:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateWatcherTaskSubscriptionVariables = {
};

export type OnCreateWatcherTaskSubscription = {
  onCreateWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateWatcherTaskSubscriptionVariables = {
};

export type OnUpdateWatcherTaskSubscription = {
  onUpdateWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteWatcherTaskSubscriptionVariables = {
};

export type OnDeleteWatcherTaskSubscription = {
  onDeleteWatcherTask?:  {
    __typename: "WatcherTask",
    id: string,
    userId: string,
    taskId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateUserTaskSubscriptionVariables = {
};

export type OnCreateUserTaskSubscription = {
  onCreateUserTask?:  {
    __typename: "UserTask",
    id: string,
    userId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateUserTaskSubscriptionVariables = {
};

export type OnUpdateUserTaskSubscription = {
  onUpdateUserTask?:  {
    __typename: "UserTask",
    id: string,
    userId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteUserTaskSubscriptionVariables = {
};

export type OnDeleteUserTaskSubscription = {
  onDeleteUserTask?:  {
    __typename: "UserTask",
    id: string,
    userId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateGroupTaskSubscriptionVariables = {
};

export type OnCreateGroupTaskSubscription = {
  onCreateGroupTask?:  {
    __typename: "GroupTask",
    id: string,
    groupId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateGroupTaskSubscriptionVariables = {
};

export type OnUpdateGroupTaskSubscription = {
  onUpdateGroupTask?:  {
    __typename: "GroupTask",
    id: string,
    groupId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteGroupTaskSubscriptionVariables = {
};

export type OnDeleteGroupTaskSubscription = {
  onDeleteGroupTask?:  {
    __typename: "GroupTask",
    id: string,
    groupId: string,
    taskId: string,
    status: TaskUserGroupStatusType,
    createdByUserId?: string | null,
    updatedByUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    createdByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    updatedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    groups:  {
      __typename: "Group",
      id: string,
      name: string,
      description?: string | null,
      status?: ActiveStatusType | null,
      organizationId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      users?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleGroupConnection",
        items:  Array< {
          __typename: "TicketRuleGroup",
          id: string,
          ticketRuleId: string,
          groupId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tasks:  {
      __typename: "Task",
      id: string,
      name: string,
      description?: string | null,
      requestedByUserId: string,
      reviewedByUserId?: string | null,
      status: TaskStatusType,
      rootChapterId?: string | null,
      organizationId?: string | null,
      ticketRef?: string | null,
      severity?: string | null,
      severityUpdatedAt?: string | null,
      issueCategory?: string | null,
      subCategory?: string | null,
      productVersion?: string | null,
      closureReason?: string | null,
      type?: string | null,
      rootChapterVersion?: string | null,
      productDocId?: string | null,
      isRejected?: boolean | null,
      usersToNotify?: Array< string | null > | null,
      linkedTickets?: Array< string | null > | null,
      salesForceTicketId?: string | null,
      hasSensitiveInfo?: boolean | null,
      requestForInformation?: string | null,
      isSupportEngineerIdentityHidden?: boolean | null,
      tenantId: string,
      issueType?: string | null,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productDoc?:  {
        __typename: "ProductDoc",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        applicationId: string,
        orderFloat?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        application?:  {
          __typename: "Application",
          id: string,
          name: string,
          description?: string | null,
          defaultRoles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rootChapter?:  {
        __typename: "Chapter",
        id: string,
        version: string,
        status: string,
        name: string,
        description?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        pdfFile?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        isPdfBeingGenerated?: boolean | null,
        createdByUserId: string,
        lastModifiedByUserId: string,
        approvedByUserId?: string | null,
        rootChapterId: string,
        parentProductDocId?: string | null,
        parentChapterId?: string | null,
        shouldBeDeleted?: boolean | null,
        currentTaskId?: string | null,
        isRejected?: boolean | null,
        orderFloat?: string | null,
        tenantId: string,
        isArchivedByOrphanRemovalScript?: boolean | null,
        archivedByOrphanRemovalScript?: string | null,
        archivedByIrrelevantContentRemovalScript?: string | null,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDoc?:  {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        parentChapter?:  {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        subChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        approvedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        currentTask?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      requestedByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      reviewedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketWatchers?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedUsers?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketAssignedGroups?:  {
        __typename: "ModelGroupTaskConnection",
        items:  Array< {
          __typename: "GroupTask",
          id: string,
          groupId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateLeadSubscriptionVariables = {
};

export type OnCreateLeadSubscription = {
  onCreateLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateLeadSubscriptionVariables = {
};

export type OnUpdateLeadSubscription = {
  onUpdateLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteLeadSubscriptionVariables = {
};

export type OnDeleteLeadSubscription = {
  onDeleteLead?:  {
    __typename: "Lead",
    id: string,
    leadReferenceNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    role?: string | null,
    jobTitle?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    industry?: string | null,
    companyUrl?: string | null,
    leadName?: string | null,
    description?: string | null,
    timeline?: string | null,
    estimatedBudget?: string | null,
    estimatedNumberOfUsers?: number | null,
    solution?: string | null,
    incumbentProduct?: string | null,
    competitors?: string | null,
    submissionDate?: string | null,
    remarks?: string | null,
    createdByUserId: string,
    lastModifiedByUserId?: string | null,
    qualifiedByUserId?: string | null,
    disqualifiedByUserId?: string | null,
    approvedByUserId?: string | null,
    rejectedByUserId?: string | null,
    usersToNotify?: Array< string | null > | null,
    overallStatus?: LeadOverallStatusType | null,
    qualifiedStatus?: LeadQualifiedStatusType | null,
    approvedStatus?: LeadApprovedStatusType | null,
    qualifiedDate?: string | null,
    approvedDate?: string | null,
    qualifiedRemarks?: string | null,
    approvedRemarks?: string | null,
    salesForceLeadId?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    createdByUser:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    qualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    disqualifiedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    approvedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    rejectedByUser?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        comment: string,
        commentedByUserId: string,
        parentCommentId?: string | null,
        taskId?: string | null,
        leadId?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        attachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        hasSensitiveInfo?: boolean | null,
        requestForInformation?: boolean | null,
        type?: CommentType | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        commentedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        parentComment?:  {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        replies?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        task?:  {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        lead?:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attchments?:  {
      __typename: "ModelFileListConnection",
      items:  Array< {
        __typename: "FileList",
        id: string,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        taskId?: string | null,
        leadId?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    leadWatchers?:  {
      __typename: "ModelWatcherLeadConnection",
      items:  Array< {
        __typename: "WatcherLead",
        id: string,
        userId: string,
        leadId: string,
        createdAt: string,
        updatedAt: string,
        users:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        },
        leads:  {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateWatcherLeadSubscriptionVariables = {
};

export type OnCreateWatcherLeadSubscription = {
  onCreateWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
    userId: string,
    leadId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    leads:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateWatcherLeadSubscriptionVariables = {
};

export type OnUpdateWatcherLeadSubscription = {
  onUpdateWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
    userId: string,
    leadId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    leads:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteWatcherLeadSubscriptionVariables = {
};

export type OnDeleteWatcherLeadSubscription = {
  onDeleteWatcherLead?:  {
    __typename: "WatcherLead",
    id: string,
    userId: string,
    leadId: string,
    createdAt: string,
    updatedAt: string,
    users:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    leads:  {
      __typename: "Lead",
      id: string,
      leadReferenceNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      role?: string | null,
      jobTitle?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      industry?: string | null,
      companyUrl?: string | null,
      leadName?: string | null,
      description?: string | null,
      timeline?: string | null,
      estimatedBudget?: string | null,
      estimatedNumberOfUsers?: number | null,
      solution?: string | null,
      incumbentProduct?: string | null,
      competitors?: string | null,
      submissionDate?: string | null,
      remarks?: string | null,
      createdByUserId: string,
      lastModifiedByUserId?: string | null,
      qualifiedByUserId?: string | null,
      disqualifiedByUserId?: string | null,
      approvedByUserId?: string | null,
      rejectedByUserId?: string | null,
      usersToNotify?: Array< string | null > | null,
      overallStatus?: LeadOverallStatusType | null,
      qualifiedStatus?: LeadQualifiedStatusType | null,
      approvedStatus?: LeadApprovedStatusType | null,
      qualifiedDate?: string | null,
      approvedDate?: string | null,
      qualifiedRemarks?: string | null,
      approvedRemarks?: string | null,
      salesForceLeadId?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdByUser:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      },
      qualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      disqualifiedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      approvedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      rejectedByUser?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attchments?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leadWatchers?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateTempTokenSubscriptionVariables = {
};

export type OnCreateTempTokenSubscription = {
  onCreateTempToken?:  {
    __typename: "TempToken",
    id: string,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTempTokenSubscriptionVariables = {
};

export type OnUpdateTempTokenSubscription = {
  onUpdateTempToken?:  {
    __typename: "TempToken",
    id: string,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTempTokenSubscriptionVariables = {
};

export type OnDeleteTempTokenSubscription = {
  onDeleteTempToken?:  {
    __typename: "TempToken",
    id: string,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCurrencySubscriptionVariables = {
};

export type OnCreateCurrencySubscription = {
  onCreateCurrency?:  {
    __typename: "Currency",
    id: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCurrencySubscriptionVariables = {
};

export type OnUpdateCurrencySubscription = {
  onUpdateCurrency?:  {
    __typename: "Currency",
    id: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCurrencySubscriptionVariables = {
};

export type OnDeleteCurrencySubscription = {
  onDeleteCurrency?:  {
    __typename: "Currency",
    id: string,
    name?: string | null,
    symbol?: string | null,
    isDefaultCurrency?: boolean | null,
    code?: string | null,
    country?: string | null,
    exchangeRate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFAccountSubscriptionVariables = {
};

export type OnCreateSFAccountSubscription = {
  onCreateSFAccount?:  {
    __typename: "SFAccount",
    id: string,
    name?: string | null,
    accountName?: string | null,
    countryId?: string | null,
    parentAccountId?: string | null,
    emailDomain?: string | null,
    industry?: string | null,
    organizationId?: string | null,
    billingAddress?: string | null,
    shippingAddress?: string | null,
    copyBillingAddressToShippingAddress?: boolean | null,
    type?: string | null,
    phone?: string | null,
    website?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    parentAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProducts?:  {
      __typename: "ModelSFProductConnection",
      items:  Array< {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFAccountSubscriptionVariables = {
};

export type OnUpdateSFAccountSubscription = {
  onUpdateSFAccount?:  {
    __typename: "SFAccount",
    id: string,
    name?: string | null,
    accountName?: string | null,
    countryId?: string | null,
    parentAccountId?: string | null,
    emailDomain?: string | null,
    industry?: string | null,
    organizationId?: string | null,
    billingAddress?: string | null,
    shippingAddress?: string | null,
    copyBillingAddressToShippingAddress?: boolean | null,
    type?: string | null,
    phone?: string | null,
    website?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    parentAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProducts?:  {
      __typename: "ModelSFProductConnection",
      items:  Array< {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFAccountSubscriptionVariables = {
};

export type OnDeleteSFAccountSubscription = {
  onDeleteSFAccount?:  {
    __typename: "SFAccount",
    id: string,
    name?: string | null,
    accountName?: string | null,
    countryId?: string | null,
    parentAccountId?: string | null,
    emailDomain?: string | null,
    industry?: string | null,
    organizationId?: string | null,
    billingAddress?: string | null,
    shippingAddress?: string | null,
    copyBillingAddressToShippingAddress?: boolean | null,
    type?: string | null,
    phone?: string | null,
    website?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFAccountConnection",
      items:  Array< {
        __typename: "TicketRuleSFAccount",
        id: string,
        ticketRuleId: string,
        sfAccountId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfAccounts:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    parentAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProducts?:  {
      __typename: "ModelSFProductConnection",
      items:  Array< {
        __typename: "SFProduct",
        id: string,
        productName?: string | null,
        productVersion?: string | null,
        productMetric?: string | null,
        productDescription?: string | null,
        sfAccountId?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        productCode?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProductSubCategories?:  {
          __typename: "ModelSFProductSubCategoryConnection",
          nextToken?: string | null,
        } | null,
        sfProductVersions?:  {
          __typename: "ModelSFProductVersionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFContactSubscriptionVariables = {
};

export type OnCreateSFContactSubscription = {
  onCreateSFContact?:  {
    __typename: "SFContact",
    id: string,
    name?: string | null,
    phone?: string | null,
    accountId?: string | null,
    email?: string | null,
    title?: string | null,
    role?: string | null,
    countryId?: string | null,
    superVisorId?: string | null,
    region?: string | null,
    mailingAddress?: string | null,
    otherAddress?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    superVisor?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFContactSubscriptionVariables = {
};

export type OnUpdateSFContactSubscription = {
  onUpdateSFContact?:  {
    __typename: "SFContact",
    id: string,
    name?: string | null,
    phone?: string | null,
    accountId?: string | null,
    email?: string | null,
    title?: string | null,
    role?: string | null,
    countryId?: string | null,
    superVisorId?: string | null,
    region?: string | null,
    mailingAddress?: string | null,
    otherAddress?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    superVisor?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFContactSubscriptionVariables = {
};

export type OnDeleteSFContactSubscription = {
  onDeleteSFContact?:  {
    __typename: "SFContact",
    id: string,
    name?: string | null,
    phone?: string | null,
    accountId?: string | null,
    email?: string | null,
    title?: string | null,
    role?: string | null,
    countryId?: string | null,
    superVisorId?: string | null,
    region?: string | null,
    mailingAddress?: string | null,
    otherAddress?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    superVisor?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    country?:  {
      __typename: "SFCountry",
      id: string,
      name?: string | null,
      countryId?: string | null,
      region?: string | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFCountryConnection",
        items:  Array< {
          __typename: "TicketRuleSFCountry",
          id: string,
          ticketRuleId: string,
          sfCountryId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      accounts?:  {
        __typename: "ModelSFAccountConnection",
        items:  Array< {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelSFContactConnection",
        items:  Array< {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFContractSubscriptionVariables = {
};

export type OnCreateSFContractSubscription = {
  onCreateSFContract?:  {
    __typename: "SFContract",
    id: string,
    contractNumber?: string | null,
    accountId?: string | null,
    contractTerm?: string | null,
    status?: string | null,
    ownerExpirationNotice?: string | null,
    totalContractValue?: string | null,
    contractType?: string | null,
    poNumber?: string | null,
    poDate?: string | null,
    referenceNumber?: string | null,
    partnerAccountId?: string | null,
    distributorAccountId?: string | null,
    paymentTerms?: string | null,
    poIssuedById?: string | null,
    contactId?: string | null,
    salesRepresentativeNameId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    customerAddressId?: string | null,
    designationId?: string | null,
    emailId?: string | null,
    mobileId?: string | null,
    year1MaintenanceEndDate?: string | null,
    year1MaintenanceStartDate?: string | null,
    year2MaintenanceEndDate?: string | null,
    year2MaintenanceStartDate?: string | null,
    year3MaintenanceEndDate?: string | null,
    year3MaintenanceStartDate?: string | null,
    value?: string | null,
    billingAddress?: string | null,
    description?: string | null,
    specialTerms?: string | null,
    signedById?: string | null,
    companySignedId?: string | null,
    activatedById?: string | null,
    activatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    invoiceDate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    companySigned?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    activatedBy?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    partnerAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    distributorAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    poIssuedBy?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    customerAddress?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    contact?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    salesRepresentativeName?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    designation?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    email?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    mobile?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    signedBy?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFContractSubscriptionVariables = {
};

export type OnUpdateSFContractSubscription = {
  onUpdateSFContract?:  {
    __typename: "SFContract",
    id: string,
    contractNumber?: string | null,
    accountId?: string | null,
    contractTerm?: string | null,
    status?: string | null,
    ownerExpirationNotice?: string | null,
    totalContractValue?: string | null,
    contractType?: string | null,
    poNumber?: string | null,
    poDate?: string | null,
    referenceNumber?: string | null,
    partnerAccountId?: string | null,
    distributorAccountId?: string | null,
    paymentTerms?: string | null,
    poIssuedById?: string | null,
    contactId?: string | null,
    salesRepresentativeNameId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    customerAddressId?: string | null,
    designationId?: string | null,
    emailId?: string | null,
    mobileId?: string | null,
    year1MaintenanceEndDate?: string | null,
    year1MaintenanceStartDate?: string | null,
    year2MaintenanceEndDate?: string | null,
    year2MaintenanceStartDate?: string | null,
    year3MaintenanceEndDate?: string | null,
    year3MaintenanceStartDate?: string | null,
    value?: string | null,
    billingAddress?: string | null,
    description?: string | null,
    specialTerms?: string | null,
    signedById?: string | null,
    companySignedId?: string | null,
    activatedById?: string | null,
    activatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    invoiceDate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    companySigned?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    activatedBy?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    partnerAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    distributorAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    poIssuedBy?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    customerAddress?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    contact?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    salesRepresentativeName?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    designation?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    email?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    mobile?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    signedBy?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFContractSubscriptionVariables = {
};

export type OnDeleteSFContractSubscription = {
  onDeleteSFContract?:  {
    __typename: "SFContract",
    id: string,
    contractNumber?: string | null,
    accountId?: string | null,
    contractTerm?: string | null,
    status?: string | null,
    ownerExpirationNotice?: string | null,
    totalContractValue?: string | null,
    contractType?: string | null,
    poNumber?: string | null,
    poDate?: string | null,
    referenceNumber?: string | null,
    partnerAccountId?: string | null,
    distributorAccountId?: string | null,
    paymentTerms?: string | null,
    poIssuedById?: string | null,
    contactId?: string | null,
    salesRepresentativeNameId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    customerAddressId?: string | null,
    designationId?: string | null,
    emailId?: string | null,
    mobileId?: string | null,
    year1MaintenanceEndDate?: string | null,
    year1MaintenanceStartDate?: string | null,
    year2MaintenanceEndDate?: string | null,
    year2MaintenanceStartDate?: string | null,
    year3MaintenanceEndDate?: string | null,
    year3MaintenanceStartDate?: string | null,
    value?: string | null,
    billingAddress?: string | null,
    description?: string | null,
    specialTerms?: string | null,
    signedById?: string | null,
    companySignedId?: string | null,
    activatedById?: string | null,
    activatedDate?: string | null,
    files?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    invoiceDate?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    companySigned?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    activatedBy?:  {
      __typename: "User",
      id: string,
      userType: UserType,
      firstName: string,
      lastName: string,
      displayName?: string | null,
      jobTitle?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      email: string,
      organizationId?: string | null,
      salesForceId?: string | null,
      role?: RoleType | null,
      secondaryEmail: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      status?: ActiveStatusType | null,
      isMigrated?: boolean | null,
      isTenantAdmin?: boolean | null,
      ticketsToReviewOrder?: Array< string | null > | null,
      supportPortalDashBoardChartsOrder?: Array< number | null > | null,
      tenantId: string,
      preferredCurrency?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      createdProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedProductDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedChapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedDocItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleUserConnection",
        items:  Array< {
          __typename: "TicketRuleUser",
          id: string,
          ticketRuleId: string,
          userId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      createdTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewedTasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingTickets?:  {
        __typename: "ModelWatcherTaskConnection",
        items:  Array< {
          __typename: "WatcherTask",
          id: string,
          userId: string,
          taskId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      assignedTickets?:  {
        __typename: "ModelUserTaskConnection",
        items:  Array< {
          __typename: "UserTask",
          id: string,
          userId: string,
          taskId: string,
          status: TaskUserGroupStatusType,
          createdByUserId?: string | null,
          updatedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      qualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      disqualifiedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      approvedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rejectedLeads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      watchingLeads?:  {
        __typename: "ModelWatcherLeadConnection",
        items:  Array< {
          __typename: "WatcherLead",
          id: string,
          userId: string,
          leadId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    account?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    partnerAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    distributorAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    poIssuedBy?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    customerAddress?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    contact?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    salesRepresentativeName?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    designation?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    email?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    mobile?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    signedBy?:  {
      __typename: "SFContact",
      id: string,
      name?: string | null,
      phone?: string | null,
      accountId?: string | null,
      email?: string | null,
      title?: string | null,
      role?: string | null,
      countryId?: string | null,
      superVisorId?: string | null,
      region?: string | null,
      mailingAddress?: string | null,
      otherAddress?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      superVisor?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFContractLineItemSubscriptionVariables = {
};

export type OnCreateSFContractLineItemSubscription = {
  onCreateSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
    contractName?: string | null,
    itemNo?: string | null,
    productId?: string | null,
    quantity?: string | null,
    contractId?: string | null,
    productListPrice?: string | null,
    discount?: string | null,
    amount?: string | null,
    version?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contract?:  {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    product?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFContractLineItemSubscriptionVariables = {
};

export type OnUpdateSFContractLineItemSubscription = {
  onUpdateSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
    contractName?: string | null,
    itemNo?: string | null,
    productId?: string | null,
    quantity?: string | null,
    contractId?: string | null,
    productListPrice?: string | null,
    discount?: string | null,
    amount?: string | null,
    version?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contract?:  {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    product?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFContractLineItemSubscriptionVariables = {
};

export type OnDeleteSFContractLineItemSubscription = {
  onDeleteSFContractLineItem?:  {
    __typename: "SFContractLineItem",
    id: string,
    contractName?: string | null,
    itemNo?: string | null,
    productId?: string | null,
    quantity?: string | null,
    contractId?: string | null,
    productListPrice?: string | null,
    discount?: string | null,
    amount?: string | null,
    version?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contract?:  {
      __typename: "SFContract",
      id: string,
      contractNumber?: string | null,
      accountId?: string | null,
      contractTerm?: string | null,
      status?: string | null,
      ownerExpirationNotice?: string | null,
      totalContractValue?: string | null,
      contractType?: string | null,
      poNumber?: string | null,
      poDate?: string | null,
      referenceNumber?: string | null,
      partnerAccountId?: string | null,
      distributorAccountId?: string | null,
      paymentTerms?: string | null,
      poIssuedById?: string | null,
      contactId?: string | null,
      salesRepresentativeNameId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      customerAddressId?: string | null,
      designationId?: string | null,
      emailId?: string | null,
      mobileId?: string | null,
      year1MaintenanceEndDate?: string | null,
      year1MaintenanceStartDate?: string | null,
      year2MaintenanceEndDate?: string | null,
      year2MaintenanceStartDate?: string | null,
      year3MaintenanceEndDate?: string | null,
      year3MaintenanceStartDate?: string | null,
      value?: string | null,
      billingAddress?: string | null,
      description?: string | null,
      specialTerms?: string | null,
      signedById?: string | null,
      companySignedId?: string | null,
      activatedById?: string | null,
      activatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      invoiceDate?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      companySigned?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      activatedBy?:  {
        __typename: "User",
        id: string,
        userType: UserType,
        firstName: string,
        lastName: string,
        displayName?: string | null,
        jobTitle?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        email: string,
        organizationId?: string | null,
        salesForceId?: string | null,
        role?: RoleType | null,
        secondaryEmail: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null,
        status?: ActiveStatusType | null,
        isMigrated?: boolean | null,
        isTenantAdmin?: boolean | null,
        ticketsToReviewOrder?: Array< string | null > | null,
        supportPortalDashBoardChartsOrder?: Array< number | null > | null,
        tenantId: string,
        preferredCurrency?: string | null,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        approvedProductDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        createdChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        approvedChapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        createdDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        approvedDocs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        createdDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        approvedDocItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelUserGroupConnection",
          nextToken?: string | null,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleUserConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        reviewedTasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        watchingTickets?:  {
          __typename: "ModelWatcherTaskConnection",
          nextToken?: string | null,
        } | null,
        assignedTickets?:  {
          __typename: "ModelUserTaskConnection",
          nextToken?: string | null,
        } | null,
        createdLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        qualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        disqualifiedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        approvedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        rejectedLeads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        watchingLeads?:  {
          __typename: "ModelWatcherLeadConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      account?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      partnerAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      distributorAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      poIssuedBy?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      customerAddress?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      contact?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      salesRepresentativeName?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      designation?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      email?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      mobile?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      signedBy?:  {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    product?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFProductSubscriptionVariables = {
};

export type OnCreateSFProductSubscription = {
  onCreateSFProduct?:  {
    __typename: "SFProduct",
    id: string,
    productName?: string | null,
    productVersion?: string | null,
    productMetric?: string | null,
    productDescription?: string | null,
    sfAccountId?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    productCode?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProductSubCategories?:  {
      __typename: "ModelSFProductSubCategoryConnection",
      items:  Array< {
        __typename: "SFProductSubCategory",
        id: string,
        name?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    sfProductVersions?:  {
      __typename: "ModelSFProductVersionConnection",
      items:  Array< {
        __typename: "SFProductVersion",
        id: string,
        name?: string | null,
        version?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSFProductSubscriptionVariables = {
};

export type OnUpdateSFProductSubscription = {
  onUpdateSFProduct?:  {
    __typename: "SFProduct",
    id: string,
    productName?: string | null,
    productVersion?: string | null,
    productMetric?: string | null,
    productDescription?: string | null,
    sfAccountId?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    productCode?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProductSubCategories?:  {
      __typename: "ModelSFProductSubCategoryConnection",
      items:  Array< {
        __typename: "SFProductSubCategory",
        id: string,
        name?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    sfProductVersions?:  {
      __typename: "ModelSFProductVersionConnection",
      items:  Array< {
        __typename: "SFProductVersion",
        id: string,
        name?: string | null,
        version?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSFProductSubscriptionVariables = {
};

export type OnDeleteSFProductSubscription = {
  onDeleteSFProduct?:  {
    __typename: "SFProduct",
    id: string,
    productName?: string | null,
    productVersion?: string | null,
    productMetric?: string | null,
    productDescription?: string | null,
    sfAccountId?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    productCode?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfAccount?:  {
      __typename: "SFAccount",
      id: string,
      name?: string | null,
      accountName?: string | null,
      countryId?: string | null,
      parentAccountId?: string | null,
      emailDomain?: string | null,
      industry?: string | null,
      organizationId?: string | null,
      billingAddress?: string | null,
      shippingAddress?: string | null,
      copyBillingAddressToShippingAddress?: boolean | null,
      type?: string | null,
      phone?: string | null,
      website?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      files?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      notesAndAttachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      ticketRules?:  {
        __typename: "ModelTicketRuleSFAccountConnection",
        items:  Array< {
          __typename: "TicketRuleSFAccount",
          id: string,
          ticketRuleId: string,
          sfAccountId: string,
          status: TicketRuleJoinTableStatusType,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      parentAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProducts?:  {
        __typename: "ModelSFProductConnection",
        items:  Array< {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      country?:  {
        __typename: "SFCountry",
        id: string,
        name?: string | null,
        countryId?: string | null,
        region?: string | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFCountryConnection",
          nextToken?: string | null,
        } | null,
        accounts?:  {
          __typename: "ModelSFAccountConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelSFContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    sfProductSubCategories?:  {
      __typename: "ModelSFProductSubCategoryConnection",
      items:  Array< {
        __typename: "SFProductSubCategory",
        id: string,
        name?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    sfProductVersions?:  {
      __typename: "ModelSFProductVersionConnection",
      items:  Array< {
        __typename: "SFProductVersion",
        id: string,
        name?: string | null,
        version?: string | null,
        sfProductId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProduct?:  {
          __typename: "SFProduct",
          id: string,
          productName?: string | null,
          productVersion?: string | null,
          productMetric?: string | null,
          productDescription?: string | null,
          sfAccountId?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          productCode?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateSFProductSubCategorySubscriptionVariables = {
};

export type OnCreateSFProductSubCategorySubscription = {
  onCreateSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
    name?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFProductSubCategorySubscriptionVariables = {
};

export type OnUpdateSFProductSubCategorySubscription = {
  onUpdateSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
    name?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFProductSubCategorySubscriptionVariables = {
};

export type OnDeleteSFProductSubCategorySubscription = {
  onDeleteSFProductSubCategory?:  {
    __typename: "SFProductSubCategory",
    id: string,
    name?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFProductVersionSubscriptionVariables = {
};

export type OnCreateSFProductVersionSubscription = {
  onCreateSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
    name?: string | null,
    version?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFProductVersionSubscriptionVariables = {
};

export type OnUpdateSFProductVersionSubscription = {
  onUpdateSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
    name?: string | null,
    version?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFProductVersionSubscriptionVariables = {
};

export type OnDeleteSFProductVersionSubscription = {
  onDeleteSFProductVersion?:  {
    __typename: "SFProductVersion",
    id: string,
    name?: string | null,
    version?: string | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFPriceBookSubscriptionVariables = {
};

export type OnCreateSFPriceBookSubscription = {
  onCreateSFPriceBook?:  {
    __typename: "SFPriceBook",
    id: string,
    priceBookName?: string | null,
    active?: boolean | null,
    description?: string | null,
    isStandardPriceBook?: boolean | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFPriceBookSubscriptionVariables = {
};

export type OnUpdateSFPriceBookSubscription = {
  onUpdateSFPriceBook?:  {
    __typename: "SFPriceBook",
    id: string,
    priceBookName?: string | null,
    active?: boolean | null,
    description?: string | null,
    isStandardPriceBook?: boolean | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFPriceBookSubscriptionVariables = {
};

export type OnDeleteSFPriceBookSubscription = {
  onDeleteSFPriceBook?:  {
    __typename: "SFPriceBook",
    id: string,
    priceBookName?: string | null,
    active?: boolean | null,
    description?: string | null,
    isStandardPriceBook?: boolean | null,
    sfProductId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    sfProduct?:  {
      __typename: "SFProduct",
      id: string,
      productName?: string | null,
      productVersion?: string | null,
      productMetric?: string | null,
      productDescription?: string | null,
      sfAccountId?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      productCode?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      sfAccount?:  {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      sfProductSubCategories?:  {
        __typename: "ModelSFProductSubCategoryConnection",
        items:  Array< {
          __typename: "SFProductSubCategory",
          id: string,
          name?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sfProductVersions?:  {
        __typename: "ModelSFProductVersionConnection",
        items:  Array< {
          __typename: "SFProductVersion",
          id: string,
          name?: string | null,
          version?: string | null,
          sfProductId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFIssueCategorySubscriptionVariables = {
};

export type OnCreateSFIssueCategorySubscription = {
  onCreateSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFIssueCategorySubscriptionVariables = {
};

export type OnUpdateSFIssueCategorySubscription = {
  onUpdateSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFIssueCategorySubscriptionVariables = {
};

export type OnDeleteSFIssueCategorySubscription = {
  onDeleteSFIssueCategory?:  {
    __typename: "SFIssueCategory",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFCaseSeveritySubscriptionVariables = {
};

export type OnCreateSFCaseSeveritySubscription = {
  onCreateSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFCaseSeveritySubscriptionVariables = {
};

export type OnUpdateSFCaseSeveritySubscription = {
  onUpdateSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFCaseSeveritySubscriptionVariables = {
};

export type OnDeleteSFCaseSeveritySubscription = {
  onDeleteSFCaseSeverity?:  {
    __typename: "SFCaseSeverity",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFClosureReasonSubscriptionVariables = {
};

export type OnCreateSFClosureReasonSubscription = {
  onCreateSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFClosureReasonSubscriptionVariables = {
};

export type OnUpdateSFClosureReasonSubscription = {
  onUpdateSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFClosureReasonSubscriptionVariables = {
};

export type OnDeleteSFClosureReasonSubscription = {
  onDeleteSFClosureReason?:  {
    __typename: "SFClosureReason",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFRoleSubscriptionVariables = {
};

export type OnCreateSFRoleSubscription = {
  onCreateSFRole?:  {
    __typename: "SFRole",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFRoleSubscriptionVariables = {
};

export type OnUpdateSFRoleSubscription = {
  onUpdateSFRole?:  {
    __typename: "SFRole",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFRoleSubscriptionVariables = {
};

export type OnDeleteSFRoleSubscription = {
  onDeleteSFRole?:  {
    __typename: "SFRole",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFIndustrySubscriptionVariables = {
};

export type OnCreateSFIndustrySubscription = {
  onCreateSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFIndustrySubscriptionVariables = {
};

export type OnUpdateSFIndustrySubscription = {
  onUpdateSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFIndustrySubscriptionVariables = {
};

export type OnDeleteSFIndustrySubscription = {
  onDeleteSFIndustry?:  {
    __typename: "SFIndustry",
    id: string,
    label?: string | null,
    value?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFSolutionSubscriptionVariables = {
};

export type OnCreateSFSolutionSubscription = {
  onCreateSFSolution?:  {
    __typename: "SFSolution",
    id: string,
    name?: string | null,
    leadId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    competitors?:  {
      __typename: "ModelSFCompetitorConnection",
      items:  Array< {
        __typename: "SFCompetitor",
        id: string,
        name?: string | null,
        domain?: string | null,
        competitors?: string | null,
        solutionId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solution?:  {
          __typename: "SFSolution",
          id: string,
          name?: string | null,
          leadId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSFSolutionSubscriptionVariables = {
};

export type OnUpdateSFSolutionSubscription = {
  onUpdateSFSolution?:  {
    __typename: "SFSolution",
    id: string,
    name?: string | null,
    leadId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    competitors?:  {
      __typename: "ModelSFCompetitorConnection",
      items:  Array< {
        __typename: "SFCompetitor",
        id: string,
        name?: string | null,
        domain?: string | null,
        competitors?: string | null,
        solutionId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solution?:  {
          __typename: "SFSolution",
          id: string,
          name?: string | null,
          leadId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSFSolutionSubscriptionVariables = {
};

export type OnDeleteSFSolutionSubscription = {
  onDeleteSFSolution?:  {
    __typename: "SFSolution",
    id: string,
    name?: string | null,
    leadId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    competitors?:  {
      __typename: "ModelSFCompetitorConnection",
      items:  Array< {
        __typename: "SFCompetitor",
        id: string,
        name?: string | null,
        domain?: string | null,
        competitors?: string | null,
        solutionId?: string | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        solution?:  {
          __typename: "SFSolution",
          id: string,
          name?: string | null,
          leadId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateSFCompetitorSubscriptionVariables = {
};

export type OnCreateSFCompetitorSubscription = {
  onCreateSFCompetitor?:  {
    __typename: "SFCompetitor",
    id: string,
    name?: string | null,
    domain?: string | null,
    competitors?: string | null,
    solutionId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    solution?:  {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSFCompetitorSubscriptionVariables = {
};

export type OnUpdateSFCompetitorSubscription = {
  onUpdateSFCompetitor?:  {
    __typename: "SFCompetitor",
    id: string,
    name?: string | null,
    domain?: string | null,
    competitors?: string | null,
    solutionId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    solution?:  {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSFCompetitorSubscriptionVariables = {
};

export type OnDeleteSFCompetitorSubscription = {
  onDeleteSFCompetitor?:  {
    __typename: "SFCompetitor",
    id: string,
    name?: string | null,
    domain?: string | null,
    competitors?: string | null,
    solutionId?: string | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    solution?:  {
      __typename: "SFSolution",
      id: string,
      name?: string | null,
      leadId?: string | null,
      allSFData?: string | null,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        name: string,
        subscribedServices?: string | null,
        domain?: string | null,
        organizationId?: string | null,
        tenantCustomizationId?: string | null,
        sidebarLinkId?: string | null,
        usedSpaceByDoc?: string | null,
        totalSpaceForDoc?: string | null,
        createdAt: string,
        updatedAt: string,
        sidebarLink?:  {
          __typename: "ModelSidebarLinkConnection",
          nextToken?: string | null,
        } | null,
        tenantCustomization?:  {
          __typename: "TenantCustomization",
          id: string,
          color?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        applications?:  {
          __typename: "ModelTenantApplicationConnection",
          nextToken?: string | null,
        } | null,
        productDocs?:  {
          __typename: "ModelProductDocConnection",
          nextToken?: string | null,
        } | null,
        chapters?:  {
          __typename: "ModelChapterConnection",
          nextToken?: string | null,
        } | null,
        docs?:  {
          __typename: "ModelDocConnection",
          nextToken?: string | null,
        } | null,
        docItems?:  {
          __typename: "ModelDocItemConnection",
          nextToken?: string | null,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          nextToken?: string | null,
        } | null,
        groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
        } | null,
        applicationRoles?:  {
          __typename: "ModelApplicationRoleConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          domain?: string | null,
          accountId?: string | null,
          recordId?: string | null,
          type?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        fileLists?:  {
          __typename: "ModelFileListConnection",
          nextToken?: string | null,
        } | null,
        tasks?:  {
          __typename: "ModelTaskConnection",
          nextToken?: string | null,
        } | null,
        leads?:  {
          __typename: "ModelLeadConnection",
          nextToken?: string | null,
        } | null,
        currencies?:  {
          __typename: "ModelCurrencyConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      competitors?:  {
        __typename: "ModelSFCompetitorConnection",
        items:  Array< {
          __typename: "SFCompetitor",
          id: string,
          name?: string | null,
          domain?: string | null,
          competitors?: string | null,
          solutionId?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSFCountrySubscriptionVariables = {
};

export type OnCreateSFCountrySubscription = {
  onCreateSFCountry?:  {
    __typename: "SFCountry",
    id: string,
    name?: string | null,
    countryId?: string | null,
    region?: string | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    accounts?:  {
      __typename: "ModelSFAccountConnection",
      items:  Array< {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelSFContactConnection",
      items:  Array< {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSFCountrySubscriptionVariables = {
};

export type OnUpdateSFCountrySubscription = {
  onUpdateSFCountry?:  {
    __typename: "SFCountry",
    id: string,
    name?: string | null,
    countryId?: string | null,
    region?: string | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    accounts?:  {
      __typename: "ModelSFAccountConnection",
      items:  Array< {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelSFContactConnection",
      items:  Array< {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSFCountrySubscriptionVariables = {
};

export type OnDeleteSFCountrySubscription = {
  onDeleteSFCountry?:  {
    __typename: "SFCountry",
    id: string,
    name?: string | null,
    countryId?: string | null,
    region?: string | null,
    notesAndAttachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    allSFData?: string | null,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      name: string,
      subscribedServices?: string | null,
      domain?: string | null,
      organizationId?: string | null,
      tenantCustomizationId?: string | null,
      sidebarLinkId?: string | null,
      usedSpaceByDoc?: string | null,
      totalSpaceForDoc?: string | null,
      createdAt: string,
      updatedAt: string,
      sidebarLink?:  {
        __typename: "ModelSidebarLinkConnection",
        items:  Array< {
          __typename: "SidebarLink",
          id: string,
          name?: string | null,
          url?: string | null,
          parentLinkId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tenantCustomization?:  {
        __typename: "TenantCustomization",
        id: string,
        color?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      applications?:  {
        __typename: "ModelTenantApplicationConnection",
        items:  Array< {
          __typename: "TenantApplication",
          id: string,
          tenantId: string,
          applicationId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      productDocs?:  {
        __typename: "ModelProductDocConnection",
        items:  Array< {
          __typename: "ProductDoc",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          applicationId: string,
          orderFloat?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          version: string,
          status: string,
          name: string,
          description?: string | null,
          isPdfBeingGenerated?: boolean | null,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          parentProductDocId?: string | null,
          parentChapterId?: string | null,
          shouldBeDeleted?: boolean | null,
          currentTaskId?: string | null,
          isRejected?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docs?:  {
        __typename: "ModelDocConnection",
        items:  Array< {
          __typename: "Doc",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          createdByUserId: string,
          isPdfBeingGenerated?: boolean | null,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          chapterId: string,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          referenceDocId?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      docItems?:  {
        __typename: "ModelDocItemConnection",
        items:  Array< {
          __typename: "DocItem",
          id: string,
          version: string,
          status: string,
          title: string,
          description?: string | null,
          itemType: DocItemType,
          createdByUserId: string,
          lastModifiedByUserId: string,
          approvedByUserId?: string | null,
          rootChapterId: string,
          docId: string,
          createdAt?: string | null,
          shouldBeDeleted?: boolean | null,
          orderFloat?: string | null,
          tenantId: string,
          isArchivedByOrphanRemovalScript?: boolean | null,
          archivedByOrphanRemovalScript?: string | null,
          archivedByIrrelevantContentRemovalScript?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          comment: string,
          commentedByUserId: string,
          parentCommentId?: string | null,
          taskId?: string | null,
          leadId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: boolean | null,
          type?: CommentType | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          description?: string | null,
          status?: ActiveStatusType | null,
          organizationId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      applicationRoles?:  {
        __typename: "ModelApplicationRoleConnection",
        items:  Array< {
          __typename: "ApplicationRole",
          id: string,
          roleId: string,
          applicationId: string,
          groupId: string,
          productDocs?: Array< string | null > | null,
          locations?: Array< string | null > | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        domain?: string | null,
        accountId?: string | null,
        recordId?: string | null,
        type?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      fileLists?:  {
        __typename: "ModelFileListConnection",
        items:  Array< {
          __typename: "FileList",
          id: string,
          taskId?: string | null,
          leadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name: string,
          description?: string | null,
          requestedByUserId: string,
          reviewedByUserId?: string | null,
          status: TaskStatusType,
          rootChapterId?: string | null,
          organizationId?: string | null,
          ticketRef?: string | null,
          severity?: string | null,
          severityUpdatedAt?: string | null,
          issueCategory?: string | null,
          subCategory?: string | null,
          productVersion?: string | null,
          closureReason?: string | null,
          type?: string | null,
          rootChapterVersion?: string | null,
          productDocId?: string | null,
          isRejected?: boolean | null,
          usersToNotify?: Array< string | null > | null,
          linkedTickets?: Array< string | null > | null,
          salesForceTicketId?: string | null,
          hasSensitiveInfo?: boolean | null,
          requestForInformation?: string | null,
          isSupportEngineerIdentityHidden?: boolean | null,
          tenantId: string,
          issueType?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      leads?:  {
        __typename: "ModelLeadConnection",
        items:  Array< {
          __typename: "Lead",
          id: string,
          leadReferenceNumber?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          role?: string | null,
          jobTitle?: string | null,
          companyName?: string | null,
          companyAddress?: string | null,
          industry?: string | null,
          companyUrl?: string | null,
          leadName?: string | null,
          description?: string | null,
          timeline?: string | null,
          estimatedBudget?: string | null,
          estimatedNumberOfUsers?: number | null,
          solution?: string | null,
          incumbentProduct?: string | null,
          competitors?: string | null,
          submissionDate?: string | null,
          remarks?: string | null,
          createdByUserId: string,
          lastModifiedByUserId?: string | null,
          qualifiedByUserId?: string | null,
          disqualifiedByUserId?: string | null,
          approvedByUserId?: string | null,
          rejectedByUserId?: string | null,
          usersToNotify?: Array< string | null > | null,
          overallStatus?: LeadOverallStatusType | null,
          qualifiedStatus?: LeadQualifiedStatusType | null,
          approvedStatus?: LeadApprovedStatusType | null,
          qualifiedDate?: string | null,
          approvedDate?: string | null,
          qualifiedRemarks?: string | null,
          approvedRemarks?: string | null,
          salesForceLeadId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      currencies?:  {
        __typename: "ModelCurrencyConnection",
        items:  Array< {
          __typename: "Currency",
          id: string,
          name?: string | null,
          symbol?: string | null,
          isDefaultCurrency?: boolean | null,
          code?: string | null,
          country?: string | null,
          exchangeRate?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    ticketRules?:  {
      __typename: "ModelTicketRuleSFCountryConnection",
      items:  Array< {
        __typename: "TicketRuleSFCountry",
        id: string,
        ticketRuleId: string,
        sfCountryId: string,
        status: TicketRuleJoinTableStatusType,
        createdByUserId?: string | null,
        lastModifiedByUserId?: string | null,
        createdAt: string,
        updatedAt: string,
        createdByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        lastModifiedByUser?:  {
          __typename: "User",
          id: string,
          userType: UserType,
          firstName: string,
          lastName: string,
          displayName?: string | null,
          jobTitle?: string | null,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          country?: string | null,
          email: string,
          organizationId?: string | null,
          salesForceId?: string | null,
          role?: RoleType | null,
          secondaryEmail: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: ActiveStatusType | null,
          isMigrated?: boolean | null,
          isTenantAdmin?: boolean | null,
          ticketsToReviewOrder?: Array< string | null > | null,
          supportPortalDashBoardChartsOrder?: Array< number | null > | null,
          tenantId: string,
          preferredCurrency?: string | null,
        } | null,
        ticketRules:  {
          __typename: "TicketRule",
          id: string,
          name: string,
          description?: string | null,
          status: string,
          createdByUserId?: string | null,
          lastModifiedByUserId?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
        sfCountries:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    accounts?:  {
      __typename: "ModelSFAccountConnection",
      items:  Array< {
        __typename: "SFAccount",
        id: string,
        name?: string | null,
        accountName?: string | null,
        countryId?: string | null,
        parentAccountId?: string | null,
        emailDomain?: string | null,
        industry?: string | null,
        organizationId?: string | null,
        billingAddress?: string | null,
        shippingAddress?: string | null,
        copyBillingAddressToShippingAddress?: boolean | null,
        type?: string | null,
        phone?: string | null,
        website?: string | null,
        createdBy?: string | null,
        createdDate?: string | null,
        updatedBy?: string | null,
        updatedDate?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ticketRules?:  {
          __typename: "ModelTicketRuleSFAccountConnection",
          nextToken?: string | null,
        } | null,
        parentAccount?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sfProducts?:  {
          __typename: "ModelSFProductConnection",
          nextToken?: string | null,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelSFContactConnection",
      items:  Array< {
        __typename: "SFContact",
        id: string,
        name?: string | null,
        phone?: string | null,
        accountId?: string | null,
        email?: string | null,
        title?: string | null,
        role?: string | null,
        countryId?: string | null,
        superVisorId?: string | null,
        region?: string | null,
        mailingAddress?: string | null,
        otherAddress?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        files?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        notesAndAttachments?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        } | null > | null,
        allSFData?: string | null,
        tenantId: string,
        createdAt: string,
        updatedAt: string,
        tenant?:  {
          __typename: "Tenant",
          id: string,
          name: string,
          subscribedServices?: string | null,
          domain?: string | null,
          organizationId?: string | null,
          tenantCustomizationId?: string | null,
          sidebarLinkId?: string | null,
          usedSpaceByDoc?: string | null,
          totalSpaceForDoc?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        account?:  {
          __typename: "SFAccount",
          id: string,
          name?: string | null,
          accountName?: string | null,
          countryId?: string | null,
          parentAccountId?: string | null,
          emailDomain?: string | null,
          industry?: string | null,
          organizationId?: string | null,
          billingAddress?: string | null,
          shippingAddress?: string | null,
          copyBillingAddressToShippingAddress?: boolean | null,
          type?: string | null,
          phone?: string | null,
          website?: string | null,
          createdBy?: string | null,
          createdDate?: string | null,
          updatedBy?: string | null,
          updatedDate?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        superVisor?:  {
          __typename: "SFContact",
          id: string,
          name?: string | null,
          phone?: string | null,
          accountId?: string | null,
          email?: string | null,
          title?: string | null,
          role?: string | null,
          countryId?: string | null,
          superVisorId?: string | null,
          region?: string | null,
          mailingAddress?: string | null,
          otherAddress?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        country?:  {
          __typename: "SFCountry",
          id: string,
          name?: string | null,
          countryId?: string | null,
          region?: string | null,
          allSFData?: string | null,
          tenantId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};
